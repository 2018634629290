import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  list: {
    minWidth: 335,
  },
  link: {
    color: theme.palette.action.disabled,
    textDecoration: 'underline',
    fontStyle: 'italic',
  },
  errorMessage: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  passwordForgottenContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
}));
