import { useMemo } from 'react';
import { StyleSheet } from '@react-pdf/renderer';

export const useStyles = () => {
  return useMemo(
    () =>
      StyleSheet.create({
        root: { paddingBottom: 10 },
        title: { fontSize: 14, fontWeight: 'bold' },
      }),
    [],
  );
};
