import { createApi } from '@reduxjs/toolkit/query/react';

import { isNilOrEmpty } from 'ramda-adjunct';
import userAdminBaseQuery from '../../../services/user-admin-http-client';
import { Patient } from '../../patient/types';
import { User } from '../entity/user';
import { GetPatientRequestParams } from './dto';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: userAdminBaseQuery,
  tagTypes: ['Patients', 'Specialists', 'User'],
  endpoints: builder => ({
    getCurrentUser: builder.query<User, any>({
      providesTags: ['User'],
      query: () => ({
        url: '/api/v1/accounts/current',
        method: 'GET',
      }),
    }),
    getPatient: builder.query<Patient, GetPatientRequestParams>({
      query: ({ id, organizationId }) => {
        if (!isNilOrEmpty(organizationId)) {
          return {
            url: `/api/v1/patients/${id}?organization=${organizationId}`,
          };
        }

        return {
          url: `/api/v1/patients/${id}`,
        };
      },
    }),
  }),
});

export const {
  // User
  useGetCurrentUserQuery,

  // Patient
  useGetPatientQuery,
} = userApi;
