import { makeStyles, Theme } from '@material-ui/core/styles';
import theme from '../../../theme';

export interface AlertTextStylesProps {
  color: 'warning' | 'info' | 'error' | 'success';
  alternativePalette: boolean;
}

const palette: {
  [k in 'warning' | 'info' | 'error' | 'success']: { main: string; light: string };
} = {
  success: { main: '#2ccb56', light: '#e9faed' },
  warning: { main: '#deb510', light: '#fdf8e5' },
  error: { main: '#ff4b33', light: '#ffecea' },
  info: { main: '#216583', light: '#e3f1f8' },
};

export default makeStyles<Theme, AlertTextStylesProps>({
  typography: ({ color, alternativePalette }) => ({
    color: alternativePalette ? palette[color].main : theme.palette[color].main,
  }),
});
