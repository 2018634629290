import { FunctionComponent } from 'react';
import { Tab, TabProps } from '@material-ui/core';

import useStyles from './styles';

const PatientDashboardTab: FunctionComponent<TabProps> = props => {
  const classes = useStyles();

  return (
    <Tab
      {...props}
      classes={{
        root: classes.root,
        selected: classes.selected,
      }}
      disableRipple
    />
  );
};

export default PatientDashboardTab;
