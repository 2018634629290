import { useMemo } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { usePDFThemeContext } from '../context';

export const useStyles = () => {
  const theme = usePDFThemeContext();
  return useMemo(
    () =>
      StyleSheet.create({
        root: {
          marginVertical: 5,
          width: '100%',
          height: 0.5,
          backgroundColor: theme.palette.primary.light,
        },
      }),
    [theme],
  );
};
