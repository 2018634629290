import {
  PDFInlineListProps,
  PDFListProps,
  PDFStatusMessageProps,
  PDFTextParamProps,
  PDFTitleProps,
} from '../../../../../../../components';
import {
  hasAdditionalTestsRecommendationQuestion,
  hasConsultationRecommendationWithSpecialistQuestion,
  hasReferringDoctorQuestion,
  isCardiacAssessmentQuestion,
  lifestyleActionsLabel,
  lifestyleActionsPriorityLabel,
  recommendationQuestion,
  reportTitle,
} from '../wording';
import { booleanToText } from '../../../../../../../helpers/format/string';
import { pad } from '../../../../../../../helpers/format/number';
import { findOptionLabelByValue } from '../../../../../../../helpers/format/options';
import {
  additionalEvaluationsOptions,
  lifestyleActionsOptions,
  recommendationOptions,
  referringDoctorStatusOptions,
} from '../options';
import { Recommendation } from '../../../../../../../domains/patient/report/types';
import { IReportPreviewVM } from '../../ReportPreviewVM';

export interface PDFReportDTO {
  reportTitle: PDFTitleProps;
  isCardiacAssessment: PDFTextParamProps;
  additionalEvaluations: PDFInlineListProps;

  hasConsultationRecommendationWithSpecialist: PDFTextParamProps;
  referringDoctorStatus: PDFTextParamProps;
  recommendation: PDFStatusMessageProps;
  lifestyleActionPriorities: PDFListProps;
}

const recommendationToPDFStatusMessageColor: {
  [k in Recommendation]: PDFStatusMessageProps['color'];
} = {
  [Recommendation.LOW]: 'success',
  [Recommendation.MEDIUM]: 'warning',
  [Recommendation.HIGH]: 'error',
};

const NA = '-';

export const bindFromIReportPreviewVM = (report: IReportPreviewVM): PDFReportDTO => ({
  reportTitle: {
    title: reportTitle,
  },
  isCardiacAssessment: {
    title: isCardiacAssessmentQuestion,
    value: booleanToText(report.isCardiacAssessment),
  },
  referringDoctorStatus: {
    title: hasReferringDoctorQuestion,
    value:
      findOptionLabelByValue(
        referringDoctorStatusOptions,
        report?.cardiacAssessmentConsultation?.referringDoctorStatus,
      ) || NA,
  },
  recommendation: {
    title: recommendationQuestion,
    text:
      findOptionLabelByValue(
        recommendationOptions,
        report?.cardiacAssessmentConsultation?.recommendation,
      ) || NA,
    color:
      recommendationToPDFStatusMessageColor[
        report?.cardiacAssessmentConsultation?.recommendation as Recommendation
      ],
  },
  hasConsultationRecommendationWithSpecialist: {
    title: hasConsultationRecommendationWithSpecialistQuestion,
    value: booleanToText(
      report?.cardiacAssessmentConsultation?.hasConsultationRecommendationWithSpecialist || false,
    ),
  },
  additionalEvaluations: {
    title: hasAdditionalTestsRecommendationQuestion,
    value: booleanToText(
      report?.cardiacAssessmentConsultation?.hasAdditionalTestsRecommendation || false,
    ),
    listValues: Object.values(
      report?.cardiacAssessmentConsultation?.additionalEvaluations || [],
    ).map(evaluation => findOptionLabelByValue(additionalEvaluationsOptions, evaluation) || '-'),
  },
  lifestyleActionPriorities: {
    title: lifestyleActionsLabel,
    listValues: Object.values(
      report?.cardiacAssessmentConsultation?.lifestyleActionPriorities || {},
    ).map((testType, idx) => ({
      text: `${lifestyleActionsPriorityLabel} ${pad(idx + 1, 2)} :`,
      value: findOptionLabelByValue(lifestyleActionsOptions, testType) || '-',
    })),
  },
});
