import React, { FunctionComponent, useCallback, useEffect } from 'react';

import { DeleteRecordRequestDTO } from 'src/domains/medical-record/repository';
import { ModalProps } from 'src/components/common/Modal';
import DeleteModal from '../../Modals/DeleteModal';
import useDeletePatientObservationUseCase from 'src/domains/medical-record/use-case/observation/useDeletePatientObservationUseCase';

interface ObservationDeletionModalProps extends ModalProps {
  title: string;
  recordId: string;
}

const ObservationDeletionModal: FunctionComponent<ObservationDeletionModalProps> = ({
  onClose,
  recordId,
  title,
  ...modalProps
}) => {
  const { deletePatientObservation, isLoading, isSuccess } = useDeletePatientObservationUseCase();

  const onDelete = useCallback(() => {
    const dto: DeleteRecordRequestDTO = {
      recordId,
    };

    deletePatientObservation(dto);
  }, [deletePatientObservation, recordId]);

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess, onClose]);

  return (
    <DeleteModal
      title={title}
      onDelete={onDelete}
      isLoading={isLoading}
      onClose={onClose}
      {...modalProps}
    />
  );
};

export default ObservationDeletionModal;
