import React, { FunctionComponent } from 'react';

import StatisticModificationForm from './StatisticModificationForm';
import Step from 'src/components/common/Step';

interface StatisticModificationModalContentProps {
  currentStep: number;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
  onSubmit: () => void;
  patientId: string;
  setRecord: (record: any) => void;
  record: any;
}

export const StatisticModificationModalContent: FunctionComponent<StatisticModificationModalContentProps> =
  ({ currentStep, onClose, onPrevious, patientId, record }) => {
    return (
      <>
        <Step step={0} currentStep={currentStep}>
          <StatisticModificationForm statistic={record} onClose={onClose} onPrevious={onPrevious} />
        </Step>
      </>
    );
  };

export default StatisticModificationModalContent;
