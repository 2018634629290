import { useGetScoringStateQuery } from '../api';
import { useCallback, useState } from 'react';

enum ScoringStatePollingIntervals {
  NO = 0,
  POLLING = 3000,
}

export const useScoringState = () => {
  const [pollingInterval, setPollingInterval] = useState<ScoringStatePollingIntervals>(
    ScoringStatePollingIntervals.NO,
  );

  const { data, isError, isLoading } = useGetScoringStateQuery(
    undefined,
    { pollingInterval, skip: pollingInterval === ScoringStatePollingIntervals.NO }, // TODO: check if this really work
  );

  const startPolling = useCallback(() => {
    setPollingInterval(ScoringStatePollingIntervals.POLLING);
  }, []);

  const stopPolling = useCallback(() => {
    setPollingInterval(ScoringStatePollingIntervals.NO);
  }, []);

  return { data, isError, isLoading, startPolling, stopPolling };
};
