import React, { FunctionComponent } from 'react';
import { Page } from '@react-pdf/renderer';
import { useStyles } from './styles';

export const PDFPage: FunctionComponent = ({ children }) => {
  const styles = useStyles();
  return (
    <Page size="A4" style={styles.root}>
      {children}
    </Page>
  );
};
