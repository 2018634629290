import { useMemo } from 'react';

import { IAntecedentPreviewVM, bindFromEntity } from '../AntecedentPreviewVM';
import { MedicalRecordType } from 'src/domains/medical-record/entity';
import useGetPatientAntecedentHistoryUseCase from 'src/domains/medical-record/use-case/antecedent/useGetPatientAntecedentHistoryUseCase';

interface UseAntecedentHistoryParams {
  recordType: MedicalRecordType;
  relatedTemplateElementId: string;
  familyRelationshipId?: string;
}

const useAntecedentHistory = (params: UseAntecedentHistoryParams) => {
  const { data = [], isLoading, error } = useGetPatientAntecedentHistoryUseCase(params);

  const list: IAntecedentPreviewVM[] = useMemo(() => data.map(bindFromEntity), [data]);

  return {
    isLoading,
    list,
    error,
  };
};

export default useAntecedentHistory;
