import { FunctionComponent } from 'react';

type StepProps = {
  step: number;
  currentStep: number | any;
};

const Step: FunctionComponent<StepProps> = ({ step, currentStep, children }) => {
  if (currentStep !== step) return null;

  return <>{children}</>;
};

export default Step;
