import { IReportPreviewVM } from '../ReportPreviewVM';
import { reject, anyPass, isNil } from 'ramda';
import {
  CardiacAssessmentConsultation,
  Report,
} from '../../../../../../domains/patient/report/types';

export default interface ReportFormDTO extends Partial<CardiacAssessmentConsultation> {
  isCardiacAssessment: Report['isCardiacAssessment'];
}

export const bindFromReport = (report: Report): ReportFormDTO => ({
  isCardiacAssessment: report.isCardiacAssessment,
  ...report?.cardiacAssessmentConsultation,
});

export const bindFromVM = (vm: IReportPreviewVM): ReportFormDTO => {
  return {
    isCardiacAssessment: vm.isCardiacAssessment,
    ...vm?.cardiacAssessmentConsultation,
    lifestyleActionPriorities: reject(anyPass([isNil]))(
      vm.cardiacAssessmentConsultation?.lifestyleActionPriorities || {},
    ),
  };
};
