import AppBar from '../AppBar';
import useStyles from './styles';
import { Box, Container, CssBaseline } from '@material-ui/core';
import { FunctionComponent, ReactElement } from 'react';
import Breadcrumbs from '../Breadcrumbs';

interface LayoutProps {
  children?: ReactElement;
}

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <main className={classes.root}>
        <AppBar />
        <Container maxWidth={false}>
          <Breadcrumbs />
          <Container id="main-content" maxWidth={'lg'}>
            <Box
              data-testid={'main-content'}
              flexDirection={'column'}
              flexGrow={1}
              flexBasis={0}
              paddingTop={1}
            >
              {children}
            </Box>
          </Container>
        </Container>
      </main>
    </>
  );
};

export default Layout;
