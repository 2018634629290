import React, { FunctionComponent, useMemo } from 'react';

import ReportForm from '../../ReportForm';
import useAddReport from './hook';
import ReportFormDTO from '../../ReportForm/dto';

interface ReportCreationFormProps {
  externalId: string;
  onClose: () => void;
}

const ReportCreationForm: FunctionComponent<ReportCreationFormProps> = ({
  onClose,
  externalId,
}) => {
  const { add, isLoading, isSuccess } = useAddReport(externalId);

  const formDTO: ReportFormDTO = useMemo(
    () => ({
      label: 'Compte rendu LIVI',
      isCardiacAssessment: true,
    }),
    [],
  );
  return (
    <ReportForm
      isSuccess={isSuccess}
      onClose={onClose}
      onSubmit={add}
      isLoading={isLoading}
      values={formDTO}
    />
  );
};

export default ReportCreationForm;
