import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  circularProgress: {
    color: theme.palette.primary.contrastText,
  },
  posologyItem: {
    width: 'fit-content',
    marginRight: 40,
  },
  listItem: {
    justifyContent: 'center',
  },
  switch: {
    marginLeft: -12,
  },
  footerContainer: {
    alignItems: 'baseline',
    display: 'flex',
    flex: 1,
    flexWrap: 'nowrap',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
  footerButton: {
    marginRight: theme.spacing(2),
  },
  stepperContainer: {
    marginBottom: theme.spacing(5),
  },
}));
