export interface Organization {
  clientId?: number;
  enabled?: boolean;
  environment?: string;
  hasSecretKey?: boolean;
  id: number;
  name: string;
  patientAccountCreationDisabled?: boolean;
  patientConsentDisabled?: boolean;
}

export enum UserRole {
  ORG_MANAGER = 'ORG_MANAGER',
  ORG_USER = 'ORG_USER',
  ORG_ADMIN = 'ORG_ADMIN',
  PATIENT = 'PATIENT',
  SUPER_ADMIN = 'SUPER_ADMIN',
  INTERNAL = 'INTERNAL',
}
