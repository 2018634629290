export enum ScoringJobStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  NO_JOB = 'NO_JOB',
  ERROR = 'ERROR',
}

export interface ScoringState {
  jobUid: string;
  scoringJobStatus: ScoringJobStatus;
}
