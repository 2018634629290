import React, { FunctionComponent } from 'react';

import ReportModificationForm from './ModificationForm';

interface ReportModificationModalContentProps {
  onClose: () => void;
  onSubmit: () => void;
  record: any;
}

export const ReportModificationModalContent: FunctionComponent<ReportModificationModalContentProps> =
  ({ onClose, record }) => {
    return (
      <ReportModificationForm
        externalId={record.externalId}
        visualModel={record}
        onClose={onClose}
      />
    );
  };

export default ReportModificationModalContent;
