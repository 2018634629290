import { useMemo } from 'react';
import { selectDiagnosticsFromResponse } from 'src/domains/medical-record/repository/selector/observation/diagnostic';
import { STATUS_TYPE } from 'src/domains/medical-record/value-object/StatusType';
import { QualificationType } from '../../../entity/observation';
import { useGetLatestPatientObservationsQuery } from '../../../repository';
import { GetLatestPatientObservationsRequestDTO } from '../../../repository/dto/GetLatestPatientObservationsRequestDTO';
import { PatientObservationType } from '../../../value-object/PatientObservationType';
import { SubjectType } from '../../../value-object/SubjectType';
import useGetFamilyDisallowLatestPatientObservationsQuery from '../useGetFamilyDisallowLatestPatientObservationsQuery';

const useGetPatientDiagnosticsUseCase = () => {
  const {
    isLoading,
    data = [],
    error,
  } = useGetLatestPatientObservationsQuery({
    type: PatientObservationType.DIAGNOSTICS,
    statuses: [STATUS_TYPE.PRESENT, STATUS_TYPE.ABSENT],
    qualifications: [QualificationType.DIAGNOSTIC],
  });

  const filteredData = useMemo(
    () => selectDiagnosticsFromResponse(data)?.filter(d => d.familyRelationship?.id === undefined),
    [data],
  );

  return { data: filteredData, isLoading, error };
};

export const useGetPatientDiagnostics = (
  observationTypeId?: GetLatestPatientObservationsRequestDTO['observationType'],
  statuses?: GetLatestPatientObservationsRequestDTO['statuses'],
) => {
  const requestDTO: GetLatestPatientObservationsRequestDTO = useMemo(
    () => ({
      qualifications: [QualificationType.DIAGNOSTIC],
      subjects: [SubjectType.PATHOLOGIE, SubjectType.SYNDROME, SubjectType.REACTION],
      statuses,
      // allowFamilyRelationship: false,
      observationType: observationTypeId,
      type: PatientObservationType.DIAGNOSTICS,
    }),
    [observationTypeId, statuses],
  );

  const { data, isLoading, isError, error } =
    useGetFamilyDisallowLatestPatientObservationsQuery(requestDTO);

  return { data, isLoading, isError, error };
};

export default useGetPatientDiagnosticsUseCase;
