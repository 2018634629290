import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles(
  (theme: Theme) => ({
    breadcrumbLink: {
      fontWeight: 700,
      color: theme.palette.text.disabled,
    },
    activeBreadcrumbLink: {
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
  }),
  { name: 'Breadcrumb' },
);
