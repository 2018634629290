import { useCallback, useEffect, useState } from 'react';
import {
  useInvalidateMedicalRecord,
  useScoringState,
  useStartScoring,
} from '../../../../domains/scoring/use-cases';
import { ScoringJobStatus } from '../../../../domains/scoring/types';

const useUpdateScoring = () => {
  // State for the blocking modal window
  const [inProgress, setInProgress] = useState(false);

  // Hooks for networking
  const { startScoring, isSuccess, isError: isScoringError } = useStartScoring();
  const {
    data,
    startPolling,
    stopPolling,
    isLoading: isPollingLoading,
    isError: isPollingError,
  } = useScoringState();
  const { invalidateMedicalRecord } = useInvalidateMedicalRecord();

  // Additional service state
  const [scoringStatusOutdated, setScoringStatusOutdated] = useState<boolean>(false);

  // The first time we get data is when the user is just loading
  // the screen. This is necessary in order to block the user
  // even if he refreshed the page. After starting the scoring,
  // we have to use the new data, but the scoring state hook
  // uses the existing value from the cache. This effect is used
  // to control whether the data is fresh or not.
  useEffect(() => {
    if (data || isPollingLoading || isPollingError) setScoringStatusOutdated(false);
  }, [data, isPollingError, isPollingLoading]);

  // If scoring was started successfully, we need to subscribe to status updates by polling
  useEffect(() => {
    if (isSuccess) startPolling();
  }, [isSuccess, startPolling]);

  // If an error occurs at any stage, we should unblock the UI
  useEffect(() => {
    if (isScoringError || isPollingError) {
      setInProgress(false);
      stopPolling();
      // User should be notified about any errors
    }
  }, [isScoringError, stopPolling, isPollingError]);

  // Switching the UI state depending on the scoring state received
  useEffect(() => {
    if (data && !scoringStatusOutdated) {
      switch (data.scoringJobStatus) {
        case ScoringJobStatus.IN_PROGRESS:
          setInProgress(true);
          break;
        case ScoringJobStatus.ERROR:
        case ScoringJobStatus.NO_JOB:
        default:
          stopPolling();
          invalidateMedicalRecord();
          setInProgress(false);
          break;
      }
    }
  }, [data, scoringStatusOutdated, invalidateMedicalRecord, stopPolling, isPollingError]);

  const updateScoring = useCallback(async () => {
    // Show blocking modal
    setInProgress(true);
    // Mark existing data as outdated
    setScoringStatusOutdated(true);
    // Launch scoring
    startScoring({});
  }, [startScoring]);

  return { inProgress, updateScoring };
};

export default useUpdateScoring;
