import { useCallback } from 'react';
import { useUpdateReportUseCase } from 'src/domains/patient/report/use-case/useUpdatePatientReport';
import {
  Recommendation,
  ReferringDoctorStatus,
} from '../../../../../../../domains/patient/report/types';
import ReportFormDTO from '../../ReportForm/dto';

const useUpdateReport = (externalId: string) => {
  const { updateReport, ...rest } = useUpdateReportUseCase();

  const update = useCallback(
    (creation: ReportFormDTO) => {
      updateReport({
        externalId,
        createdDate: new Date().toISOString(),
        isCardiacAssessment: creation.isCardiacAssessment,
        cardiacAssessmentConsultation: creation.isCardiacAssessment
          ? {
              additionalEvaluations: creation?.additionalEvaluations || [],
              hasAdditionalTestsRecommendation: !!creation?.hasAdditionalTestsRecommendation,
              hasConsultationRecommendationWithSpecialist:
                !!creation?.hasConsultationRecommendationWithSpecialist,
              lifestyleActionPriorities: creation.lifestyleActionPriorities || {},
              recommendation: creation.recommendation as Recommendation,
              referringDoctorStatus: creation.referringDoctorStatus as ReferringDoctorStatus,
            }
          : undefined,
      });
    },
    [updateReport, externalId],
  );

  return { update, ...rest };
};

export default useUpdateReport;
