import {
  Antecedent,
  bindFromEvent,
  bindFromObservation,
  bindFromPatientEventAndType,
  bindFromPatientObservation,
  PatientAntecedent,
} from '../../entity/patientAntecedent';
import { LatestEventDTO, LatestObservationDTO } from '../../dto';
import { Observation, PatientObservation } from '../../entity/observation';
import { Event, PatientEventAndType } from '../../entity/event';
import { SubjectType } from '../../value-object/SubjectType';
import { selectDiagnosticObservations } from './observation/diagnostic';

export const antecedentEventSubjects: string[] = [
  SubjectType.NOMDEPRESCRIPTION,
  SubjectType.ACTE,
  SubjectType.ACTE_THERAPEUTIQUE,
  SubjectType.CONSULTATION,
  SubjectType.DCI,
];

export const antecedentDiagnosticSubjects: string[] = [
  SubjectType.PATHOLOGIE,
  SubjectType.AFFECTION,
  SubjectType.SYNDROME,
  SubjectType.REACTION,
];

export const selectPatientEventAndTypeAntecedentsFromResponse = (
  response: LatestEventDTO[],
): PatientEventAndType[] => {
  const filteredLatestObservationsDTO: LatestEventDTO[] = response.filter(
    ({ subject, patientEvent }) => antecedentEventSubjects.includes(subject?.label),
  );

  return filteredLatestObservationsDTO.map(latestEventDTO => ({
    event: latestEventDTO.patientEvent,
    type: latestEventDTO.eventType,
  }));
};

export const selectAntecedentDiagnosticsFromResponse = (
  response: LatestObservationDTO[] = [],
): PatientObservation[] => {
  const filteredLatestObservationsDTO: LatestObservationDTO[] = response.filter(
    ({ patientObservation }) =>
      antecedentDiagnosticSubjects.includes(patientObservation.subject.label),
  );

  return filteredLatestObservationsDTO.map(
    latestObservationDTO => latestObservationDTO.patientObservation,
  );
};

export const selectPatientDiagnosticAntecedentsFromResponse = (
  response: LatestObservationDTO[],
): PatientObservation[] => {
  const diagnostics: PatientObservation[] = selectAntecedentDiagnosticsFromResponse(response);

  return diagnostics.filter(
    currentDiagnostic =>
      currentDiagnostic.familyRelationship && !currentDiagnostic.familyRelationship?.id,
  );
};

export const selectAntecedentsFromObservationAndEventResponses = (
  observationResponse: LatestObservationDTO[] = [],
  patientResponse: LatestEventDTO[] = [],
): PatientAntecedent[] => {
  const eligibleDiagnostics: PatientObservation[] =
    selectPatientDiagnosticAntecedentsFromResponse(observationResponse);

  const diagnosticAntecedents: PatientAntecedent[] = eligibleDiagnostics.map(
    bindFromPatientObservation,
  );

  const eligiblePatientEventAndTypes: PatientEventAndType[] =
    selectPatientEventAndTypeAntecedentsFromResponse(patientResponse);

  const eventAntecedents: PatientAntecedent[] = eligiblePatientEventAndTypes.map(
    bindFromPatientEventAndType,
  );

  return [...diagnosticAntecedents, ...eventAntecedents];
};

export const selectAntecedentEvents = (events: Event[]): Antecedent[] => {
  const eligiblesEvents = events.filter(event =>
    antecedentEventSubjects.includes(event?.subject?.id),
  );

  return eligiblesEvents.map(bindFromEvent);
};

export const selectAntecedentObservations = (observations: Observation[]): Antecedent[] => {
  const eligiblesObservations = selectDiagnosticObservations(observations);

  return eligiblesObservations.map(bindFromObservation);
};
