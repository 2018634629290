import { Box, Button } from '@material-ui/core';
import { FunctionComponent } from 'react';
import UpdateScoringModal from './UpdateScoringModal';
import useUpdateScoring from './hook';

const UpdateScoringSection: FunctionComponent = () => {
  const { inProgress, updateScoring } = useUpdateScoring();

  return (
    <Box display={'flex'} flexGrow={1} justifyContent={'flex-end'} alignItems={'flex-start'}>
      <Button onClick={() => updateScoring()} variant="contained" color="primary">
        Actualiser
      </Button>
      <UpdateScoringModal open={inProgress} />
    </Box>
  );
};

export default UpdateScoringSection;
