import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  questionContainer: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
    textAlign: 'center',
  },
  circularProgress: {
    color: theme.palette.primary.contrastText,
  },
}));
