import React, { FunctionComponent, useMemo } from 'react';

import { IManifestationPreviewVM } from '../ManifestationPreviewVM';
import { useHistoryTableColumn } from './columns';
import HistoryModal from '../../../Modals/HistoryModal';
import useManifestationHistory from './hook';

interface ManifestationHistoryModalProps {
  manifestation: IManifestationPreviewVM;
  onClose: () => void;
  open: boolean;
}

export const ManifestationHistoryModal: FunctionComponent<ManifestationHistoryModalProps> = ({
  manifestation,
  onClose: handleOnClose,
  open,
}) => {
  const { isLoading, list } = useManifestationHistory({
    observationDescriptionId: manifestation.descriptionId,
    familyRelationshipId: manifestation.familyRelationshipId,
  });

  const title = useMemo(() => 'Historique de la manifestation', []);

  const columns = useHistoryTableColumn();

  return (
    <>
      <HistoryModal
        columns={columns}
        data={list}
        onClose={handleOnClose}
        open={open}
        isLoading={isLoading}
        title={title}
      />
    </>
  );
};

export default ManifestationHistoryModal;
