import {
  AlertClassKey,
  AutocompleteClassKey,
  TabPanelClassKey,
  TimelineDotClassKey,
  TimelineConnectorClassKey,
  TimelineItemClassKey,
} from '@material-ui/lab';
import { ChevronDown } from 'react-feather';
import { alpha, createTheme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';
import { grey } from '@material-ui/core/colors';

import colors from './colors';

import { Font } from '@react-pdf/renderer';

import Nunito400 from '../assets/fonts/Nunito/Nunito-Regular.ttf';
import Nunito400Italic from '../assets/fonts/Nunito/Nunito-Italic.ttf';
import Nunito700 from '../assets/fonts/Nunito/Nunito-Bold.ttf';
import Nunito700Italic from '../assets/fonts/Nunito/Nunito-BoldItalic.ttf';

import Tinos400 from '../assets/fonts/Tinos/Tinos-Regular.ttf';
import Tinos400Italic from '../assets/fonts/Tinos/Tinos-Italic.ttf';
import Tinos700 from '../assets/fonts/Tinos/Tinos-Bold.ttf';
import Tinos700Italic from '../assets/fonts/Tinos/Tinos-BoldItalic.ttf';

Font.register({
  family: 'Nunito',
  fonts: [
    { src: Nunito400, format: 'truetype', fontWeight: 'normal', fontStyle: 'normal' },
    { src: Nunito400Italic, format: 'truetype', fontWeight: 'normal', fontStyle: 'italic' },
    { src: Nunito700, format: 'truetype', fontWeight: 'bold', fontStyle: 'normal' },
    { src: Nunito700Italic, format: 'truetype', fontWeight: 'bold', fontStyle: 'italic' },
  ],
});
Font.register({
  family: 'Tinos',
  fonts: [
    { src: Tinos400, format: 'truetype', fontWeight: 'normal', fontStyle: 'normal' },
    { src: Tinos400Italic, format: 'truetype', fontWeight: 'normal', fontStyle: 'italic' },
    { src: Tinos700, format: 'truetype', fontWeight: 'bold', fontStyle: 'normal' },
    { src: Tinos700Italic, format: 'truetype', fontWeight: 'bold', fontStyle: 'italic' },
  ],
});

interface Overrides extends CoreOverrides {
  MuiAlert?: Partial<Record<AlertClassKey, CSSProperties | (() => CSSProperties)>> | undefined;
  MuiTabPanel?:
    | Partial<Record<TabPanelClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined;
  MuiTimelineDot?:
    | Partial<Record<TimelineDotClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined;
  MuiTimelineConnector?:
    | Partial<Record<TimelineConnectorClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined;
  MuiTimelineItem?:
    | Partial<Record<TimelineItemClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined;
  MuiAutocomplete?:
    | Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined;
  MuiKeyboardDatePicker?:
    | Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined;
}

const typography = {
  fontFamily: 'Nunito',
  fontSize: 14,
  h1: {
    fontSize: 32,
  },
  h2: {
    fontSize: 24,
    lineHeight: '24px',
  },
  h3: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '24px',
  },
  h4: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '18px',
  },
  h5: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '18px',
  },
  body1: {
    fontSize: 14,
    lineHeight: '18px',
  },
  body2: {
    fontSize: 12,
    lineHeight: '18px',
  },
  subtitle1: {
    fontSize: 13,
    lineHeight: '18px',
  },
  button: {
    fontWeight: 'bold',
    textTransform: 'none',
  },
};

const palette = {
  primary: {
    ...colors.INDIGO,
    contrastText: colors.WHITE,
  },
  secondary: {
    ...colors.TEAL,
    main: colors.TEAL.dark,
    contrastText: colors.WHITE,
  },
  background: {
    default: colors.CYAN.light,
    paper: colors.WHITE,
  },
  text: {
    primary: colors.INDIGO.dark,
    secondary: colors.TEAL.dark,
    disabled: colors.INDIGO.light,
  },
  action: {
    active: colors.LIGHT_BLUE.main,
    selected: colors.TEAL.main,
    focus: colors.TEAL.main,
    disabled: colors.INDIGO.light,
  },
  info: colors.LIGHT_BLUE,
  success: {
    main: colors.TEAL.light,
  },
  warning: colors.AMBER,
  error: colors.PINK,
};

const overrides: Overrides = {
  MuiCheckbox: {
    colorSecondary: {
      color: palette.primary.dark,
      '&$checked': {
        color: palette.primary.dark,
      },
    },
  },
  MuiDialog: {
    paper: {
      minWidth: 800,
    },
  },
  MuiDialogTitle: {
    root: {
      paddingBottom: 25,
      padding: 0,
    },
  },
  MuiDialogContent: {
    root: {
      padding: 0,
      '&:first-child': {
        padding: '0 !important',
      },
    },
  },
  MuiDialogActions: {
    root: {
      paddingTop: 25,
      padding: 0,
    },
  },
  MuiButton: {
    root: {
      borderRadius: 5,
      paddingRight: 5,
      paddingLeft: 5,
      paddingTop: 3,
      paddingBottom: 3,
      minWidth: 130,
      minHeight: 31,
    },
    sizeLarge: {
      height: 'auto',
      paddingTop: 13,
      paddingBottom: 14,
      paddingLeft: 22,
      paddingRight: 23,
      fontSize: 14,
    },
    outlined: {
      borderWidth: 1,
      paddingTop: 3,
      paddingBottom: 3,
    },
    outlinedPrimary: {
      borderColor: palette.primary.main,
    },
    contained: {
      paddingTop: 3,
      paddingBottom: 3,
    },
    containedPrimary: {
      padding: '4px 15px',
    },
  },
  MuiSelect: {
    root: {
      border: `solid 1px ${colors.INDIGO.main}`,
      fontWeight: 'bold',
    },
    outlined: {
      paddingLeft: 15,
      paddingRight: 0,
      paddingTop: 6,
      paddingBottom: 6,
      display: 'flex',
      alignItems: 'baseline',
    },
    icon: {
      height: 24,
      width: 15,
      color: colors.INDIGO.main,
      strokeWidth: 3,
    },
  },
  MuiTable: {
    root: {
      borderRadius: 5,
      boxShadow: `0 0 0 1px ${colors.LIGHT_BLUE.light}`,
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: 'none',
      fontSize: typography.body1.fontSize,
      paddingLeft: 15,
      paddingRight: 15,
    },
    head: {
      color: palette.text.disabled,
      fontSize: typography.body2.fontSize,
      lineHeight: typography.body2.lineHeight,
      paddingBottom: 7,
      paddingTop: 8,
    },
    body: {
      color: palette.text.primary,
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  MuiTableRow: {
    root: {
      borderBottom: `solid 1px ${colors.LIGHT_BLUE.light}`,
      height: 42,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    head: {
      height: 33,
      '&:last-child': {
        borderBottom: `solid 1px ${colors.LIGHT_BLUE.light}`,
      },
    },
  },
  MuiSvgIcon: {
    root: {
      fontSize: '1.3rem',
    },
    fontSizeSmall: {
      fontSize: '0.8rem',
    },
    colorAction: {
      stroke: palette.success.main,
      color: palette.success.main,
    },
    colorPrimary: {
      stroke: palette.primary.dark,
      color: palette.primary.dark,
    },
    colorError: {
      stroke: palette.error.main,
      color: palette.error.main,
    },
  },
  MuiAlert: {
    outlinedSuccess: {
      backgroundColor: alpha(palette.success.main, 0.15),
      color: palette.primary.main,
    },
    outlinedError: {
      backgroundColor: alpha(palette.error.main, 0.15),
      color: palette.primary.main,
    },
    outlinedInfo: {
      borderColor: colors.LIGHT_BLUE.light,
      backgroundColor: colors.CYAN.light,
      color: palette.primary.main,
    },
    outlinedWarning: {
      backgroundColor: alpha(palette.warning.main, 0.15),
      color: palette.primary.main,
    },
  },
  MuiListItem: {
    root: {
      '&$selected': {
        backgroundColor: `${alpha(grey[900], 0.04)} !important`,
        color: palette.action.active,
        '&:hover': {
          backgroundColor: `${alpha(grey[900], 0.04)} !important`,
          color: palette.action.active,
        },
      },
    },
    dense: {
      paddingTop: 1,
      paddingBottom: 1,
    },
  },
  MuiListItemText: {
    multiline: {
      marginTop: 4,
      marginBottom: 4,
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 25,
    },
  },
  MuiTabPanel: {
    root: {
      padding: 0,
    },
  },
  MuiTimelineDot: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      borderWidth: 3,
      height: 25,
      width: 25,
      boxShadow: 'none',
    },
  },
  MuiTimelineConnector: {
    root: {
      backgroundColor: palette.success.main,
      minHeight: 79,
      width: 4,
    },
  },
  MuiTableSortLabel: {
    root: {
      color: `${palette.action.disabled} !important`,
    },
    active: {
      color: `${palette.action.disabled} !important`,
      fontWeight: 'bold',
    },
    icon: {
      color: `${palette.action.disabled} !important`,
    },
  },
  MuiStepper: {
    horizontal: {
      justifyContent: 'center',
    },
  },
  MuiStepLabel: {
    labelContainer: {
      maxWidth: 130,
    },
    label: {
      ...typography.body1,
      color: palette.primary.main,
      textAlign: 'center',
    },
    active: {
      // @ts-ignore
      fontWeight: 'bold !important',
    },
  },
  MuiStepConnector: {
    root: {
      flex: 'inherit',
      width: 50,
    },
    line: {
      height: 1,
    },
  },
  MuiAutocomplete: {
    option: {
      paddingTop: 12,
      paddingBottom: 12,
      borderBottom: `solid 1px ${colors.INDIGO.light}`,
      // Hover
      '&[data-focus="true"]': {
        backgroundColor: `${alpha(colors.BLACK, 0.04)} !important`,
      },
      // Selected
      '&[aria-selected="true"]': {
        backgroundColor: `${palette.background.default} !important`,
      },
    },
    listbox: {
      padding: 0,
      border: `solid 1px ${colors.INDIGO.light}`,
      ' -ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    clearIndicator: {
      marginTop: 4,
      color: palette.primary.main,
    },
  },
  MuiSwitch: {
    root: {
      width: 46,
      height: 25,
      padding: 0,
      margin: 8,
    },
    switchBase: {
      padding: 1,
      '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
        transform: 'translateX(21px)',
        color: palette.background.default,
        '& + $track': {
          opacity: 1,
        },
      },
    },
    thumb: {
      width: 23,
      height: 23,
      boxShadow: 'none',
    },
    track: {
      borderRadius: 20,
      backgroundColor: colors.LIGHT_BLUE.light,
      opacity: 1,
      transition:
        'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
};

const theme = createTheme({
  // @ts-ignore
  typography,
  palette,
  shape: {
    borderRadius: 3,
  },
  overrides,
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiSelect: {
      variant: 'outlined',
      IconComponent: ChevronDown,
    },
    MuiTableCell: {
      align: 'left',
    },
    MuiStepLabel: {
      StepIconComponent: () => null,
    },
  },
});

export default theme;
