import { SchemaOf } from 'yup';
import * as yup from 'yup';

import { STATUS_TYPE, StatusType } from 'src/domains/medical-record/value-object/StatusType';

export type StatisticCreation = {
  status: StatusType;
  value: number;
};

export const schema: SchemaOf<StatisticCreation> = yup.object({
  status: yup.mixed().oneOf(Object.values(STATUS_TYPE)).required('Information requise'),
  value: yup
    .number()
    .min(0, `Veuillez saisir une valeur supérieure à 0`)
    .required('Information requise')
    .typeError('Format incorrect: veuillez saisir un nombre'),
});

export default schema;
