import { useMemo } from 'react';
import useGetRisksAndSuspicionsUseCase from 'src/domains/medical-record/use-case/observation/risk-and-suspicion/useGetRisksAndSuspicionsUseCase';
import { IRiskAndSuspicionPreviewVM, bindFromEntity } from '../RiskAndSuspicionPreviewVM';
import { STATUS_TYPE } from 'src/domains/medical-record/value-object/StatusType';

const useRiskAndSuspicion = () => {
  const { data = [], isLoading, error } = useGetRisksAndSuspicionsUseCase();

  const list = useMemo(
    () =>
      data.reduce<Record<string, IRiskAndSuspicionPreviewVM[]>>((acc, observation) => {
        const id = observation.observationDescription.evolvesFrom?.coreAppId;
        if (!id) {
          return acc;
        }
        return {
          ...acc,
          [id]: [...(acc[id] || []), bindFromEntity(observation)],
        };
      }, {}),
    [data],
  );

  return {
    isLoading,
    list,
    error,
  };
};

export const useRiskAndSuspicionList = () => {
  const { list: data, isLoading, error } = useRiskAndSuspicion();

  const list = useMemo(
    () =>
      Object.values(data).reduce((acc, item) => {
        const presentItem = item.find(i => i.status === STATUS_TYPE.PRESENT);
        if (presentItem) {
          acc.push(presentItem);
        }
        return acc;
      }, []),
    [data],
  );

  return {
    isLoading,
    list,
    error,
  };
};

export default useRiskAndSuspicion;
