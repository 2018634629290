import { Button, Paper, Typography } from '@material-ui/core';
import React, { FunctionComponent, memo } from 'react';

import Table, { TableProps } from 'src/components/common/Table';
import useStyles from './styles';

interface SectionProps extends TableProps {
  columns: TableProps['columns'];
  data: TableProps['data'];
  title?: string;
  subtitle?: string;
  onAddButtonClick?: () => void;
  addButtonLabel?: string;
  disableAddButton?: boolean;
}

const Section: FunctionComponent<SectionProps> = memo(
  ({
    columns,
    data,
    title,
    subtitle,
    onAddButtonClick,
    disableAddButton,
    addButtonLabel,
    ...tableProps
  }) => {
    const classes = useStyles();

    return (
      <Paper className={classes.paper}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            {title && (
              <div className={classes.titleHeaderContainer}>
                <Typography color={'primary'} variant={'h3'}>
                  {title}
                </Typography>
              </div>
            )}
            <div className={classes.buttonHeaderContainer}>
              {onAddButtonClick && (
                <Button
                  variant={'outlined'}
                  disabled={disableAddButton}
                  color={'primary'}
                  onClick={onAddButtonClick}
                >
                  {addButtonLabel ?? 'Ajouter'}
                </Button>
              )}
            </div>
          </div>
          <div className={classes.headerContainer}>
            {subtitle && (
              <div className={classes.titleHeaderContainer}>
                <Typography color={'primary'} variant={'body1'}>
                  {subtitle}
                </Typography>
              </div>
            )}
          </div>
          <Table columns={columns} data={data} {...tableProps} />
        </div>
      </Paper>
    );
  },
);

export default Section;
