import { fr } from 'date-fns/locale';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import React, { FunctionComponent } from 'react';

const DatePicker: FunctionComponent<KeyboardDatePickerProps> = props => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
      <KeyboardDatePicker
        disableToolbar
        disableFuture
        format="dd/MM/yyyy"
        color={'primary'}
        margin={'dense'}
        hiddenLabel
        leftArrowButtonProps={{ color: 'primary' }}
        rightArrowButtonProps={{ color: 'primary' }}
        keyboardIcon={<EventIcon color={'primary'} />}
        inputVariant={'outlined'}
        cancelLabel={'Annuler'}
        okLabel={'Confirmer'}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
