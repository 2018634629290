export enum LifestyleAction {
  FOOD = 'FOOD',
  PHYSICAL_ACTIVITY = 'PHYSICAL_ACTIVITY',
  SEDENTARY = 'SEDENTARY',
  BODY_MASS_INDEX = 'BODY_MASS_INDEX',
  TABACCO = 'TABACCO',
  ALCOHOL = 'ALCOHOL',
  STRESS = 'STRESS',
  SLEEP = 'SLEEP',
}

export enum AdditionalEvaluations {
  LIPID = 'LIPID',
  GLYCERIN = 'GLYCERIN',
  THYROID = 'THYROID',
  RENAL = 'RENAL',
  OTHER = 'OTHER',
}

export enum Recommendation {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum ReferringDoctorStatus {
  PRESENT = 'PRESENT',
  ABSENT = 'ABSENT',
  NO_ANSWER = 'NO_ANSWER',
}

export interface CardiacAssessmentConsultation {
  additionalEvaluations: AdditionalEvaluations[];
  hasAdditionalTestsRecommendation: boolean;
  hasConsultationRecommendationWithSpecialist: boolean;
  lifestyleActionPriorities: { [k in 'first' | 'second' | 'third']?: LifestyleAction };
  recommendation: Recommendation;
  referringDoctorStatus: ReferringDoctorStatus;
}

export interface Report {
  createdDate: string;
  isCardiacAssessment: boolean;
  cardiacAssessmentConsultation?: CardiacAssessmentConsultation;
}
