import { useCallback } from 'react';

import { ObservationCreationDTO } from '../../dto';
import {
  useCreatePatientObservationMutation,
  useForcePatientObservationCreationMutation,
  useReplacePatientObservationMutation,
} from '../../repository';
import { SubmitAction } from '../../../../pages/PatientDashboard/PatientDashboardUpperSection/MedicalRecord/Modals/PersistenceModal/PersistenceForm/constants';

const useUpdateObservationUseCase = () => {
  const [
    createObservation,
    {
      isLoading: isPatientObservationCreationLoading,
      isSuccess: isPatientObservationCreationSuccess,
      error: patientObservationCreationError,
    },
  ] = useCreatePatientObservationMutation();

  const [
    forcePatientObservationCreation,
    {
      isLoading: isForcePatientObservationCreationLoading,
      isSuccess: isForcePatientObservationCreationSuccess,
      error: forcePatientObservationCreationError,
    },
  ] = useForcePatientObservationCreationMutation();

  const [
    replacePatientObservation,
    {
      isLoading: isReplacePatientObservationLoading,
      isSuccess: isReplacePatientObservationSuccess,
      error: replacePatientObservationError,
    },
  ] = useReplacePatientObservationMutation();

  const updateObservation = useCallback(
    (
      patientId: string,
      observationId: string,
      dto: ObservationCreationDTO,
      submitAction: SubmitAction | undefined = SubmitAction.ADD_OR_UPDATE,
    ) => {
      if (submitAction === SubmitAction.ADD_OR_UPDATE) createObservation({ body: dto });

      if (submitAction === SubmitAction.REPLACE)
        replacePatientObservation({
          patientObservationId: observationId,
          ...dto,
        });

      if (submitAction === SubmitAction.FORCE_CREATION)
        forcePatientObservationCreation({ body: dto });
    },
    [createObservation, forcePatientObservationCreation, replacePatientObservation],
  );

  return {
    updateObservation,
    isCreationLoading: isPatientObservationCreationLoading,
    isCreationSuccess: isPatientObservationCreationSuccess,
    isReplaceLoading: isReplacePatientObservationLoading,
    isReplaceSuccess: isReplacePatientObservationSuccess,
    isForceCreationLoading: isForcePatientObservationCreationLoading,
    isForceCreationSuccess: isForcePatientObservationCreationSuccess,
    isSuccess:
      isForcePatientObservationCreationSuccess ||
      isReplacePatientObservationSuccess ||
      isPatientObservationCreationSuccess,
    error:
      patientObservationCreationError ||
      forcePatientObservationCreationError ||
      replacePatientObservationError,
  };
};

export default useUpdateObservationUseCase;
