import { useEffect, useState } from 'react';
import { initDB } from 'src/services/indexed-db';

/**
 * Init indexedDB database
 * @returns isDBReady -> true if DB is ready
 */
const useIndexedDB = () => {
  const [isDBReady, setIsDBReady] = useState<boolean>(false);

  useEffect(() => {
    initDB().then(status => setIsDBReady(status));
  }, []);

  return {
    isDBReady,
  };
};

export const IndexedDBProvider = ({ children }: { children: React.ReactElement }) => {
  const { isDBReady } = useIndexedDB();
  if (!isDBReady) return null;

  return <>{children}</>;
};
