import React, { FunctionComponent, useMemo } from 'react';

import { IManifestationPreviewVM } from '../../Manifestation/ManifestationPreviewVM';
import { useHistoryTableColumn } from './columns';
import HistoryModal from '../../../Modals/HistoryModal';
import useBehaviourHistory from './hook';

interface BehaviourHistoryModalProps {
  manifestation: IManifestationPreviewVM;
  onClose: () => void;
  open: boolean;
}

export const BehaviourHistoryModal: FunctionComponent<BehaviourHistoryModalProps> = ({
  manifestation,
  onClose: handleOnClose,
  open,
}) => {
  const { isLoading, list } = useBehaviourHistory({
    observationDescriptionId: manifestation.descriptionId,
    familyRelationshipId: manifestation.familyRelationshipId,
  });

  const title = useMemo(() => 'Historique du comportement', []);

  const columns = useHistoryTableColumn();

  return (
    <>
      <HistoryModal
        columns={columns}
        data={list}
        onClose={handleOnClose}
        open={open}
        isLoading={isLoading}
        title={title}
      />
    </>
  );
};

export default BehaviourHistoryModal;
