import { CoreAppItem } from 'src/domains/medical-record/value-object/Item';
import { MeasurementUnit } from 'src/types/quantity';
import { EditionFormat, PatientFactAndType } from 'src/domains/medical-record/entity/fact';
import { StatusType } from 'src/domains/medical-record/value-object/StatusType';
import { toTitleCase } from 'src/helpers/format/string';
import { AnswerType } from '../../../../../domains/medical-record/value-object/AnswerType';

export interface IFactPreviewVM {
  id: string;
  answerType: AnswerType;
  creationDate: Date;
  endDate: Date;
  typeId: string;
  measurementUnit?: MeasurementUnit;
  patientCareEpisodeId?: string;
  qualitativeResult: CoreAppItem;
  startDate: Date;
  status: StatusType;
  value?: number;
  label: string;
  isQuantifiable: boolean;
}

export const bindFromEntity = (factAndType: PatientFactAndType): IFactPreviewVM => {
  const value: number = parseFloat(factAndType.fact?.measure?.value.toFixed(2));

  return {
    id: factAndType.fact.id,
    answerType: factAndType.fact.answerType,
    creationDate: new Date(factAndType.fact.date),
    endDate: new Date(factAndType.fact.endDate),
    typeId: factAndType.type.id,
    measurementUnit: factAndType.fact?.measure?.measurementUnit,
    qualitativeResult: factAndType.fact.qualitativeResult,
    startDate: new Date(factAndType.fact.startDate),
    status: factAndType.fact.status,
    value,
    label: toTitleCase(factAndType.type.label),
    isQuantifiable: factAndType.type.editionFormat === EditionFormat.MEASURE, // FIXME: check if we need other values
  };
};
