import { AnswerType } from '../value-object/AnswerType';
import { Event, PatientEventAndType } from './event';
import { CoreAppItem, LabelListItem } from '../value-object/Item';
import { MedicalRecordType } from './index';
import { Observation, PatientObservation } from './observation';
import { Quantity } from '../../../types/quantity';
import { StatusType } from '../value-object/StatusType';
import { toTitleCase } from '../../../helpers/format/string';

export interface PatientAntecedent extends Antecedent {
  id: string;
  answerType?: AnswerType;
  status: StatusType;
  startDate: Date;
  creationDate: Date;
}

export interface Antecedent {
  relatedTemplateElementId: string;
  familyRelationship?: LabelListItem;
  measure?: Quantity;
  label: string;
  type: MedicalRecordType;
}

export const bindFromPatientObservation = (
  patientObservation: PatientObservation,
): PatientAntecedent => {
  return {
    id: patientObservation.id,
    answerType: patientObservation.answerType,
    creationDate: patientObservation.date,
    status: patientObservation.status,
    relatedTemplateElementId: patientObservation.observationDescription.id,
    startDate: patientObservation.startDate,
    familyRelationship: patientObservation.familyRelationship,
    label: patientObservation.observationDescription.observationType.label,
    type: MedicalRecordType.OBSERVATION,
  };
};

export const bindFromPatientEventAndType = (
  patientEventAndType: PatientEventAndType,
): PatientAntecedent => {
  return {
    id: patientEventAndType.event.id,
    answerType: patientEventAndType.event.answerType,
    creationDate: patientEventAndType.event.date,
    status: patientEventAndType.event.status,
    relatedTemplateElementId: patientEventAndType.type.id,
    startDate: patientEventAndType.event.startDate,
    familyRelationship: patientEventAndType.event?.familyRelationship as LabelListItem,
    label: patientEventAndType.type.label,
    type: MedicalRecordType.EVENT,
  };
};

export const bindFromEvent = (event: Event): Antecedent => {
  return {
    relatedTemplateElementId: event.eventType.id,
    familyRelationship: event.familyRelationship as CoreAppItem,
    label: toTitleCase(event.eventType.label),
    type: MedicalRecordType.EVENT,
  };
};

export const bindFromObservation = (observation: Observation): Antecedent => {
  return {
    relatedTemplateElementId: observation.observationDescription.id,
    familyRelationship: observation.familyRelationship as CoreAppItem,
    label: toTitleCase(observation.observationDescription.observationType.label),
    type: MedicalRecordType.OBSERVATION,
  };
};
