import React, { FunctionComponent } from 'react';

import CreationForm from './CreationForm';

interface ReportCreationModalContentProps {
  onClose: () => void;
  onSubmit: () => void;
  record: { externalId: string };
}

export const ReportCreationModalContent: FunctionComponent<ReportCreationModalContentProps> = ({
  onSubmit,
  record,
}) => {
  return <CreationForm externalId={record.externalId} onClose={onSubmit} />;
};

export default ReportCreationModalContent;
