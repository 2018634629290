import * as React from 'react';
import { createElement } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { EnhancedRouteConfig } from '../../../routes';
import { NavigationRoute } from 'src/routes/navigationRoute';
import { UserRole } from 'src/domains/user/entity/common';
import authenticationService from 'src/services/authentication';

interface PrivateRouteProps extends EnhancedRouteConfig {
  roles?: UserRole[];
  redirectTo?: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  roles = [UserRole.ORG_MANAGER, UserRole.ORG_USER],
  redirectTo = NavigationRoute.LOGIN,
  ...route
}) => {
  const isAuthenticated = authenticationService.isAuthenticated();
  const currentUser = authenticationService.getUser();
  const isRouteAvailable = roles.includes(currentUser?.role);

  if (isAuthenticated && isRouteAvailable) {
    return (
      <Route
        path={route.path}
        exact={route.exact}
        strict={route.strict}
        render={props =>
          route.render
            ? route.render({ ...props, route: route })
            : // @ts-ignore
              createElement(route.component, { ...props, route: route })
        }
      />
    );
  }

  return (
    <Redirect
      to={{
        pathname: redirectTo,
        state: { from: route.location },
      }}
    />
  );
};

export default PrivateRoute;
