export enum NavigationRoute {
  HOME = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  PATIENTS = '/patients',
  EXTERNAL_PATIENT = '/patients/external',
  PASSWORD_FORGOTTEN = '/forgotten-password',
  RESET_PASSWORD = '/reset-password',
  FIRST_SIGN_IN = '/first-sign-in/staff-member',
}
