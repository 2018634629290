import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { differenceInYears, format } from 'date-fns';
import { FunctionComponent, useMemo } from 'react';

import { ReactComponent as AvatarPatientSvg } from 'src/components/common/Icons/svgs/avatar-patient.svg';
import { useGetPatientQuery } from 'src/domains/patient/api';
import { toTitleCase } from 'src/helpers/format/string';
import authenticationService from 'src/services/authentication';
import UpdateScoringSection from './UpdateScoringSection';
import useStyles from './styles';
import { UserRole } from 'src/domains/user/entity/common';

interface PatientInformationsProps {
  patientId: string;
}

const PatientInformations: FunctionComponent<PatientInformationsProps> = ({ patientId }) => {
  const classes = useStyles();
  const {
    data: patient,
    isLoading,
    isError,
  } = useGetPatientQuery({
    id: patientId,
    organizationId: authenticationService.getOrganizationId(),
  });

  const patientBirthdate = useMemo(
    () => patient?.birthDate && format(new Date(patient?.birthDate), 'dd/MM/yyyy'),
    [patient?.birthDate],
  );

  const patientAge = useMemo(
    () => patient?.birthDate && differenceInYears(Date.now(), new Date(patient.birthDate)),
    [patient?.birthDate],
  );

  const currentUser = authenticationService.getUser();

  return (
    <>
      {isLoading && (
        <Box marginLeft={2}>
          <Box display={'flex'} alignItems={'center'} flexWrap={'nowrap'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              className={classes.widthAuto}
              marginRight={5}
            >
              <AvatarPatientSvg />
            </Box>
            <Box
              display={'flex'}
              flexWrap={'nowrap'}
              justifyContent={'flex-start'}
              alignItems={'flex-end'}
              className={classes.widthAuto}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                flexWrap={'nowrap'}
                className={classes.widthAuto}
                paddingRight={1}
              >
                <Box display={'flex'} flexWrap={'nowrap'}>
                  <Typography color={'primary'} variant={'body1'}>
                    NOM Prénom :
                  </Typography>
                </Box>
                <Box display={'flex'} flexWrap={'nowrap'}>
                  <Typography color={'primary'} variant={'body1'}>
                    Age (date de naissance) :
                  </Typography>
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                className={classes.widthAuto}
                marginLeft={2}
              >
                <Box display={'flex'} flexWrap={'nowrap'} height={24}>
                  <Skeleton variant={'text'} width={100} />
                </Box>
                <Box display={'flex'} flexWrap={'nowrap'} height={18}>
                  <Skeleton variant={'text'} width={100} />
                </Box>
              </Box>
              <Box
                display={'flex'}
                className={classes.widthAuto}
                paddingLeft={1}
                marginTop={0.5}
                alignSelf={'flex-start'}
              >
                <Box width={90} display={'flex'} justifyContent={'flex-end'}>
                  <Skeleton variant={'text'} width={30} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {!isLoading && !isError && patient && (
        <Box marginLeft={2}>
          <Box display={'flex'} alignItems={'stretch'} flexWrap={'nowrap'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              className={classes.widthAuto}
              marginRight={5}
            >
              <AvatarPatientSvg />
            </Box>
            <Box
              display={'flex'}
              flexWrap={'nowrap'}
              justifyContent={'flex-start'}
              alignItems={'baseline'}
              className={classes.widthAuto}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                flexWrap={'nowrap'}
                className={classes.widthAuto}
                paddingRight={1}
              >
                <Box display={'flex'} flexWrap={'nowrap'}>
                  <Typography color={'primary'} variant={'body1'}>
                    NOM Prénom :
                  </Typography>
                </Box>
                <Box display={'flex'} flexWrap={'nowrap'} paddingTop={0.25}>
                  <Typography color={'primary'} variant={'body1'}>
                    Age (date de naissance) :
                  </Typography>
                </Box>
                <Box display={'flex'} flexWrap={'nowrap'} paddingTop={0.25}>
                  <Typography color={'primary'} variant={'body1'}>
                    Sexe :
                  </Typography>
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                className={classes.widthAuto}
                marginLeft={2}
              >
                <Typography color={'primary'} variant={'h3'}>
                  {`${patient.lastName} ${toTitleCase(patient.firstName.toLowerCase())}`}
                </Typography>
                <Box display={'flex'} alignItems={'baseline'}>
                  <Typography color={'primary'} variant={'h5'}>
                    {`${patientAge} ans`}
                  </Typography>
                  <Box paddingLeft={1}>
                    <Typography color={'primary'} variant={'body1'}>
                      {`(${patientBirthdate})`}
                    </Typography>
                  </Box>
                </Box>
                <Box display={'flex'} alignItems={'baseline'} paddingTop={0.5}>
                  <Typography variant={'h5'} color={'primary'}>
                    {patient.gender === 'MALE' && 'Masculin'}
                    {patient.gender === 'FEMALE' && 'Féminin'}
                  </Typography>
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                flexWrap={'nowrap'}
                className={classes.widthAuto}
                paddingRight={1}
                paddingLeft={10}
              >
                <Box display={'flex'} flexWrap={'nowrap'}>
                  <Typography color={'primary'} variant={'body1'}>
                    N° RCU :
                  </Typography>
                </Box>
                {/*<Box display={'flex'} flexWrap={'nowrap'} paddingTop={0.25}>
                  <Typography color={'primary'} variant={'body1'}>
                    Dernière connexion :
                  </Typography>
                </Box>*/}
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                className={classes.widthAuto}
                marginLeft={2}
              >
                <Typography color={'primary'} variant={'h3'}>
                  {patient?.externalId || '-'}
                </Typography>
                {/*<Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
                  <DateField date={patient.birthDate} variant={'h5'} />
                  <Box paddingLeft={1}>
                    <Typography color={'primary'} variant={'body1'} className={classes.bold}>
                      {' - 12h00'}
                    </Typography>
                  </Box>
                </Box>*/}
              </Box>
            </Box>
            {currentUser?.role === UserRole.ORG_MANAGER && <UpdateScoringSection />}
          </Box>
        </Box>
      )}
    </>
  );
};

export default PatientInformations;
