import { BreadcrumbData } from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import React, { FunctionComponent } from 'react';
import { Link, Typography } from '@material-ui/core';

import useStyles from './styles';

const BreadcrumbItem: FunctionComponent<BreadcrumbData> = ({ breadcrumb, location, match }) => {
  const classes = useStyles();

  if (location?.pathname === match?.url)
    return (
      <Typography variant="body1" className={classes.activeBreadcrumbLink}>
        {breadcrumb}
      </Typography>
    );

  return (
    <Link
      component={NavLink}
      to={match?.url}
      variant={'body1'}
      className={classes.breadcrumbLink}
      underline={'none'}
    >
      {breadcrumb}
    </Link>
  );
};

export default BreadcrumbItem;
