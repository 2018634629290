import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import { useTableColumn } from './columns';

import PersistenceModal from '../../../Modals/PersistenceModal';
import Section from '../../../Section';
import ObservationDeletionModal from '../../DeletionModal';
import ManifestationHistoryModal from '../History';
import { IManifestationPreviewVM } from '../ManifestationPreviewVM';
import { ManifestationModificationModalContent } from '../Update';
import useManifestationList from './hook';

const ManifestationSection: FunctionComponent = () => {
  const { isLoading, list } = useManifestationList();

  const [visualModel, setVisualModel] = useState<IManifestationPreviewVM | undefined>(undefined);
  const [isHistoryModalOpened, toggleHistoryModal] = useToggle(false);
  const [isModificationModalOpened, toggleModificationModal] = useToggle(false);
  const [isDeletionModalOpened, toggleDeletionModal] = useToggle(false);
  /*  const [isCreationModalOpened, toggleCreationModal] = useToggle(false);

  const onCreationClick = useCallback(() => {
    toggleCreationModal();
  }, [toggleCreationModal]);*/

  const onModificationClick = useCallback(
    (vm: IManifestationPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleModificationModal();
      }
    },
    [toggleModificationModal],
  );

  const onHistoryClick = useCallback(
    (vm: IManifestationPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleHistoryModal();
      }
    },
    [toggleHistoryModal],
  );

  const onDeletionClick = useCallback(
    (vm: IManifestationPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleDeletionModal();
      }
    },
    [toggleDeletionModal],
  );

  const columns = useTableColumn({
    handleHistoryClick: onHistoryClick,
    handleDeleteClick: onDeletionClick,
    handleEditClick: onModificationClick,
  });

  const defaultSort = useMemo(
    () => [
      {
        id: 'creationDate',
        desc: true,
      },
    ],
    [],
  );

  return (
    <>
      <Section
        columns={columns}
        data={list}
        // onAddButtonClick={onCreationClick}
        title={'Manifestations déclarées'}
        isLoading={isLoading}
        defaultSort={defaultSort}
      />
      {/*<PersistenceModal
        contentType={ManifestationCreationModal}
        onClose={toggleCreationModal}
        open={isCreationModalOpened}
        patientId={patientId}
        selectedRecord={visualModel}
        title={'Ajouter une manifestation'}
        stepLabels={['Sélection du problème médical', 'Détails de la manifestation']}
      />*/}
      {visualModel && (
        <PersistenceModal
          title={'Mettre à jour une manifestation'}
          onClose={toggleModificationModal}
          open={isModificationModalOpened}
          contentType={ManifestationModificationModalContent}
          selectedRecord={visualModel}
        />
      )}
      {visualModel && (
        <ObservationDeletionModal
          recordId={visualModel.id}
          onClose={toggleDeletionModal}
          open={isDeletionModalOpened}
          title={'Supprimer la manifestation'}
        />
      )}
      {visualModel && (
        <ManifestationHistoryModal
          manifestation={visualModel}
          onClose={toggleHistoryModal}
          open={isHistoryModalOpened}
        />
      )}
    </>
  );
};

ManifestationSection.displayName = 'ManifestationSection';

export default ManifestationSection;
