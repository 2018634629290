import { Field, FormikProps } from 'formik';
import { Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { FunctionComponent, useMemo } from 'react';

import { statusOptions } from '../../helpers/statusOptions';
import { STATUS_TYPE, StatusType } from 'src/domains/medical-record/value-object/StatusType';
import AntecedentFormDTO from './dto';
import DatePicker from 'src/components/common/DatePicker';
import Select from 'src/components/common/Select';
import schema, { AntecedentCreation } from './schema';
import PersistenceForm from '../../Modals/PersistenceModal/PersistenceForm';
import useAntecedentForm from './hook';
import useStyles from '../../Modals/PersistenceModal/PersistenceForm/styles';

interface AntecedentFormProps {
  error?: any;
  isCreating?: boolean;
  isReplacing?: boolean;
  isForceCreating?: boolean;
  isSuccess: boolean;
  onClose: () => void;
  onPrevious?: () => void;
  onSubmit: (creation: AntecedentCreation) => void;
  patientId: string;
  record: AntecedentFormDTO;
}

const AntecedentForm: FunctionComponent<AntecedentFormProps> = ({
  error,
  isCreating,
  isForceCreating,
  isReplacing,
  isSuccess,
  onClose,
  onPrevious,
  onSubmit,
  record,
}) => {
  const classes = useStyles();
  const { data: familyRelationshipOptions, isLoading: isSelectOptionsRequestLoading } =
    useAntecedentForm();

  const isUpdateMode: boolean = useMemo(() => Boolean(record), [record]);

  const firstOption = useMemo(
    () => familyRelationshipOptions?.[0]?.value,
    [familyRelationshipOptions],
  );

  const initialValues: AntecedentCreation = useMemo(
    () => ({
      startDate: new Date(),
      familyRelationshipId: record?.familyRelationshipId || (firstOption as string),
      status: record?.status || STATUS_TYPE.PRESENT,
    }),
    [firstOption, record?.familyRelationshipId, record?.status],
  );

  return (
    <PersistenceForm
      error={error}
      initialValues={initialValues}
      isUpdateMode={isUpdateMode}
      isCreating={isCreating}
      isForceCreating={isForceCreating}
      isReplacing={isReplacing}
      isSubmittingSuccessfully={isSuccess}
      onClose={onClose}
      onPrevious={onPrevious}
      onSubmit={onSubmit}
      validationSchema={schema}
      formContent={(props: FormikProps<AntecedentCreation>) => (
        <>
          {!isSelectOptionsRequestLoading && (
            <>
              <div className={classes.row}>
                <div className={classes.inputSubjectContainer}>
                  <Typography variant={'body1'}>A partir de quand ?</Typography>
                </div>
                <div className={classes.inputContainer}>
                  <Field
                    id="startDate"
                    name="startDate"
                    component={DatePicker}
                    onChange={(value: MaterialUiPickersDate) => {
                      props.setFieldValue('startDate', value, true);
                    }}
                    value={props.values.startDate}
                    error={Boolean(props.errors.startDate)}
                    helperText={props.errors.startDate}
                    disabled={isUpdateMode}
                  />
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.inputSubjectContainer}>
                  <Typography variant={'body1'}>Personne atteinte:</Typography>
                </div>
                <div className={classes.inputContainer}>
                  <Field
                    value={props.values.familyRelationshipId}
                    options={familyRelationshipOptions}
                    id="familyRelationshipId"
                    name="familyRelationshipId"
                    placeholder=""
                    type="text"
                    onChange={(
                      event: React.ChangeEvent<{ name: string | undefined; value: string }>,
                    ) => {
                      props.setFieldValue('familyRelationshipId', event.target.value, true);
                    }}
                    disabled={isUpdateMode}
                    component={Select}
                  />
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.inputSubjectContainer}>
                  <Typography variant={'body1'}>Etat:</Typography>
                </div>
                <div className={classes.inputContainer}>
                  <Field
                    value={props.values.status}
                    options={statusOptions}
                    id="status"
                    name="status"
                    placeholder=""
                    type="text"
                    onChange={(
                      event: React.ChangeEvent<{ name: string | undefined; value: StatusType }>,
                    ) => {
                      props.setFieldValue('status', event.target.value, true);
                    }}
                    component={Select}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    />
  );
};

export default AntecedentForm;
