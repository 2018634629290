import { createApi } from '@reduxjs/toolkit/query/react';

import userAdminBaseQuery from '../../services/user-admin-http-client';
import { TokensResponse } from './types';

export const tokenApi = createApi({
  reducerPath: 'tokenApi',
  baseQuery: userAdminBaseQuery,
  endpoints: builder => ({
    generateToken: builder.mutation<TokensResponse, string | undefined>({
      query: patientId => ({
        url: '/api/v1/accounts/tokens',
        method: 'POST',
        body: {
          patientId: patientId,
        },
      }),
    }),
  }),
});

export const { useGenerateTokenMutation } = tokenApi;

export default tokenApi;
