import React, { FunctionComponent } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import Modal from '../../../../../components/common/Modal';

export interface UpdateScoringModalProps {
  open: boolean;
}

const UpdateScoringModal: FunctionComponent<UpdateScoringModalProps> = ({ open }) => {
  return (
    <Modal closeButton={null} open={open}>
      <Box display={'flex'} alignItems={'center'} overflow={'hidden'} flexDirection={'column'}>
        <Box marginX={'auto'} marginY={3}>
          <CircularProgress />
        </Box>
        <Typography align={'center'} variant="body1">
          En cours d'actualisation
        </Typography>
      </Box>
    </Modal>
  );
};

export default UpdateScoringModal;
