import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  searchBar: {
    flex: 1,
    top: 7,
  },
  validationButton: {
    height: 30,
    marginLeft: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
}));
