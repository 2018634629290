import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    cursor: 'default',
    color: theme.palette.primary.dark,
    minWidth: 'fit-content',
    opacity: 1,
    '&:first-child': {
      paddingLeft: 0,
      '& > span': {
        alignItems: 'flex-start',
      },
    },
  },
  selected: {
    color: theme.palette.action.active,
  },
  divider: {
    color: theme.palette.primary.dark,
    minWidth: 'fit-content',
  },
  tabPanel: {
    color: theme.palette.action.disabled,
    textDecoration: 'underline',
    fontStyle: 'italic',
  },
}));
