import React, { FunctionComponent, useMemo } from 'react';

import { IBehaviourPreviewVM } from '../../BehaviourPreviewVM';
import BehaviourForm from '../../BehaviourForm';
import useUpdateBehaviour from './hook';
import BehaviourFormDTO, { bindFromIBehaviourPreviewVM } from '../../BehaviourForm/dto';

interface BehaviourModificationFormProps {
  patientId: string;
  behaviour: IBehaviourPreviewVM;
  onClose: () => void;
  onPrevious: () => void;
}

const BehaviourModificationForm: FunctionComponent<BehaviourModificationFormProps> = ({
  onClose,
  onPrevious,
  patientId,
  behaviour,
}) => {
  const { update, isCreationLoading, isForceCreationLoading, isReplaceLoading, isSuccess, error } =
    useUpdateBehaviour(patientId, behaviour);

  const record: BehaviourFormDTO = useMemo(
    () => bindFromIBehaviourPreviewVM(behaviour),
    [behaviour],
  );

  return (
    <BehaviourForm
      error={error}
      isCreating={isCreationLoading}
      isForceCreating={isForceCreationLoading}
      isReplacing={isReplaceLoading}
      isSuccess={isSuccess}
      onClose={onClose}
      onPrevious={onPrevious}
      onSubmit={update}
      record={record}
    />
  );
};

export default BehaviourModificationForm;
