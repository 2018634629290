import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';
import App from './app';
import history from './app/history';
import AppLoader from './components/common/AppLoader';
import { IndexedDBProvider } from './indexeddb/IndexedDBProvider';
import reportWebVitals from './reportWebVitals';
import store from './store';
import theme from './theme';

ReactDOM.render(
  <React.Fragment>
    <CssBaseline />
    <IndexedDBProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <React.Suspense fallback={<AppLoader />}>
            <ConnectedRouter history={history}>
              <App />
            </ConnectedRouter>
          </React.Suspense>
        </ThemeProvider>
      </Provider>
    </IndexedDBProvider>
  </React.Fragment>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
