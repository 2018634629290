import { createApi } from '@reduxjs/toolkit/query/react';
import { ScoringState } from './types';
import engineBaseQuery from '../../services/engine-http-client';

interface StartScoringRequestDTO {
  force?: boolean;
}

export const scoringApi = createApi({
  reducerPath: 'scoringApi',
  baseQuery: engineBaseQuery,
  tagTypes: ['scoringState'],
  endpoints: builder => ({
    startScoring: builder.mutation<void, StartScoringRequestDTO>({
      invalidatesTags: ['scoringState'],
      query: ({ force }) => ({
        url: `/api/v1/users/startScoring`,
        method: 'POST',
        params: { force },
      }),
    }),
    getScoringState: builder.query<ScoringState, void>({
      providesTags: ['scoringState'],
      query: () => ({
        url: `/api/v1/users/scoringState`,
      }),
    }),
  }),
});

export const { useStartScoringMutation, useGetScoringStateQuery } = scoringApi;
