import { FunctionComponent, ReactElement } from 'react';

type PageProps = {
  children: ReactElement;
  testId?: String;
};

const Page: FunctionComponent<PageProps> = ({ children, testId }) => (
  <div id={'toma-pro-page'} data-testid={testId}>
    {children}
  </div>
);

export default Page;
