import { AnswerType } from 'src/domains/medical-record/value-object/AnswerType';
import { Antecedent, PatientAntecedent } from 'src/domains/medical-record/entity/patientAntecedent';
import { LabelListItem } from 'src/domains/medical-record/value-object/Item';
import { MedicalRecordType } from 'src/domains/medical-record/entity';
import { StatusType } from 'src/domains/medical-record/value-object/StatusType';
import { toTitleCase } from 'src/helpers/format/string';
import { Subject } from 'src/domains/medical-record/value-object/Subject';
import { SubjectType } from 'src/domains/medical-record/value-object/SubjectType';

export interface IAntecedentPreviewVM {
  id: string;
  author?: AnswerType;
  startDate: Date;
  creationDate: Date;
  relatedTemplateElementId: Antecedent['relatedTemplateElementId'];
  familyRelationship?: LabelListItem;
  label: string;
  status: StatusType;
  subjectId: Subject['id'];
  type: MedicalRecordType;
}

export const bindFromEntity = (antecedent: PatientAntecedent): IAntecedentPreviewVM => {
  return {
    id: antecedent.id,
    author: antecedent.answerType,
    creationDate: new Date(antecedent.creationDate),
    startDate: new Date(antecedent.startDate),
    familyRelationship: antecedent?.familyRelationship,
    label: toTitleCase(antecedent.label),
    relatedTemplateElementId: antecedent.relatedTemplateElementId,
    status: antecedent.status,
    type: antecedent.type,
    subjectId: SubjectType.SCORE,
  };
};
