import { useMemo } from 'react';

import { bindFromEntity, IDiagnosticPreviewVM } from '../DiagnosticPreviewVM';
import useGetPatientDiagnosticHistoryUseCase from 'src/domains/medical-record/use-case/observation/diagnostic/useGetPatientDiagnosticHistoryUseCase';

interface UseDiagnosticHistoryParams {
  observationDescriptionId: string;
  familyRelationshipId?: string;
}

const useDiagnosticHistory = (params: UseDiagnosticHistoryParams) => {
  const { data = [], isLoading, error } = useGetPatientDiagnosticHistoryUseCase(params);

  const diagnosticList: IDiagnosticPreviewVM[] = useMemo(() => data.map(bindFromEntity), [data]);

  return {
    isLoading,
    diagnosticList,
    error,
  };
};

export default useDiagnosticHistory;
