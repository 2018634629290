import React, { FunctionComponent } from 'react';
import { TableCell, TableRow } from '@material-ui/core';

interface EmptyDataRowProps {
  message: string;
  colSpan?: number;
}

const EmptyDataRow: FunctionComponent<EmptyDataRowProps> = ({ message, colSpan = 2 }) => (
  <TableRow>
    <TableCell align={'center'} colSpan={colSpan}>
      {message}
    </TableCell>
  </TableRow>
);

export default EmptyDataRow;
