import { useMemo } from 'react';
import { StyleSheet } from '@react-pdf/renderer';

export const useStyles = () => {
  return useMemo(
    () =>
      StyleSheet.create({
        root: { paddingVertical: 10 },
        header: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          paddingBottom: 5,
        },
        title: { fontSize: 9, fontWeight: 'normal' },
        listContainer: {
          display: 'flex',
          flexDirection: 'column',
        },
        listItem: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          paddingBottom: 5,
        },
        listItemText: { fontSize: 9, fontWeight: 'normal' },
        listItemValue: { fontSize: 10, fontWeight: 'bold' },
      }),
    [],
  );
};
