import { Box, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';

type NominalContainerProps = {
  title?: string;
};

const NominalContainer: FunctionComponent<NominalContainerProps> = ({ title, children }) => {
  return (
    <Box width={'100%'}>
      <Box display={'flex'} justifyContent={'center'} paddingBottom={2}>
        <Typography color={'primary'} variant={'h3'}>
          {title}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {children}
      </Box>
    </Box>
  );
};

export default NominalContainer;
