import { StatusType } from 'src/domains/medical-record/value-object/StatusType';
import { Observation } from 'src/domains/medical-record/entity/observation';
import { IManifestationPreviewVM } from '../ManifestationPreviewVM';

export default interface ManifestationFormDTO {
  observationDescriptionId: string;
  status?: StatusType;
  startDate?: Date;
}

export const bindFromObservation = (observation: Observation): ManifestationFormDTO => {
  return {
    observationDescriptionId: observation.observationDescription.id,
  };
};

export const bindFromIManifestationPreviewVM = (
  vm: IManifestationPreviewVM,
): ManifestationFormDTO => {
  return {
    observationDescriptionId: vm.descriptionId,
    status: vm.status,
    startDate: vm.startDate,
  };
};
