import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import { authenticationApi } from '../domains/authentication/api';
import { medicalRecordApi } from '../domains/medical-record/repository';
import { patientApi } from '../domains/patient/api';
import { userApi } from 'src/domains/user/repository';
import { scoringApi } from 'src/domains/scoring/api';
import history from '../app/history';
import rootReducer from './reducers';
import tokenApi from '../domains/authentication/token-api';
import { indexedDBApi } from '../domains/patient/report/api';

export const sagaMiddleware = createSagaMiddleware();

export const configureAppStore = (preloadedState = {}) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware()
        .concat(authenticationApi.middleware)
        .concat(patientApi.middleware)
        .concat(indexedDBApi.middleware)
        .concat(userApi.middleware)
        .concat(medicalRecordApi.middleware)
        .concat(tokenApi.middleware)
        .concat(scoringApi.middleware)
        .concat(routerMiddleware(history)),
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  });

  return store;
};

export const store = configureAppStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
