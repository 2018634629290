import { MeasurementUnit } from 'src/types/quantity';
import { StatusType } from 'src/domains/medical-record/value-object/StatusType';
import { EditionFormat, Fact } from '../../../../../../../domains/medical-record/entity/fact';
import { IFactPreviewVM } from '../../FactPreviewVM';

export default interface StatisticFormDTO {
  id?: string;
  label: string;
  factTypeId: string;
  status?: StatusType;
  value?: number;
  measurementUnit?: MeasurementUnit;
  isQuantifiable: boolean;
}

export const bindFromFact = (fact: Fact): StatisticFormDTO => {
  return {
    label: fact.factType.label,
    factTypeId: fact.factType.id,
    isQuantifiable: fact.factType.editionFormat === EditionFormat.MEASURE, // FIXME: check if we need other values
  };
};

export const bindFromIFactPreviewVM = (vm: IFactPreviewVM): StatisticFormDTO => {
  return {
    id: vm.id,
    label: vm.label,
    factTypeId: vm.typeId,
    status: vm.status,
    value: vm.value,
    measurementUnit: vm.measurementUnit,
    isQuantifiable: vm.isQuantifiable,
  };
};
