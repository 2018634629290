import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  inputContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputWrapper: {
    maxWidth: 200,
  },
  inputStyles: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 'large',
    letterSpacing: 15,
  },
  errorMessage: {
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  askCodeValidationContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(6),
    textAlign: 'center',
  },
  askCodeValidation: {
    color: theme.palette.action.focus,
  },
  askCodeValidationAction: {
    color: theme.palette.action.focus,
  },
  buttonContainer: {
    paddingTop: theme.spacing(6),
  },
  processDetail: {
    paddingTop: theme.spacing(1),
  },
}));
