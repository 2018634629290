import { useMemo } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { usePDFThemeContext } from '../context';

export const useStyles = () => {
  const theme = usePDFThemeContext();
  return useMemo(
    () =>
      StyleSheet.create({
        root: {
          padding: 30,
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.primary.dark,
        },
      }),
    [theme],
  );
};
