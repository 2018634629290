import { differenceInDays, formatDuration } from 'date-fns';
import { divide, gte } from 'ramda';
import { fr } from 'date-fns/locale';
import { isNilOrEmpty } from 'ramda-adjunct';

import { Duration } from 'src/types/duration';

interface ToPercentageParams {
  currentDate?: Date;
  startDate: Date;
  endDate: Date;
}

export const toDurationLabel = (duration: Duration): string => {
  if (isNilOrEmpty(duration)) return '-';

  return formatDuration(duration as Omit<Duration, 'xsdRepresentation'>, { locale: fr });
};

export const toPercentage = ({ currentDate, startDate, endDate }: ToPercentageParams): number => {
  if (!startDate || !endDate) return 0;

  const comparedDate = currentDate ? new Date(currentDate) : Date.now();

  const timeSinceStartDate = differenceInDays(
    new Date(comparedDate) || Date.now(),
    new Date(startDate),
  );

  const numerator: number = gte(timeSinceStartDate, 1) ? 1 : timeSinceStartDate;
  const denominator: number = differenceInDays(new Date(endDate), new Date(startDate));

  return Number(divide(numerator, denominator).toFixed(2));
};
