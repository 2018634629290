import { Typography } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { FunctionComponent, useMemo } from 'react';

import { statusOptions } from '../../../helpers/statusOptions';
import { STATUS_TYPE, StatusType } from 'src/domains/medical-record/value-object/StatusType';
import DatePicker from 'src/components/common/DatePicker';
import Select from 'src/components/common/Select';
import schema, { ManifestationCreation } from './schema';
import ManifestationFormDTO from './dto';
import PersistenceForm from '../../../Modals/PersistenceModal/PersistenceForm';
import useStyles from '../../../Modals/PersistenceModal/PersistenceForm/styles';
import { SubmitAction } from '../../../Modals/PersistenceModal/PersistenceForm/constants';

interface ManifestationFormProps {
  error?: any;
  isCreating?: boolean;
  isReplacing?: boolean;
  isForceCreating?: boolean;
  isSuccess: boolean;
  onClose: () => void;
  onPrevious?: () => void;
  onSubmit: (manifestation: ManifestationCreation, submitAction?: SubmitAction) => void;
  record?: ManifestationFormDTO;
}

const ManifestationForm: FunctionComponent<ManifestationFormProps> = ({
  error,
  isCreating,
  isForceCreating,
  isReplacing,
  isSuccess,
  onClose,
  onPrevious,
  onSubmit,
  record,
}) => {
  const classes = useStyles();

  const initialValues: ManifestationCreation = useMemo(
    () => ({
      startDate: new Date(),
      status: record?.status || STATUS_TYPE.PRESENT,
    }),
    [record?.status],
  );

  const isUpdateMode: boolean = useMemo(() => Boolean(record), [record]);

  return (
    <PersistenceForm
      error={error}
      initialValues={initialValues}
      isUpdateMode={isUpdateMode}
      isCreating={isCreating}
      isForceCreating={isForceCreating}
      isReplacing={isReplacing}
      isSubmittingSuccessfully={isSuccess}
      onClose={onClose}
      onPrevious={onPrevious}
      onSubmit={onSubmit}
      validationSchema={schema}
      formContent={(props: FormikProps<ManifestationCreation>) => (
        <>
          <div className={classes.row}>
            <div className={classes.inputSubjectContainer}>
              <Typography variant={'body1'}>A partir de quand ?</Typography>
            </div>
            <div className={classes.inputContainer}>
              <Field
                id="startDate"
                name="startDate"
                component={DatePicker}
                onChange={(value: MaterialUiPickersDate) => {
                  props.setFieldValue('startDate', value, true);
                }}
                value={props.values.startDate}
                error={Boolean(props.errors.startDate)}
                helperText={props.errors.startDate}
                disabled={isUpdateMode}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.inputSubjectContainer}>
              <Typography variant={'body1'}>Manifestation</Typography>
            </div>
            <div className={classes.inputContainer}>
              <Field
                value={props.values.status}
                options={statusOptions}
                margin={'dense'}
                variant={'outlined'}
                color={'primary'}
                id="status"
                name="status"
                placeholder=""
                type="text"
                onChange={(
                  event: React.ChangeEvent<{ name: string | undefined; value: StatusType }>,
                ) => {
                  props.setFieldValue('status', event.target.value, true);
                }}
                component={Select}
              />
            </div>
          </div>
        </>
      )}
    />
  );
};

export default ManifestationForm;
