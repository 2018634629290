import React, { FunctionComponent } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { useStyles } from './styles';

export interface PDFStatusMessageProps {
  title: string;
  text: string | number;
  color?: 'warning' | 'info' | 'error' | 'success';
}

export const PDFStatusMessage: FunctionComponent<PDFStatusMessageProps> = ({
  text,
  title,
  color,
}) => {
  const styles = useStyles({ color });
  return (
    <View style={styles.root}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.textContainer}>
        <Text style={styles.text}>{text}</Text>
      </View>
    </View>
  );
};
