import { Report } from 'src/domains/patient/report/types';
import { DatabaseReport } from 'src/services/indexed-db';

export interface IReportPreviewVM {
  externalId: string;
  createdDate: Date;
  label: string;
  isCardiacAssessment: Report['isCardiacAssessment'];
  cardiacAssessmentConsultation?: Report['cardiacAssessmentConsultation'];
}

const reportLabel = 'Compte rendu LIVI';
export const bindFromEntity = (report: DatabaseReport): IReportPreviewVM => {
  return {
    externalId: report.externalId,
    createdDate: new Date(report.createdDate),
    label: reportLabel,
    isCardiacAssessment: report.isCardiacAssessment,
    cardiacAssessmentConsultation: report?.cardiacAssessmentConsultation,
  };
};
