import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useGenerateToken from '../../domains/authentication/hooks/useGenerateToken';
import { useGetPatientByExternalIdQuery } from '../../domains/patient/api';

interface PatientDashboardPageQueryParams {
  id: string;
}

interface UsePatientDashboard {
  externalId: string;
  isPatientIdentified: boolean;
  isLoading: boolean;
  patientId?: string;
  patientRefId?: string;
}

const usePatientDashboard = (): UsePatientDashboard => {
  const { id: externalId } = useParams<PatientDashboardPageQueryParams>();
  const {
    generateToken,
    isLoading: isGenerateTokensRequestLoading,
    tokenLoaded,
  } = useGenerateToken();

  const {
    data: patient,
    isLoading: isGetPatientRequestLoading,
    isSuccess: isGetPatientRequestSuccess,
  } = useGetPatientByExternalIdQuery({ externalId });

  const isPatientIdentified: boolean = useMemo(
    () => !isGetPatientRequestLoading && isGetPatientRequestSuccess && !!patient?.id && tokenLoaded,
    [isGetPatientRequestLoading, isGetPatientRequestSuccess, patient?.id, tokenLoaded],
  );

  const patientId: string | undefined = useMemo(() => patient?.id, [patient?.id]);

  useEffect(() => {
    if (isGetPatientRequestSuccess) {
      generateToken(patientId);
    }
  }, [generateToken, isGetPatientRequestSuccess, patientId]);

  return {
    externalId,
    isPatientIdentified,
    isLoading: isGetPatientRequestLoading || isGenerateTokensRequestLoading,
    patientId: patient?.id,
  };
};

export default usePatientDashboard;
