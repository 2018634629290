import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import { useCountdown } from 'rooks';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { Duration } from 'src/types/duration';
import { LoginError } from 'src/domains/authentication/types';
import { NavigationRoute } from 'src/routes/navigationRoute';
import { toDurationLabel } from 'src/helpers/format/duration';
import { schema, ValidationCode } from './schema';
import { useRequestSecurityCodeMutation } from 'src/domains/authentication/api';
import authenticationService from 'src/services/authentication';
import NominalContainer from 'src/components/common/NominalContainer';
import useStyles from './styles';

type SecurityCodeFormProps = {
  onSubmit: (validation: ValidationCode) => void;
  isLoading: boolean;
  errorMessage?: string;
  remainingAttempts?: number;
  accessToken?: string;
};

const SecurityCodeForm: FunctionComponent<SecurityCodeFormProps> = ({
  accessToken,
  onSubmit,
  isLoading = false,
  errorMessage,
  remainingAttempts,
}) => {
  const classes = useStyles();
  const [isRequestSecurityCodeBlocked, setIsRequestSecurityCodeBlocked] = useState<boolean>(
    !!authenticationService.getSecurityCodeRetryAfter(),
  );
  const [requestCode, { isLoading: isSecurityCodeRequestLoading, isError, error }] =
    useRequestSecurityCodeMutation();
  const history = useHistory();

  const retryAfter = authenticationService.getSecurityCodeRetryAfter();

  useEffect(() => {
    if (isError && error) {
      if ('data' in error) {
        if (error.data) {
          const errorCode = (error?.data as LoginError).errorCode;
          const errorDetails = (error?.data as LoginError).errorDetails;

          if (errorCode === 'security.code.request.rate.limit.exceeded') {
            if (errorDetails?.retryAfter)
              authenticationService.setSecurityCodeRetryAfter(new Date(errorDetails?.retryAfter));

            setIsRequestSecurityCodeBlocked(true);
          }

          if (errorCode === 'access.token.expired') {
            const { from } = { from: { pathname: NavigationRoute.LOGIN } };
            history.replace(from);
          }
        }
      }
    }
  }, [error, history, isError]);

  const onSecurityCodeRequestBlockedEnd = useCallback(() => {
    authenticationService.clearSecurityCodeRetryAfter();
    setIsRequestSecurityCodeBlocked(false);
  }, []);

  const countdown = useCountdown(new Date(retryAfter), {
    interval: 1000,
    onEnd: onSecurityCodeRequestBlockedEnd,
  });

  const remainingTime = useMemo(() => {
    const remaining = intervalToDuration({
      start: 0,
      end: 1000 * countdown,
    });

    return toDurationLabel(remaining as Duration);
  }, [countdown]);

  const requestSecurityCode = useCallback(() => {
    if (accessToken) {
      requestCode(accessToken);
    }
  }, [accessToken, requestCode]);

  const formik = useFormik({
    initialValues: {
      code: undefined,
    },
    validationSchema: schema,
    onSubmit,
  });

  return (
    <NominalContainer title={'Identification à double facteurs'}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.inputContainer}>
          <div className={classes.inputWrapper}>
            <TextField
              variant={'outlined'}
              color={'primary'}
              hiddenLabel
              fullWidth
              id="code"
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
              inputProps={{ min: 4, max: 4, maxLength: 4, inputMode: 'numeric' }}
              InputProps={{
                classes: {
                  input: classes.inputStyles,
                },
              }}
            />
            <div className={classes.errorMessage}>
              {errorMessage && (
                <Typography variant={'body1'} color={'error'}>
                  {errorMessage}
                </Typography>
              )}
              {remainingAttempts && (
                <Typography variant={'body1'} color={'error'}>
                  Tentatives restantes: {remainingAttempts}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <div className={classes.processDetail}>
          <Typography color={'primary'} variant={'body1'}>
            Un message contenant un code de validation vous a été envoyé par SMS. Entrez le code
            pour continuer.
          </Typography>
        </div>
        <div className={classes.askCodeValidationContainer}>
          <Typography color={'primary'} variant={'body1'} className={classes.askCodeValidation}>
            Vous n'avez pas reçu le code de validation ?
          </Typography>
          {!isRequestSecurityCodeBlocked ? (
            <Button
              variant={'text'}
              onClick={requestSecurityCode}
              disabled={isSecurityCodeRequestLoading}
            >
              <Typography
                color={'primary'}
                variant={'h5'}
                className={classes.askCodeValidationAction}
              >
                Renvoyer le code
              </Typography>
            </Button>
          ) : (
            <Typography color={'primary'} variant={'h5'} className={classes.askCodeValidation}>
              Vous pourrez le redemander dans {remainingTime}
            </Typography>
          )}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            color={'primary'}
            fullWidth
            size={'large'}
            variant={'contained'}
            type={'submit'}
            disabled={isLoading}
          >
            {isLoading && <CircularProgress size={24} />}
            {!isLoading && 'Valider'}
          </Button>
        </div>
      </form>
    </NominalContainer>
  );
};

export default SecurityCodeForm;
