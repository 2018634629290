import React, { FunctionComponent, useMemo } from 'react';

import { IFactPreviewVM } from '../../FactPreviewVM';
import { useHistoryTableColumn } from './columns';
import HistoryModal from '../../../Modals/HistoryModal';
import useStatisticHistory from './hook';

interface StatisticHistoryModalProps {
  statistic: IFactPreviewVM;
  onClose: () => void;
  open: boolean;
}

export const StatisticHistoryModal: FunctionComponent<StatisticHistoryModalProps> = ({
  statistic,
  onClose: handleOnClose,
  open,
}) => {
  const { isLoading, list } = useStatisticHistory({
    factTypeId: statistic.typeId,
  });

  const title = useMemo(() => 'Historique de la statistique', []);

  const columns = useHistoryTableColumn();

  return (
    <>
      <HistoryModal
        columns={columns}
        data={list}
        onClose={handleOnClose}
        open={open}
        isLoading={isLoading}
        title={title}
      />
    </>
  );
};

export default StatisticHistoryModal;
