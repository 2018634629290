import React, { FunctionComponent, memo } from 'react';
import { Button, ButtonProps } from '@material-ui/core';

import useStyles from './styles';

export interface ButtonParams {
  props?: ButtonProps;
  label: string | JSX.Element;
}

interface ModalFooterProps {
  buttons: ButtonParams[];
}

const ModalFooterButton: FunctionComponent<ButtonParams> = memo((button: ButtonParams) => {
  const classes = useStyles();

  return (
    <div className={classes.footerButton}>
      <Button {...button.props}>{button.label}</Button>
    </div>
  );
});

const ModalFooterButtons: FunctionComponent<ModalFooterProps> = memo(({ buttons = [] }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.footerContainer}>
        {buttons.map(button => (
          <div className={classes.footerButton} key={`menu-button-${button.label}`}>
            <ModalFooterButton props={button.props} label={button.label} />
          </div>
        ))}
      </div>
    </>
  );
});

export default ModalFooterButtons;
