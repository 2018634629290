import { Report } from '../domains/patient/report/types';

const REPORT_STORE = 'reports';

let request: IDBOpenDBRequest;
let db: IDBDatabase;
let version = 1;

export type DatabaseReport = Report & {
  externalId: string;
};

export const initDB = (): Promise<boolean> => {
  return new Promise(resolve => {
    request = indexedDB.open('myDB');

    request.onupgradeneeded = () => {
      db = request.result;

      if (!db.objectStoreNames.contains(REPORT_STORE)) {
        console.debug('Creating users store');
        db.createObjectStore(REPORT_STORE, { keyPath: 'externalId' });
      }
    };

    request.onsuccess = () => {
      db = request.result;
      version = db.version;
      console.debug('request.onsuccess - initDB', version);
      resolve(true);
    };

    request.onerror = () => {
      resolve(false);
    };
  });
};

const setReportInDB = (data: DatabaseReport): Promise<DatabaseReport> => {
  return new Promise((resolve, reject) => {
    request = indexedDB.open('myDB', version);

    request.onsuccess = () => {
      console.debug('request.onsuccess - addData', data);
      db = request.result;
      const tx = db.transaction(REPORT_STORE, 'readwrite');
      const store = tx.objectStore(REPORT_STORE);
      store.add(data);
      resolve(data);
    };

    request.onerror = () => {
      const error = request.error?.message;
      if (error) {
        reject(error);
      } else {
        reject('Unknown error');
      }
    };
  });
};

const updateReportInDB = (data: DatabaseReport): Promise<DatabaseReport> => {
  return new Promise((resolve, reject) => {
    request = indexedDB.open('myDB', version);

    request.onsuccess = () => {
      console.debug('request.onsuccess - updateData', data);
      db = request.result;
      const tx = db.transaction(REPORT_STORE, 'readwrite');
      const store = tx.objectStore(REPORT_STORE);
      store.put(data);
      resolve(data);
    };

    request.onerror = () => {
      const error = request.error?.message;
      if (error) {
        reject(error);
      } else {
        reject('Unknown error');
      }
    };
  });
};

const getReportFromDB = (externalId: string): Promise<DatabaseReport | null> => {
  return new Promise(resolve => {
    request = indexedDB.open('myDB');

    request.onsuccess = () => {
      console.debug('request.onsuccess - getAllData');
      db = request.result;
      if (!db.objectStoreNames.contains(REPORT_STORE)) {
        return resolve(null);
      }
      const tx = db.transaction(REPORT_STORE, 'readonly');
      const store = tx.objectStore(REPORT_STORE);
      const res = store.get(externalId);
      res.onsuccess = () => {
        resolve(res.result);
      };
    };
  });
};

const deleteReportFromDB = (externalId: string): Promise<boolean> => {
  return new Promise(resolve => {
    request = indexedDB.open('myDB', version);

    request.onsuccess = () => {
      console.debug('request.onsuccess - deleteData', externalId);
      db = request.result;
      const tx = db.transaction(REPORT_STORE, 'readwrite');
      const store = tx.objectStore(REPORT_STORE);
      const res = store.delete(externalId);

      res.onsuccess = () => {
        resolve(true);
      };
      res.onerror = () => {
        resolve(false);
      };
    };
  });
};

const clearReportStore = () => {
  return new Promise(resolve => {
    request = indexedDB.open('myDB', version);

    request.onsuccess = () => {
      console.debug('request.onsuccess - clear reports');
      db = request.result;
      const tx = db.transaction(REPORT_STORE, 'readwrite');
      const store = tx.objectStore(REPORT_STORE);
      const res = store.clear();

      res.onsuccess = () => {
        resolve(true);
      };
      res.onerror = () => {
        resolve(false);
      };
    };
  });
};

export { deleteReportFromDB, getReportFromDB, setReportInDB, updateReportInDB, clearReportStore };
