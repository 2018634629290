import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    justifyContent: 'space-between',
    padding: 0,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  rowWithoutMargin: {
    justifyContent: 'space-between',
    padding: 0,
  },
  priorityRow: {
    justifyContent: 'space-between',
    padding: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  column: {
    flexDirection: 'column',
    padding: 0,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  select: {
    flexBasis: '50%',
  },
  selectItem: {
    whiteSpace: 'normal',
  },
  bigSelect: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  bigSelectedMenu: {
    whiteSpace: 'normal',
  },
  prioritySelect: {
    width: '80%',
  },
  switchContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  circularProgress: {
    color: theme.palette.primary.contrastText,
  },
}));
