import { useMemo } from 'react';

import useGetPatientStatisticHistoryUseCase from 'src/domains/medical-record/use-case/fact/statistic/useGetPatientStatisticHistoryUseCase';
import { IFactPreviewVM, bindFromEntity } from '../../FactPreviewVM';

interface UseStatisticHistoryParams {
  factTypeId: string;
}

const useStatisticHistory = (params: UseStatisticHistoryParams) => {
  const { data = [], isLoading, error } = useGetPatientStatisticHistoryUseCase(params);

  const list: IFactPreviewVM[] = useMemo(() => data.map(bindFromEntity), [data]);

  return {
    isLoading,
    list,
    error,
  };
};

export default useStatisticHistory;
