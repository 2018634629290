import { SchemaOf } from 'yup';
import * as yup from 'yup';

export type PatientSearch = {
  idRcu?: string;
};

export const schema: SchemaOf<PatientSearch> = yup.object({
  idRcu: yup.string().required('Information requise'),
});

export default schema;
