import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { medicalRecordApi } from '../../medical-record/repository';
import { PatientObservationType } from '../../medical-record/value-object/PatientObservationType';
import { PatientFactType } from '../../medical-record/value-object/PatientFactType';
import { PatientEventType } from '../../medical-record/value-object/PatientEventType';

export const useInvalidateMedicalRecord = () => {
  const dispatch = useDispatch();

  const invalidateMedicalRecord = useCallback(() => {
    dispatch(
      medicalRecordApi.util.invalidateTags([
        PatientObservationType.ANTECEDENTS,
        PatientEventType.ANTECEDENTS,
        PatientObservationType.DIAGNOSTICS,
        PatientObservationType.SUSPICIONS,
        PatientObservationType.RISKS,
        PatientFactType.STATISTICS,
        PatientFactType.ANALYSES,
        'Events',
        PatientObservationType.NOSOLOGICAL_FRAMEWORKS,
        PatientObservationType.BEHAVIOURS,
        PatientObservationType.MANIFESTATIONS,
        'AllPatientObservations',
      ]),
    );
  }, [dispatch]);

  return { invalidateMedicalRecord };
};
