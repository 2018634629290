import { ErrorBoundary } from 'react-error-boundary';

import { UserRole } from 'src/domains/user/entity/common';
import authenticationService from 'src/services/authentication';
import AntecedentSection from './Antecedent/List';
import StatisticSection from './Fact/Statistic/List';
import BehaviourSection from './Observation/Behaviour/List';
import DiagnosticSection from './Observation/Diagnostic/List';
import ManifestationSection from './Observation/Manifestation/List';
import SuspicionAndRiskSection from './Observation/SuspicionAndRisk/List';
import ReportSection from './Report/List';
import MedicalRecordFallbackComponent from './Section/FallbackComponent';

type MedicalRecordProps = {
  externalId: string;
};

export default function MedicalRecord({ externalId }: MedicalRecordProps) {
  const currentUser = authenticationService.getUser();

  return (
    <div data-testid={'medical-card-container'}>
      <ErrorBoundary
        fallbackRender={props => (
          <MedicalRecordFallbackComponent title={'Statistiques déclarées'} {...props} />
        )}
      >
        <StatisticSection />
      </ErrorBoundary>

      {currentUser?.role === UserRole.ORG_MANAGER && (
        <>
          <ErrorBoundary
            fallbackRender={props => (
              <MedicalRecordFallbackComponent title={'Antécédents déclarés'} {...props} />
            )}
          >
            <AntecedentSection />
          </ErrorBoundary>

          <ErrorBoundary
            fallbackRender={props => (
              <MedicalRecordFallbackComponent title={'Comportements déclarés'} {...props} />
            )}
          >
            <BehaviourSection />
          </ErrorBoundary>

          <ErrorBoundary
            fallbackRender={props => (
              <MedicalRecordFallbackComponent title={'Manifestations déclarées'} {...props} />
            )}
          >
            <ManifestationSection />
          </ErrorBoundary>

          <ErrorBoundary
            fallbackRender={props => (
              <MedicalRecordFallbackComponent title={'Maladies présentes déclarées'} {...props} />
            )}
          >
            <DiagnosticSection />
          </ErrorBoundary>

          <ErrorBoundary
            fallbackRender={props => (
              <MedicalRecordFallbackComponent
                title={'Risques et Suspicions de développer les maladies'}
                {...props}
              />
            )}
          >
            <SuspicionAndRiskSection />
          </ErrorBoundary>

          <ErrorBoundary
            fallbackRender={props => (
              <MedicalRecordFallbackComponent title={'Compte rendu'} {...props} />
            )}
          >
            <ReportSection externalId={externalId} />
          </ErrorBoundary>
        </>
      )}
    </div>
  );
}
