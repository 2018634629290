import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

import authenticationService from './authentication';

// admin
export const userAdminHttpClient = fetchBaseQuery({
  mode: 'cors',
  credentials: 'include',
  prepareHeaders: headers => {
    const xsrfToken = authenticationService.getXSRFToken();

    if (xsrfToken) headers.set('X-XSRF-TOKEN', xsrfToken);

    return headers;
  },
});

const userAdminBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await userAdminHttpClient(args, api, extraOptions);
  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    authenticationService.logoutWithLocationState();
  }
  return result;
};

export default userAdminBaseQuery;
