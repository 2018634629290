import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import { medicalRecordApi } from '../domains/medical-record/repository';
import { patientApi } from '../domains/patient/api';
import { userApi } from '../domains/user/repository';
import { scoringApi } from '../domains/scoring/api';
import authenticationApi from '../domains/authentication/api';
import history from '../app/history';
import tokenApi from '../domains/authentication/token-api';
import { indexedDBApi } from 'src/domains/patient/report/api';

const reducer = combineReducers({
  router: connectRouter(history),
  [indexedDBApi.reducerPath]: indexedDBApi.reducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [patientApi.reducerPath]: patientApi.reducer,
  [medicalRecordApi.reducerPath]: medicalRecordApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [tokenApi.reducerPath]: tokenApi.reducer,
  [scoringApi.reducerPath]: scoringApi.reducer,
});

export default reducer;
