import { isNilOrEmpty } from 'ramda-adjunct';
import { Stepper, Step as MuiStep, StepLabel } from '@material-ui/core';
import { useCounter } from 'react-use';
import React, {
  createElement,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Modal, { ModalProps } from 'src/components/common/Modal';
import StepConnectorIcon from 'src/components/common/Icons/step-connector';
import useStyles from './styles';
import { MedicalRecord } from '../../types';

interface PersistenceModalProps extends ModalProps {
  stepLabels?: string[];
  contentType: any;
  selectedRecord?: any;
}

export const PersistenceModal: FunctionComponent<PersistenceModalProps> = ({
  contentType,
  open,
  onClose: handleOnClose,
  selectedRecord,
  stepLabels = [],
  maxWidth = 'sm',
  children,
  ...modalProps
}) => {
  const maxStepCount: number = useMemo(() => stepLabels.length, [stepLabels]);
  const [currentStep, { inc: next, dec: previous, reset }] = useCounter(1, maxStepCount);
  const [medicalRecord, setMedicalRecord] = useState<MedicalRecord | undefined>(undefined);
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      reset();
      setMedicalRecord(undefined);
    }
  }, [open, reset]);

  const handleOnPrevious = useCallback(() => {
    setMedicalRecord(undefined);
    previous();
  }, [previous]);

  const handleOnNext = useCallback(() => {
    next();
  }, [next]);

  const handleOnSubmit = useCallback(() => {
    setMedicalRecord(undefined);
    handleOnClose();
  }, [handleOnClose]);

  const childrenProps = useMemo(
    () => ({
      currentStep,
      onClose: handleOnClose,
      onNext: handleOnNext,
      onPrevious: handleOnPrevious,
      onSubmit: handleOnSubmit,
      setRecord: setMedicalRecord,
      record: medicalRecord || selectedRecord,
    }),
    [
      currentStep,
      handleOnClose,
      handleOnNext,
      handleOnPrevious,
      handleOnSubmit,
      medicalRecord,
      selectedRecord,
    ],
  );

  return (
    <Modal maxWidth={maxWidth} onClose={handleOnClose} open={open} {...modalProps}>
      {!isNilOrEmpty(stepLabels) && (
        <div className={classes.stepperContainer}>
          <Stepper
            activeStep={currentStep - 1}
            connector={<StepConnectorIcon color={'disabled'} />}
          >
            {stepLabels.map(label => (
              <MuiStep key={label}>
                <StepLabel color={'primary'}>{label}</StepLabel>
              </MuiStep>
            ))}
          </Stepper>
        </div>
      )}
      {createElement(contentType, childrenProps)}
    </Modal>
  );
};

export default PersistenceModal;
