import { useCallback, useMemo } from 'react';

import {
  DeleteRecordRequestDTO,
  useDeletePatientEventMutation,
  useDeletePatientObservationMutation,
} from '../../repository';
import { isEventType, isObservationType, MedicalRecordType } from '../../entity';

const useDeletePatientAntecedentUseCase = (type: MedicalRecordType) => {
  const [
    deletePatientObservation,
    {
      isLoading: isPatientObservationDeletionLoading,
      isSuccess: isPatientObservationDeletionSuccess,
      error: patientObservationDeletionError,
    },
  ] = useDeletePatientObservationMutation();

  const [
    deletePatientEvent,
    {
      isLoading: isPatientEventDeletionLoading,
      isSuccess: isPatientEventDeletionSuccess,
      error: patientEventDeletionError,
    },
  ] = useDeletePatientEventMutation();

  const deleteAntecedent = useCallback(
    (dto: DeleteRecordRequestDTO) =>
      isObservationType(type)
        ? deletePatientObservation(dto)
        : isEventType(type)
        ? deletePatientEvent(dto)
        : null,
    [deletePatientEvent, deletePatientObservation, type],
  );

  const isLoading = useMemo(
    () =>
      isObservationType(type)
        ? isPatientObservationDeletionLoading
        : isEventType(type)
        ? isPatientEventDeletionLoading
        : false,
    [isPatientEventDeletionLoading, isPatientObservationDeletionLoading, type],
  );

  const isSuccess = useMemo(
    () =>
      isObservationType(type)
        ? isPatientObservationDeletionSuccess
        : isEventType(type)
        ? isPatientEventDeletionSuccess
        : false,
    [isPatientEventDeletionSuccess, isPatientObservationDeletionSuccess, type],
  );

  const error = useMemo(
    () =>
      isObservationType(type)
        ? patientObservationDeletionError
        : isEventType(type)
        ? patientEventDeletionError
        : null,
    [patientEventDeletionError, patientObservationDeletionError, type],
  );

  return {
    deleteAntecedent,
    isLoading,
    isSuccess,
    error,
  };
};

export default useDeletePatientAntecedentUseCase;
