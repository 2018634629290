import { CellProps } from 'react-table';
import { useMemo } from 'react';

import { IAntecedentPreviewVM } from '../AntecedentPreviewVM';
import DateField from 'src/components/common/Table/DateField';
import StatusField from '../../helpers/table/StatusField';
import TextField from 'src/components/common/Table/TextField';
import AuthorField from '../../helpers/table/AuthorField';
import { isNilOrEmpty } from 'ramda-adjunct';
import { toTitleCase } from '../../../../../../helpers/format/string';

export const useHistoryTableColumn = (): any[] => {
  return useMemo(
    () => [
      {
        id: 'creationDate',
        accessor: 'creationDate',
        sortType: 'datetime',
        sortable: true,
        width: '15%',
        Cell: (props: CellProps<IAntecedentPreviewVM>) => {
          const date = props.cell.value;

          return <DateField date={date} />;
        },
      },
      {
        accessor: 'label',
        width: '30%',
        Cell: (props: CellProps<IAntecedentPreviewVM>) => {
          const label = props.cell.value;

          return <TextField label={label} variant={'h5'} />;
        },
      },
      {
        accessor: 'familyRelationship.label',
        width: '25%',
        Cell: (props: CellProps<IAntecedentPreviewVM>) => {
          const label = !isNilOrEmpty(props.cell.value) ? toTitleCase(props.cell.value) : undefined;

          return <TextField label={label} variant={'h5'} />;
        },
      },
      {
        accessor: 'author',
        width: '15%',
        sortable: true,
        Cell: (props: CellProps<IAntecedentPreviewVM>) => {
          const author = props.cell.value;

          return <AuthorField value={author} />;
        },
      },
      {
        accessor: 'status',
        width: '15%',
        sortable: true,
        Cell: (props: CellProps<IAntecedentPreviewVM>) => {
          const status = props.cell.value;

          return <StatusField status={status} />;
        },
      },
    ],
    [],
  );
};
