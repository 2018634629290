import { Box, Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import { ChevronRight } from 'react-feather';
import { isNilOrEmpty } from 'ramda-adjunct';
import React, { FunctionComponent } from 'react';
import useReactRouterBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { config } from 'src/routes';
import BreadcrumbItem from './BreadcrumbItem';
import useStyles from './styles';

const Breadcrumbs: FunctionComponent = () => {
  const classes = useStyles();
  const breadcrumbs = useReactRouterBreadcrumbs(config as BreadcrumbsRoute[], {
    disableDefaults: true,
  });

  if (isNilOrEmpty(breadcrumbs)) return null;

  return (
    <Box
      display="inline-flex"
      justifyContent={'baseline'}
      data-testid={'breadcrumbs'}
      displayPrint={'none'}
    >
      {/*@ts-ignore*/}
      <Box component={ChevronRight} size={20} marginLeft={0} marginRight={1} />
      <MuiBreadcrumbs
        separator={<ChevronRight size={20} />}
        classes={{
          root: classes.breadcrumbs,
        }}
        aria-label="breadcrumb"
      >
        {breadcrumbs.map((breadcrumbItem, index) => (
          <BreadcrumbItem {...breadcrumbItem} key={`breadcrumb-item-${index}`} />
        ))}
      </MuiBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;
