import React, { FunctionComponent, useMemo } from 'react';

import Modal, { ModalProps } from 'src/components/common/Modal';
import useStyles from './styles';
import { CircularProgress, Typography } from '@material-ui/core';
import ModalFooterButtons, { ButtonParams } from 'src/components/common/Modal/ModalFooterButtons';

interface DeleteModalProps extends ModalProps {
  onDelete: () => void;
  isLoading: boolean;
}

const DeleteModal: FunctionComponent<DeleteModalProps> = ({
  open,
  onClose,
  onDelete,
  isLoading,
  ...modalProps
}) => {
  const classes = useStyles();

  const buttons: ButtonParams[] = useMemo(
    () => [
      {
        props: { variant: 'outlined', color: 'primary', onClick: onClose },
        label: 'Non',
      },
      {
        props: {
          variant: 'contained',
          color: 'primary',
          disabled: isLoading,
          onClick: onDelete,
        },
        label: isLoading ? <CircularProgress size={22} /> : 'Oui',
      },
    ],
    [isLoading, onClose, onDelete],
  );

  return (
    <Modal maxWidth={'sm'} onClose={onClose} open={open} {...modalProps}>
      <div className={classes.container}>
        <div className={classes.questionContainer}>
          <Typography color={'primary'} variant={'body1'}>
            Êtes-vous sûr de vouloir supprimer cet enregistrement ?
          </Typography>
        </div>
      </div>
      <ModalFooterButtons buttons={buttons} />
    </Modal>
  );
};

export default DeleteModal;
