import { useCallback, useEffect, useReducer, useState } from 'react';
import { usePDF } from '@react-pdf/renderer';
import { useTheme } from '@material-ui/core';

import { bindFromIReportPreviewVM, PDFReportDTO } from './dto';
import { IReportPreviewVM } from '../../ReportPreviewVM';
import PDFReport from './';
import { equals } from 'ramda';

export const usePDFReport = () => {
  const theme = useTheme();

  const [pdfReport, setPDFReport] = useState<PDFReportDTO | undefined>(undefined);
  const [isDownloadPDFTriggered, setIsDownloadPDFTriggered] = useState<boolean>(false);

  function reducer(documentObject: any, action: PDFReportDTO | undefined) {
    if (equals(pdfReport, action)) return documentObject;

    setPDFReport(action);
    return { document: <PDFReport pdfReportData={action} theme={theme} /> };
  }

  const [pdfReportDocument, updatePDFDocument] = useReducer(reducer, {
    document: <></>,
  });

  const [instance, updateInstance] = usePDF(pdfReportDocument);

  const buildPDFDocument = useCallback(
    (pdfReportDTO: PDFReportDTO) => {
      updatePDFDocument(pdfReportDTO);
      updateInstance();
    },
    [updateInstance],
  );

  const openPDF = useCallback((url: string) => {
    window.open(url, '_blank')?.focus();
    updatePDFDocument(undefined);
    setIsDownloadPDFTriggered(false);
  }, []);

  useEffect(() => {
    if (isDownloadPDFTriggered && pdfReport) updateInstance();
  }, [isDownloadPDFTriggered, pdfReport, theme, updateInstance]);

  useEffect(() => {
    if (!instance.loading && instance.url && isDownloadPDFTriggered) {
      openPDF(instance.url);
    }
  }, [instance.loading, instance.url, isDownloadPDFTriggered, openPDF, updateInstance]);

  const downloadPDF = useCallback(
    (vm: IReportPreviewVM) => {
      const pdfReportDTO = bindFromIReportPreviewVM(vm);
      setIsDownloadPDFTriggered(true);
      buildPDFDocument(pdfReportDTO);
      updateInstance();
    },
    [buildPDFDocument, updateInstance],
  );

  return { downloadPDF };
};
export default usePDFReport;
