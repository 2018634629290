import React, { FunctionComponent, useMemo } from 'react';

import ReportForm from '../../ReportForm';
import useUpdateReport from './hook';
import ReportFormDTO, { bindFromVM } from '../../ReportForm/dto';
import { IReportPreviewVM } from '../../ReportPreviewVM';

interface ReportModificationFormProps {
  externalId: string;
  visualModel: IReportPreviewVM;
  onClose: () => void;
}

const ReportModificationForm: FunctionComponent<ReportModificationFormProps> = ({
  onClose,
  externalId,
  visualModel,
}) => {
  const { update, isLoading, isSuccess, error } = useUpdateReport(externalId);

  const formDTO: ReportFormDTO = useMemo(() => bindFromVM(visualModel), [visualModel]);

  return (
    <ReportForm
      error={error}
      isUpdateMode
      isSuccess={isSuccess}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={update}
      values={formDTO}
    />
  );
};

export default ReportModificationForm;
