import { isNilOrEmpty } from 'ramda-adjunct';
import { isNil } from 'ramda';
import Cookies from 'js-cookie';

export enum StorageType {
  LOCAL = 'LOCAL',
  SESSION = 'SESSION',
  COOKIE = 'COOKIE',
}

export interface CookieParams {
  expires?: any;
  path?: string;
}

class CookieStorage {
  public static getAll(): { [id: string]: string } {
    return Cookies.get();
  }

  public static getItem(key: string): string | undefined {
    return Cookies.get(key);
  }

  public static setItem(key: string, value: string, options?: CookieParams): void {
    Cookies.set(key, value, options);
  }

  public static removeItem(key: string, path?: string): void {
    if (path) Cookies.remove(key, { path });

    Cookies.remove(key, {});
  }

  public static clear(): void {
    Object.keys(Cookies.get()).forEach(cookieName => {
      const currentCookieValue = Cookies.getJSON(cookieName);
      Cookies.remove(cookieName, currentCookieValue);
    });
  }
}

class StorageService {
  getStorage(
    type: StorageType,
  ): typeof localStorage | typeof sessionStorage | typeof CookieStorage {
    if (type === StorageType.SESSION) return sessionStorage;
    if (type === StorageType.COOKIE) return CookieStorage;
    return localStorage;
  }

  setItem(key: string, value: any, type = StorageType.LOCAL): void {
    try {
      const storage = this.getStorage(type);

      if (isNilOrEmpty(key)) throw new Error('key parameter is required');
      if (isNil(value)) throw new Error('value parameter is required');

      const serializedToken = JSON.stringify(value);
      storage.setItem(key, serializedToken);
    } catch (error) {
      throw new Error('Cannot save data in storage');
    }
  }

  getItem(key: string, type = StorageType.LOCAL) {
    try {
      const storage = this.getStorage(type);

      if (isNilOrEmpty(key)) throw new Error('key parameter is required');

      const serializedValue = storage.getItem(key);

      if (isNil(serializedValue)) return null;

      return JSON.parse(serializedValue);
    } catch (error) {
      throw new Error('Cannot select data from storage');
    }
  }

  removeItem(key: string, type = StorageType.LOCAL): void {
    try {
      const storage = this.getStorage(type);

      if (isNilOrEmpty(key)) throw new Error('key parameter is required');

      storage.removeItem(key);
    } catch (error) {
      throw new Error('Cannot remove data from storage');
    }
  }

  clear(type = StorageType.LOCAL): void {
    try {
      const storage = this.getStorage(type);
      storage.clear();
    } catch (error) {
      throw new Error('Cannot clear data from storage');
    }
  }
}

const storageService = new StorageService();

export default storageService;
