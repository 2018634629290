import {
  useGetPatientManifestationHistoryQuery,
  useGetPatientObservationsQuery,
} from '../../../repository';
import { GetPatientObservationsRequestDTO } from '../../../repository/dto/GetPatientObservationsRequestDTO';
import { useMemo } from 'react';
import { PatientObservationType } from '../../../value-object/PatientObservationType';

interface UseGetPatientObservationHistoryUseCaseParams {
  observationDescriptionId: string;
  familyRelationshipId?: string;
}

const useGetPatientManifestationHistoryUseCase = (
  params: UseGetPatientObservationHistoryUseCaseParams,
) => {
  const { isLoading, data, error } = useGetPatientManifestationHistoryQuery(params);

  return { data, isLoading, error };
};

export const useGetPatientManifestationHistory = (
  params: Omit<GetPatientObservationsRequestDTO, 'type'>,
) => {
  const requestDTO: GetPatientObservationsRequestDTO = useMemo<GetPatientObservationsRequestDTO>(
    () => ({
      ...params,
      type: PatientObservationType.MANIFESTATIONS,
    }),
    [params],
  );

  const { isLoading, data, error } = useGetPatientObservationsQuery(requestDTO);

  return { data, isLoading, error };
};

export default useGetPatientManifestationHistoryUseCase;
