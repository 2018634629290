import { useMemo } from 'react';
import { CellProps } from 'react-table';

import DateField from 'src/components/common/Table/DateField';
import TextField from 'src/components/common/Table/TextField';
import DegreeField from '../../../helpers/table/DegreeField';
import { IRiskAndSuspicionPreviewVM } from '../RiskAndSuspicionPreviewVM';

export const useTableColumn = (): any[] => {
  return useMemo(
    () => [
      {
        Header: "Date d'évaluation",
        id: 'creationDate',
        accessor: 'creationDate',
        sortType: 'datetime',
        sortable: true,
        width: '15%',
        Cell: (props: CellProps<IRiskAndSuspicionPreviewVM>) => {
          const date = props.cell.value;

          return <DateField date={date} />;
        },
      },
      {
        Header: 'Maladie',
        accessor: 'label',
        width: '50%',
        sortable: true,
        Cell: (props: CellProps<IRiskAndSuspicionPreviewVM>) => {
          const label = props.cell.value;

          return <TextField label={label} variant={'h5'} />;
        },
      },
      {
        Header: 'Niveau initial',
        accessor: 'degree',
        width: '15%',
        sortable: true,
        Cell: (props: CellProps<IRiskAndSuspicionPreviewVM>) => {
          const degree = props.cell.value;

          return <DegreeField value={degree} />;
        },
      },
    ],
    [],
  );
};
