import { useMemo } from 'react';

import { bindFromEntity, IFactPreviewVM } from '../../FactPreviewVM';
import { useGetPatientStatisticsQuery } from 'src/domains/medical-record/repository';

const usePatientStatisticList = () => {
  const { data = [], isLoading, error } = useGetPatientStatisticsQuery();

  const list: IFactPreviewVM[] = useMemo(() => data.map(bindFromEntity), [data]);

  return {
    isLoading,
    list,
    error,
  };
};

export default usePatientStatisticList;
