import { Alert } from '@material-ui/lab';
import { Button, Paper, Typography } from '@material-ui/core';
import React, { FunctionComponent, memo } from 'react';

import useStyles from './styles';

interface MedicalRecordFallbackComponentProps {
  title?: string;
  subtitle?: string;
  resetErrorBoundary?: () => void;
}

const MedicalRecordFallbackComponent: FunctionComponent<MedicalRecordFallbackComponentProps> = memo(
  ({ title, subtitle, resetErrorBoundary }) => {
    const classes = useStyles();

    return (
      <Paper className={classes.paper}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            {title && (
              <div className={classes.titleHeaderContainer}>
                <Typography color={'primary'} variant={'h3'}>
                  {title}
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.headerContainer}>
            {subtitle && (
              <div className={classes.titleHeaderContainer}>
                <Typography color={'primary'} variant={'body1'}>
                  {subtitle}
                </Typography>
              </div>
            )}
          </div>

          <div className={classes.errorContainer}>
            <Alert
              icon={false}
              variant={'outlined'}
              severity={'error'}
              style={{ width: 'fit-content' }}
            >
              <div className={classes.errorContainer}>
                <div className={classes.errorTitle}>
                  <Typography variant={'h5'}>Une erreur s'est produite</Typography>
                </div>
                <Typography variant={'body1'}>
                  Un problème est survenu lors du chargement. Veuillez réessayer ultérieurement.
                </Typography>

                {resetErrorBoundary && (
                  <div className={classes.errorButton}>
                    <Button variant={'contained'} color={'primary'} onClick={resetErrorBoundary}>
                      Réessayer
                    </Button>
                  </div>
                )}
              </div>
            </Alert>
          </div>
        </div>
      </Paper>
    );
  },
);

export default MedicalRecordFallbackComponent;
