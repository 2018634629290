import { IObservationPreviewVM } from '../ObservationPreviewVM';
import { DegreeType, PatientObservation } from 'src/domains/medical-record/entity/observation';
import { StatusType } from 'src/domains/medical-record/value-object/StatusType';
import { toTitleCase } from 'src/helpers/format/string';

export interface IRiskAndSuspicionPreviewVM extends IObservationPreviewVM {
  degree: DegreeType;
  status: StatusType;
  coreAppId: number;
  observationTypeLabel: string;
}

export const bindFromEntity = (observation: PatientObservation): IRiskAndSuspicionPreviewVM => {
  let degree = DegreeType.LOW;
  switch (observation.observationDescription.observationType.evolutionOrder) {
    case 0:
      degree = DegreeType.LOW;
      break;
    case 1:
      degree = DegreeType.MEDIUM;
      break;
    case 2:
      degree = DegreeType.HIGH;
      break;
  }

  return {
    id: observation.observationDescription.evolvesFrom!.id,
    coreAppId: observation.observationDescription.evolvesFrom!.coreAppId,
    label: toTitleCase(observation.observationDescription.evolvesFrom!.label),
    status: observation.status,
    degree,
    descriptionId: observation.observationDescription.id,
    observationTypeLabel: toTitleCase(observation.observationDescription.observationType.label),
    creationDate: new Date(observation.date),
    startDate: new Date(observation.startDate),
    endDate: new Date(observation.endDate),
  };
};
