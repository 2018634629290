import { useMemo } from 'react';
import Section from '../../../Section';
import { useTableColumn } from './columns';
import { useRiskAndSuspicionList } from './hook';

const SuspicionAndRiskSection = () => {
  const { list, isLoading } = useRiskAndSuspicionList();

  const columns = useTableColumn();

  const defaultSort = useMemo(
    () => [
      {
        id: 'label',
        desc: true,
      },
    ],
    [],
  );

  return (
    <>
      <Section
        columns={columns}
        data={list}
        title={'Risques et Suspicions de développer les maladies'}
        subtitle={
          'Un niveau de risque non réevalué par le médecin LIVI est considéré comme vu et non remis en cause par le médecin LIVI.'
        }
        isLoading={isLoading}
        defaultSort={defaultSort}
      />
    </>
  );
};

export default SuspicionAndRiskSection;
