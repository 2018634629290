import React, { FunctionComponent } from 'react';

import { ModalProps } from 'src/components/common/Modal';

import Step from 'src/components/common/Step';
import DiagnosticModificationForm from './DiagnosticModificationForm';

interface DiagnosticModificationModalContentProps extends ModalProps {
  currentStep: number;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
  onSubmit: () => void;
  patientId: string;
  setRecord: (record: any) => void;
  record: any;
}

export const DiagnosticModificationModalContent: FunctionComponent<DiagnosticModificationModalContentProps> =
  ({ currentStep, onClose, onPrevious, patientId, record }) => {
    return (
      <>
        <Step step={0} currentStep={currentStep}>
          <DiagnosticModificationForm
            patientId={patientId}
            record={record}
            onClose={onClose}
            onPrevious={onPrevious}
            startDateProps={{ disabled: true }}
          />
        </Step>
      </>
    );
  };

export default DiagnosticModificationModalContent;
