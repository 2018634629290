import { useCallback } from 'react';

import { AnswerType } from 'src/domains/medical-record/value-object/AnswerType';
import { AntecedentCreation } from '../../AntecedentForm/schema';
import { IAntecedentPreviewVM } from '../../AntecedentPreviewVM';
import { EventCreationDTO, ObservationCreationDTO } from 'src/domains/medical-record/dto';
import { isEventType, isObservationType } from 'src/domains/medical-record/entity';
import useUpdateObservationUseCase from 'src/domains/medical-record/use-case/observation/useUpdateObservationUseCase';
import useUpdatePatientEventUseCase from 'src/domains/medical-record/use-case/event/useUpdatePatientEventUseCase';
import { SubmitAction } from '../../../Modals/PersistenceModal/PersistenceForm/constants';

const useUpdateAntecedent = (patientId: string, visualModel: IAntecedentPreviewVM) => {
  const {
    updateObservation,
    isCreationLoading: isPatientObservationCreationLoading,
    isReplaceLoading: isReplacePatientObservationLoading,
    isForceCreationLoading: isForcePatientObservationCreationLoading,
    isSuccess: isPatientObservationUpdateSuccess,
    error: patientObservationUpdateError,
  } = useUpdateObservationUseCase();

  const {
    updateEvent,
    isCreationLoading: isPatientEventCreationLoading,
    isReplaceLoading: isReplacePatientEventLoading,
    isForceCreationLoading: isForcePatientEventCreationLoading,
    isSuccess: isPatientEventUpdateSuccess,
    error: patientEventUpdateError,
  } = useUpdatePatientEventUseCase();

  const update = useCallback(
    (modification: AntecedentCreation, submitAction?: SubmitAction) => {
      if (isObservationType(visualModel.type)) {
        const creationDTO: ObservationCreationDTO = {
          answerType: AnswerType.DOCTOR,
          endDate: modification.startDate as Date,
          observationDescriptionId: visualModel.relatedTemplateElementId,
          familyRelationshipId: modification.familyRelationshipId,
          startDate: modification.startDate as Date,
          status: modification.status,
        };
        updateObservation(patientId, visualModel.id, creationDTO, submitAction);
      }

      if (isEventType(visualModel.type)) {
        const creationDTO: EventCreationDTO = {
          answerType: AnswerType.DOCTOR,
          id: visualModel.id,
          endDate: modification.startDate as Date,
          startDate: modification.startDate as Date,
          eventTypeId: visualModel.relatedTemplateElementId,
          familyRelationshipId: modification.familyRelationshipId,
          status: modification.status,
        };
        updateEvent(patientId, visualModel.id, creationDTO, submitAction);
      }
    },
    [
      patientId,
      updateEvent,
      updateObservation,
      visualModel.id,
      visualModel.relatedTemplateElementId,
      visualModel.type,
    ],
  );

  return {
    update,
    isCreationLoading: isPatientObservationCreationLoading || isPatientEventCreationLoading,
    isReplaceLoading: isReplacePatientObservationLoading || isReplacePatientEventLoading,
    isForceCreationLoading:
      isForcePatientObservationCreationLoading || isForcePatientEventCreationLoading,
    isSuccess: isPatientObservationUpdateSuccess || isPatientEventUpdateSuccess,
    error: patientObservationUpdateError || patientEventUpdateError,
  };
};

export default useUpdateAntecedent;
