import { FunctionComponent } from 'react';
import { Tabs, TabsProps } from '@material-ui/core';

const PatientDashboardTabs: FunctionComponent<TabsProps> = ({ children, ...rest }) => (
  <Tabs
    TabIndicatorProps={{
      style: {
        display: 'none',
      },
    }}
    variant="standard"
    scrollButtons="auto"
    {...rest}
  >
    {children}
  </Tabs>
);

export default PatientDashboardTabs;
