import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { transformDateFromBody } from 'src/helpers/format/date';
import engineBaseQuery from 'src/services/engine-http-client';
import {
  EventCreationDTO,
  FactCreationDTO,
  LatestEventDTO,
  LatestFactDTO,
  LatestObservationDTO,
  ObservationCreationDTO,
} from '../dto';
import { Event, PatientEventAndType } from '../entity/event';
import { Fact, PatientFactAndType } from '../entity/fact';
import { Observation, PatientObservation } from '../entity/observation';
import { CoreAppItem } from '../value-object/Item';
import { PatientEventType } from '../value-object/PatientEventType';
import { PatientFactType } from '../value-object/PatientFactType';
import { PatientObservationType } from '../value-object/PatientObservationType';
import { STATUS_TYPE } from '../value-object/StatusType';
import { GetAllPatientObservationsRequestDTO } from './dto/GetAllPatientObservationsRequestDTO';
import { GetLatestPatientEventsRequestDTO } from './dto/GetLatestPatientEventsRequestDTO';
import { GetLatestPatientFactsRequestDTO } from './dto/GetLatestPatientFactsRequestDTO';
import { GetLatestPatientObservationsRequestDTO } from './dto/GetLatestPatientObservationsRequestDTO';
import { GetObservationHistoryRequestDTO } from './dto/GetObservationHistoryRequestDTO';
import { GetPatientObservationsRequestDTO } from './dto/GetPatientObservationsRequestDTO';
import { selectPatientEventAndTypeAntecedentsFromResponse } from './selector/antecedent';
import { selectEventsFromResponse } from './selector/event';
import { selectAnalysesFromResponse } from './selector/fact/analysis';
import { selectFactFromResponse } from './selector/fact/fact';
import { selectStatisticsFromResponse } from './selector/fact/statistic';
import { selectPatientObservationsByStatus } from './selector/observation/observation';

export interface GetFactHistoryRequestDTO {
  factTypeId: string;
}

export interface GetEventHistoryRequestDTO {
  eventTypeId: string;
  allowFamilyRelationship: boolean;
  appendProgramLabel?: boolean;
}

export interface DeleteRecordRequestDTO {
  recordId: string;
}

export interface CreatePatientObservationRequestDTO {
  body: ObservationCreationDTO;
}

export interface ReplacePatientObservationRequestDTO extends ObservationCreationDTO {
  patientObservationId: string;
}

export interface CreatePatientEventRequestDTO {
  body: EventCreationDTO;
}

export interface CreatePatientFactRequestDTO {
  body: FactCreationDTO;
}

export interface CreateMultiplePatientFactRequestDTO {
  body: FactCreationDTO[];
}

export interface ReplacePatientFactRequestDTO {
  id: string;
  body: FactCreationDTO;
}

export interface CreationError {
  data: {
    code: string;
    allCodeList: string[];
    details: { id: string; label: string }[];
    exception: string;
  };
  status: number;
}

export const MEDICAL_RECORD_TAGS = [
  PatientObservationType.ANTECEDENTS,
  PatientEventType.ANTECEDENTS,
  PatientObservationType.BEHAVIOURS,
  PatientObservationType.DIAGNOSTICS,
  PatientObservationType.MANIFESTATIONS,
  PatientObservationType.NOSOLOGICAL_FRAMEWORKS,
  PatientObservationType.RISKS,
  PatientObservationType.SUSPICIONS,
  PatientFactType.STATISTICS,
  PatientFactType.ANALYSES,
  'Events',
  'AllPatientObservations',
];

export const medicalRecordApi = createApi({
  reducerPath: 'medicalRecordApi',
  baseQuery: engineBaseQuery,
  tagTypes: MEDICAL_RECORD_TAGS,
  endpoints: builder => ({
    getPatientDiagnosticHistory: builder.query<
      PatientObservation[],
      GetObservationHistoryRequestDTO
    >({
      providesTags: [PatientObservationType.DIAGNOSTICS],
      query: ({ observationDescriptionId, familyRelationshipId }) => ({
        url: '/api/v1/patientObservations',
        params: {
          odId: observationDescriptionId,
          frId: familyRelationshipId,
        },
      }),
    }),
    getPatientDiagnosticAntecedentHistory: builder.query<
      PatientObservation[],
      GetObservationHistoryRequestDTO
    >({
      providesTags: [PatientObservationType.ANTECEDENTS],
      query: ({ observationDescriptionId, familyRelationshipId }) => ({
        url: '/api/v1/patientObservations',
        params: {
          odId: observationDescriptionId,
          frId: familyRelationshipId,
        },
      }),
    }),
    getPatientSuspicionHistory: builder.query<
      PatientObservation[],
      GetObservationHistoryRequestDTO
    >({
      providesTags: [PatientObservationType.SUSPICIONS],
      query: ({ observationDescriptionId, familyRelationshipId }) => ({
        url: '/api/v1/patientObservations',
        params: {
          odId: observationDescriptionId,
          frId: familyRelationshipId,
        },
      }),
      transformResponse: (response: PatientObservation[]) =>
        selectPatientObservationsByStatus(response, STATUS_TYPE.PRESENT),
    }),
    getPatientRiskHistory: builder.query<PatientObservation[], GetObservationHistoryRequestDTO>({
      providesTags: [PatientObservationType.RISKS],
      query: ({ observationDescriptionId, familyRelationshipId }) => ({
        url: '/api/v1/patientObservations',
        params: {
          odId: observationDescriptionId,
          frId: familyRelationshipId,
        },
      }),
      transformResponse: (response: PatientObservation[]) =>
        selectPatientObservationsByStatus(response, STATUS_TYPE.PRESENT),
    }),
    getPatientBehaviourHistory: builder.query<
      PatientObservation[],
      GetObservationHistoryRequestDTO
    >({
      providesTags: [PatientObservationType.BEHAVIOURS],
      query: ({ observationDescriptionId, familyRelationshipId }) => ({
        url: '/api/v1/patientObservations',
        params: {
          odId: observationDescriptionId,
          frId: familyRelationshipId,
        },
      }),
    }),
    getLatestPatientObservations: builder.query<
      LatestObservationDTO[],
      GetLatestPatientObservationsRequestDTO
    >({
      providesTags: (result, error, queryArgs) => [
        {
          type: queryArgs.type,
          id: !queryArgs.observationType ? 'LIST' : queryArgs.observationType,
        },
      ],
      query: ({
        qualifications,
        allowFamilyRelationship,
        statuses,
        subjects,
        observationType,
      }) => ({
        url: '/api/v1/patientObservations/latest',
        params: {
          qualifications,
          allowFamilyRelationship,
          statuses,
          subjects,
          observationType,
        },
      }),
    }),
    getPatientManifestationHistory: builder.query<
      PatientObservation[],
      GetObservationHistoryRequestDTO
    >({
      providesTags: [PatientObservationType.MANIFESTATIONS],
      query: ({ observationDescriptionId, familyRelationshipId }) => ({
        url: '/api/v1/patientObservations',
        params: {
          odId: observationDescriptionId,
          frId: familyRelationshipId,
        },
      }),
    }),
    getPatientObservations: builder.query<PatientObservation[], GetPatientObservationsRequestDTO>({
      providesTags: (result, error, queryArgs) => [
        {
          type: queryArgs.type,
          id: `patient-${queryArgs.observationDescriptionId}`,
        },
      ],
      query: ({ observationDescriptionId, familyRelationshipId }) => ({
        url: '/api/v1/patientObservations',
        params: {
          odId: observationDescriptionId,
          frId: familyRelationshipId,
        },
      }),
      transformResponse: (response: PatientObservation[]) =>
        selectPatientObservationsByStatus(response, STATUS_TYPE.PRESENT),
    }),
    getAllPatientObservations: builder.query<
      PatientObservation[],
      GetAllPatientObservationsRequestDTO
    >({
      providesTags: ['AllPatientObservations'],
      query: ({ statuses }) => ({
        url: '/api/v1/patientObservations/all',
        method: 'GET',
        params: {
          statuses,
        },
      }),
    }),
    createPatientObservation: builder.mutation<void, CreatePatientObservationRequestDTO>({
      invalidatesTags: [
        PatientObservationType.DIAGNOSTICS,
        PatientObservationType.SUSPICIONS,
        PatientObservationType.RISKS,
        PatientObservationType.MANIFESTATIONS,
        PatientObservationType.ANTECEDENTS,
        PatientObservationType.BEHAVIOURS,
        'AllPatientObservations',
      ],
      query: ({ body }) => ({
        url: `/api/v1/patientObservations`,
        method: 'POST',
        body: [transformDateFromBody(body)],
      }),
    }),
    forcePatientObservationCreation: builder.mutation<void, CreatePatientObservationRequestDTO>({
      invalidatesTags: [
        PatientObservationType.DIAGNOSTICS,
        PatientObservationType.SUSPICIONS,
        PatientObservationType.RISKS,
        PatientObservationType.MANIFESTATIONS,
        PatientObservationType.ANTECEDENTS,
        PatientObservationType.BEHAVIOURS,
        'AllPatientObservations',
      ],
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const observationExpirationResponse = await fetchWithBQ({
          url: `/api/v1/patientObservations/expireValid`,
          method: 'PUT',
          params: {
            odId: _arg.body.observationDescriptionId,
            familyRelationshipId: _arg.body.familyRelationshipId,
            skipLatest: false,
          },
        });

        if (observationExpirationResponse.error) throw observationExpirationResponse.error;

        const currentDate: Date = new Date();

        const creationObservationResponse = fetchWithBQ({
          url: `/api/v1/patientObservations`,
          method: 'POST',
          body: [
            transformDateFromBody({ ..._arg.body, startDate: currentDate, endDate: currentDate }),
          ],
        });

        return creationObservationResponse as QueryReturnValue<void, FetchBaseQueryError>;
      },
    }),
    replacePatientObservation: builder.mutation<void, ReplacePatientObservationRequestDTO>({
      invalidatesTags: [
        PatientObservationType.DIAGNOSTICS,
        PatientObservationType.SUSPICIONS,
        PatientObservationType.RISKS,
        PatientObservationType.MANIFESTATIONS,
        PatientObservationType.ANTECEDENTS,
        PatientObservationType.BEHAVIOURS,
        'AllPatientObservations',
      ],
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const deletePatientObservationResponse = await fetchWithBQ({
          url: `/api/v1/patientObservations/${_arg.patientObservationId}`,
          method: 'DELETE',
        });

        if (deletePatientObservationResponse.error) throw deletePatientObservationResponse.error;

        const creationObservationResponse = fetchWithBQ({
          url: `/api/v1/patientObservations`,
          method: 'POST',
          body: [
            transformDateFromBody({
              answerType: _arg.answerType,
              degree: _arg.degree,
              endDate: _arg.endDate,
              familyRelationshipId: _arg.familyRelationshipId,
              observationDescriptionId: _arg.observationDescriptionId,
              startDate: _arg.startDate,
              status: _arg.status,
            }),
          ],
        });

        return creationObservationResponse as QueryReturnValue<void, FetchBaseQueryError>;
      },
    }),
    deletePatientObservation: builder.mutation<void, DeleteRecordRequestDTO>({
      invalidatesTags: [
        PatientObservationType.DIAGNOSTICS,
        PatientObservationType.SUSPICIONS,
        PatientObservationType.RISKS,
        PatientObservationType.MANIFESTATIONS,
        PatientObservationType.BEHAVIOURS,
      ],
      query: ({ recordId }) => ({
        url: `/api/v1/patientObservations/${recordId}`,
        method: 'DELETE',
      }),
    }),
    // FIXME: remove this endpoint, it doesn't exist anymore, find values by PO
    getObservations: builder.query<Observation[], void>({
      query: () => ({
        url: '/v1/doctor/observationDescription/',
        method: 'GET',
      }),
    }),
    getPatientEvents: builder.query<PatientEventAndType[], string>({
      providesTags: ['Events'],
      query: patientId => ({
        url: '/v1/patientEvents/latest',
        params: {
          patientId,
          statuses: [STATUS_TYPE.PRESENT, STATUS_TYPE.ABSENT],
        },
      }),
      transformResponse: (response: LatestEventDTO[]) => selectEventsFromResponse(response),
    }),
    getLatestPatientEvents: builder.query<PatientEventAndType[], GetLatestPatientEventsRequestDTO>({
      providesTags: (result, error, queryArgs) => [
        {
          type: queryArgs.type,
        },
      ],
      query: ({ allowFamilyRelationship, patientId, subjects }) => ({
        url: '/v1/patientEvents/latest',
        params: {
          patientId,
          allowFamilyRelationship,
          subjects,
        },
      }),
      transformResponse: (response: LatestEventDTO[]) => selectEventsFromResponse(response),
    }),
    getPatientEventAntecedents: builder.query<PatientEventAndType[], void>({
      providesTags: ['Events', PatientEventType.ANTECEDENTS],
      query: () => ({
        url: '/api/v1/patientEvents/latest',
        params: {
          statuses: [STATUS_TYPE.PRESENT, STATUS_TYPE.ABSENT],
          allowFamilyRelationship: true,
        },
      }),
      transformResponse: (response: LatestEventDTO[]) =>
        selectPatientEventAndTypeAntecedentsFromResponse(response),
    }),
    getPatientEventHistory: builder.query<PatientEventAndType[], GetEventHistoryRequestDTO>({
      providesTags: ['Events'],
      query: ({ eventTypeId, allowFamilyRelationship, appendProgramLabel }) => ({
        url: '/api/v1/patientEvents',
        params: {
          eventTypeId,
          allowFamilyRelationship,
          appendProgramLabel,
        },
      }),
      transformResponse: (response: LatestEventDTO[]) => selectEventsFromResponse(response),
    }),
    getPatientEventAntecedentHistory: builder.query<
      PatientEventAndType[],
      GetEventHistoryRequestDTO
    >({
      providesTags: [PatientObservationType.ANTECEDENTS],
      query: ({ eventTypeId, allowFamilyRelationship, appendProgramLabel }) => ({
        url: '/api/v1/patientEvents',
        params: {
          eventTypeId,
          allowFamilyRelationship,
          appendProgramLabel,
        },
      }),
      transformResponse: (response: LatestEventDTO[]) =>
        selectPatientEventAndTypeAntecedentsFromResponse(response),
    }),
    createPatientEvent: builder.mutation<void, CreatePatientEventRequestDTO>({
      invalidatesTags: [PatientObservationType.ANTECEDENTS, 'Events'],
      query: ({ body }) => ({
        url: `/api/v1/patientEvents`,
        method: 'POST',
        body: [transformDateFromBody(body)],
      }),
    }),
    forcePatientEventCreation: builder.mutation<void, CreatePatientEventRequestDTO>({
      invalidatesTags: [PatientObservationType.ANTECEDENTS, 'Events'],
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const eventExpirationResponse = await fetchWithBQ({
          url: `/api/v1/patientEvents/expireValid`,
          method: 'PUT',
          params: {
            etId: _arg.body.eventTypeId,
            familyRelationship: _arg.body?.familyRelationshipId,
            skipLatest: false,
          },
        });

        if (eventExpirationResponse.error) throw eventExpirationResponse.error;

        const currentDate: Date = new Date();

        const creationEventResponse = fetchWithBQ({
          url: `/api/v1/patientEvents`,
          method: 'POST',
          body: [
            transformDateFromBody({ ..._arg.body, startDate: currentDate, endDate: currentDate }),
          ],
        });

        return creationEventResponse as QueryReturnValue<void, FetchBaseQueryError>;
      },
    }),
    replacePatientEvent: builder.mutation<void, CreatePatientEventRequestDTO>({
      invalidatesTags: [PatientObservationType.ANTECEDENTS, 'Events'],
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const deletePatientEventResponse = await fetchWithBQ({
          url: `/api/v1/patientEvents/${_arg.body.id}`,
          method: 'DELETE',
        });

        if (deletePatientEventResponse.error) throw deletePatientEventResponse.error;

        const creationEventResponse = fetchWithBQ({
          url: `/api/v1/patientEvents`,
          method: 'POST',
          body: [transformDateFromBody(_arg.body)],
        });

        return creationEventResponse as QueryReturnValue<void, FetchBaseQueryError>;
      },
    }),
    deletePatientEvent: builder.mutation<void, DeleteRecordRequestDTO>({
      invalidatesTags: [],
      query: ({ recordId }) => ({
        url: `/api/v1/patientEvents/${recordId}`,
        method: 'DELETE',
      }),
    }),

    // FIXME: don't exist anymore, fact-types ??
    getEvents: builder.query<Event[], void>({
      query: () => ({
        url: '/v1/doctor/eventType',
        method: 'GET',
      }),
    }),

    createPatientFact: builder.mutation<void, CreatePatientFactRequestDTO>({
      invalidatesTags: [PatientFactType.STATISTICS, PatientFactType.ANALYSES],
      query: ({ body }) => ({
        url: `/api/v1/patientFacts`,
        method: 'POST',
        body: [transformDateFromBody(body)],
      }),
    }),
    createMultiplePatientFact: builder.mutation<void, CreateMultiplePatientFactRequestDTO>({
      invalidatesTags: [PatientFactType.STATISTICS, PatientFactType.ANALYSES],
      query: ({ body }) => ({
        url: `/api/v1/patientFacts`,
        method: 'POST',
        body: body.map(transformDateFromBody),
      }),
    }),
    forcePatientFactCreation: builder.mutation<void, CreatePatientFactRequestDTO>({
      invalidatesTags: [PatientFactType.STATISTICS, PatientFactType.ANALYSES],
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const factExpirationResponse = await fetchWithBQ({
          url: `/api/v1/patientFacts/expireValid`,
          method: 'PUT',
          params: {
            ftId: _arg.body.factTypeId,
            skipLatest: false,
          },
        });

        if (factExpirationResponse.error) throw factExpirationResponse.error;

        const currentDate: Date = new Date();

        const creationFactResponse = fetchWithBQ({
          url: `/api/v1/patientFacts`,
          method: 'POST',
          body: [
            transformDateFromBody({ ..._arg.body, startDate: currentDate, endDate: currentDate }),
          ],
        });

        return creationFactResponse as QueryReturnValue<void, FetchBaseQueryError>;
      },
    }),
    replacePatientFact: builder.mutation<void, ReplacePatientFactRequestDTO>({
      invalidatesTags: [PatientFactType.STATISTICS, PatientFactType.ANALYSES],
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const deletePatientFactResponse = await fetchWithBQ({
          url: `/api/v1/patientFacts/${_arg.id}`,
          method: 'DELETE',
        });

        if (deletePatientFactResponse.error) throw deletePatientFactResponse.error;

        const creationFactResponse = fetchWithBQ({
          url: `/api/v1/patientFacts`,
          method: 'POST',
          body: [
            transformDateFromBody({
              answerType: _arg.body.answerType,
              endDate: _arg.body.endDate,
              factTypeId: _arg.body.factTypeId,
              measurementUnit: _arg?.body.measurementUnit,
              patientCareEpisodeId: _arg?.body.patientCareEpisodeId,
              qualitativeResult: _arg?.body.qualitativeResult,
              startDate: _arg.body.startDate,
              status: _arg?.body.status,
              value: _arg.body.value,
            }),
          ],
        });

        return creationFactResponse as QueryReturnValue<void, FetchBaseQueryError>;
      },
    }),
    deletePatientFact: builder.mutation<void, DeleteRecordRequestDTO>({
      invalidatesTags: [PatientFactType.STATISTICS, PatientFactType.ANALYSES],
      query: ({ recordId }) => ({
        url: `/api/v1/patientFacts/${recordId}`,
        method: 'DELETE',
      }),
    }),
    getFacts: builder.query<Fact[], void>({
      query: () => ({
        url: '/v1/doctor/factType',
        method: 'GET',
      }),
    }),
    getLatestPatientFacts: builder.query<PatientFactAndType[], GetLatestPatientFactsRequestDTO>({
      providesTags: (result, error, queryArgs) => [
        {
          type: queryArgs.type,
        },
      ],
      query: ({ patientId, statuses, subjects }) => ({
        url: '/api/v1/patientFacts/latest',
        params: {
          patientId,
          statuses,
          subjects,
        },
      }),
      transformResponse: (response: LatestFactDTO[]) => selectFactFromResponse(response),
    }),
    getPatientStatistics: builder.query<PatientFactAndType[], void>({
      providesTags: [PatientFactType.STATISTICS],
      query: () => ({
        url: '/api/v1/patientFacts/latest',
        params: {
          statuses: [STATUS_TYPE.PRESENT, STATUS_TYPE.ABSENT],
        },
      }),
      transformResponse: (response: LatestFactDTO[]) => selectStatisticsFromResponse(response),
    }),
    getPatientStatisticHistory: builder.query<PatientFactAndType[], GetFactHistoryRequestDTO>({
      providesTags: [PatientFactType.STATISTICS],
      query: ({ factTypeId }) => ({
        url: '/api/v1/patientFacts',
        params: {
          factTypeId,
        },
      }),
      transformResponse: (response: LatestFactDTO[]) => selectStatisticsFromResponse(response),
    }),
    getPatientAnalyses: builder.query<PatientFactAndType[], void>({
      providesTags: [PatientFactType.ANALYSES],
      query: () => ({
        url: '/api/v1/patientFacts/latest',
        params: {
          statuses: [STATUS_TYPE.PRESENT, STATUS_TYPE.ABSENT],
        },
      }),
      transformResponse: (response: LatestFactDTO[]) => selectAnalysesFromResponse(response),
    }),
    getPatientAnalysisHistory: builder.query<PatientFactAndType[], GetFactHistoryRequestDTO>({
      providesTags: [PatientFactType.ANALYSES],
      query: ({ factTypeId }) => ({
        url: '/api/v1/patientFacts',
        params: {
          factTypeId,
        },
      }),
      transformResponse: (response: LatestFactDTO[]) => selectAnalysesFromResponse(response),
    }),
    getFamilyRelationships: builder.query<CoreAppItem[], void>({
      query: () => ({
        url: '/api/v1/familyRelationships/all',
      }),
    }),
  }),
});

export const {
  // Observation
  useGetObservationsQuery,
  useGetPatientObservationsQuery,
  useGetAllPatientObservationsQuery,
  useGetLatestPatientObservationsQuery,
  useCreatePatientObservationMutation,
  useForcePatientObservationCreationMutation,
  useReplacePatientObservationMutation,
  useDeletePatientObservationMutation,

  // Diagnostic
  useGetPatientDiagnosticHistoryQuery,

  // Suspicion
  useGetPatientSuspicionHistoryQuery,

  // Risk
  useGetPatientRiskHistoryQuery,

  // Behaviour
  useGetPatientBehaviourHistoryQuery,

  // Manifestation
  useGetPatientManifestationHistoryQuery,

  // Event
  useCreatePatientEventMutation,
  useForcePatientEventCreationMutation,
  useReplacePatientEventMutation,
  useDeletePatientEventMutation,
  useGetLatestPatientEventsQuery,
  useGetPatientEventsQuery,
  useGetPatientEventHistoryQuery,
  useGetEventsQuery,

  // Antecedent
  useGetPatientEventAntecedentsQuery,
  useGetPatientEventAntecedentHistoryQuery,
  useGetPatientDiagnosticAntecedentHistoryQuery,

  // Fact
  useGetLatestPatientFactsQuery,
  useCreatePatientFactMutation,
  useCreateMultiplePatientFactMutation,
  useDeletePatientFactMutation,
  useForcePatientFactCreationMutation,
  useReplacePatientFactMutation,
  useGetFactsQuery,

  // Statistic
  useGetPatientStatisticsQuery,
  useGetPatientStatisticHistoryQuery,

  // Analysis
  useGetPatientAnalysesQuery,
  useGetPatientAnalysisHistoryQuery,

  // FamilyRelationship
  useGetFamilyRelationshipsQuery,
} = medicalRecordApi;
