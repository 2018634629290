import { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { DegreeType } from 'src/domains/medical-record/entity/observation';

const useStyles = makeStyles(() => ({
  lowDegree: {
    color: '#2ccb56',
  },
  mediumDegree: {
    color: '#deb510',
  },
  highDegree: {
    color: '#ff4b33',
  },
}));

type DegreeFieldProps = {
  value?: DegreeType;
  label?: string;
};

export const DegreeField: FunctionComponent<DegreeFieldProps> = ({ value, label }) => {
  const classes = useStyles();

  if (value === DegreeType.HIGH)
    return (
      <Typography className={classes.highDegree} variant={'h5'}>
        {label ?? 'Fort'}
      </Typography>
    );

  if (value === DegreeType.MEDIUM)
    return (
      <Typography className={classes.mediumDegree} variant={'h5'}>
        {label ?? 'Modéré'}
      </Typography>
    );

  if (value === DegreeType.LOW)
    return (
      <Typography className={classes.lowDegree} variant={'h5'}>
        {label ?? 'Faible'}
      </Typography>
    );

  return (
    <Typography color={'primary'} variant={'body1'}>
      {'-'}
    </Typography>
  );
};

export default DegreeField;
