import { useMemo } from 'react';
import { StyleSheet } from '@react-pdf/renderer';

type AvailableColors = 'warning' | 'info' | 'error' | 'success' | 'secondary';

const palette: {
  [k in AvailableColors]: { main: string; light: string };
} = {
  success: { main: '#2ccb56', light: '#e9faed' },
  warning: { main: '#deb510', light: '#fdf8e5' },
  error: { main: '#ff4b33', light: '#ffecea' },
  info: { main: '#216583', light: '#e3f1f8' },
  secondary: { main: '#216583', light: '#e3f1f8' },
};

export const useStyles = ({ color = 'secondary' }: { color?: AvailableColors }) => {
  return useMemo(
    () =>
      StyleSheet.create({
        root: {
          paddingVertical: 10,
          display: 'flex',
          flexDirection: 'column',
        },
        title: { fontSize: 9, fontWeight: 'normal', paddingBottom: 5 },
        textContainer: {
          padding: 5,
          border: `1pt solid ${palette[color].main}`,
          backgroundColor: palette[color].light,
        },
        text: {
          fontSize: 10,
          fontWeight: 'normal',
          color: palette[color].main,
        },
      }),
    [color],
  );
};
