import { useCallback } from 'react';

import { AnswerType } from 'src/domains/medical-record/value-object/AnswerType';
import { IBehaviourPreviewVM } from '../../BehaviourPreviewVM';
import { BehaviourCreation } from '../../BehaviourForm/schema';
import { ObservationCreationDTO } from 'src/domains/medical-record/dto';
import useUpdateObservationUseCase from 'src/domains/medical-record/use-case/observation/useUpdateObservationUseCase';
import { SubmitAction } from '../../../../Modals/PersistenceModal/PersistenceForm/constants';

const useUpdateBehaviour = (patientId: string, visualModel: IBehaviourPreviewVM) => {
  const { updateObservation, ...rest } = useUpdateObservationUseCase();

  const update = useCallback(
    (modification: BehaviourCreation, submitAction?: SubmitAction) => {
      const creationDTO: ObservationCreationDTO = {
        answerType: AnswerType.DOCTOR,
        endDate: modification.startDate as Date,
        observationDescriptionId: visualModel.descriptionId,
        startDate: modification.startDate as Date,
        status: modification.status,
      };

      updateObservation(patientId, visualModel.id, creationDTO, submitAction);
    },
    [visualModel.descriptionId, visualModel.id, patientId, updateObservation],
  );

  return {
    update,
    ...rest,
  };
};

export default useUpdateBehaviour;
