import { SchemaOf } from 'yup';
import * as yup from 'yup';

export type ValidationCode = {
  code?: number;
};

export const schema: SchemaOf<ValidationCode> = yup.object({
  code: yup
    .number()
    .required('Veuillez saisir le code de validation')
    .typeError('Format incorrect: veuillez saisir une série de chiffres'),
});
