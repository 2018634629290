import { useMemo } from 'react';

import { SelectOption } from 'src/components/common/Select';
import { useGetFamilyRelationshipsQuery } from 'src/domains/medical-record/repository';
import { toTitleCase } from '../../../../../../helpers/format/string';

const useAntecedentForm = () => {
  const {
    data: familyRelationShips = [],
    isLoading,
    isSuccess,
    error,
  } = useGetFamilyRelationshipsQuery();

  const data: SelectOption[] = useMemo(
    () => [
      { label: 'Patient', value: undefined },
      ...familyRelationShips.map(familyRelationShip => ({
        label: familyRelationShip.label && toTitleCase(familyRelationShip.label),
        value: familyRelationShip.id,
      })),
    ],
    [familyRelationShips],
  );

  return {
    data,
    isLoading,
    isSuccess,
    error,
  };
};

export default useAntecedentForm;
