import React, { createElement, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import { FormikHelpers, FormikValues } from 'formik/dist/types';

import ModalFooterButtons, { ButtonParams } from 'src/components/common/Modal/ModalFooterButtons';
import useStyles from './styles';
import CreationConflict from './CreationConflict';
import { SubmitAction } from './constants';
import { CreationError } from 'src/domains/medical-record/repository';
import { isNil, reject } from 'ramda';

interface PersistenceFormProps {
  initialValues: FormikValues['initialValues'];
  isCreating?: boolean;
  isReplacing?: boolean;
  isForceCreating?: boolean;
  isSubmittingSuccessfully: boolean;
  isUpdateMode: boolean;
  formContent: FunctionComponent<FormikProps<any>>;
  onClose: () => void;
  onPrevious?: () => void;
  error?: any;
  onSubmit: (creationDTO: any, submitAction?: SubmitAction) => void;
  validationSchema: FormikValues['validationSchema'];
}

const PersistenceForm: FunctionComponent<PersistenceFormProps> = ({
  error,
  formContent,
  initialValues,
  isUpdateMode = false,
  isCreating = false,
  isReplacing = false,
  isForceCreating = false,
  isSubmittingSuccessfully,
  onClose,
  onPrevious,
  onSubmit,
  validationSchema,
}) => {
  const classes = useStyles();

  const [hasConflict, setHasConflict] = useState(false);
  const [submitAction, setSubmitAction] = useState<SubmitAction>(SubmitAction.ADD_OR_UPDATE);

  useEffect(() => {
    if (error && (error as CreationError).data.code === 'medicalRecord.creation.alreadyExists') {
      setHasConflict(true);
    }
  }, [error]);

  const handleOnSubmit: (
    values: any,
    formikHelpers: FormikHelpers<any>,
    submitAction?: SubmitAction,
  ) => void | Promise<any> = useCallback(
    formValues => {
      onSubmit(formValues, submitAction);
    },
    [onSubmit, submitAction],
  );

  useEffect(() => {
    if (!isCreating && !isReplacing && !isForceCreating && isSubmittingSuccessfully) onClose();
  }, [isCreating, isForceCreating, isReplacing, isSubmittingSuccessfully, onClose]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      {props => {
        const buttons: ButtonParams[] = reject(isNil)([
          onPrevious
            ? {
                props: {
                  variant: 'outlined',
                  color: 'primary',
                  type: 'button',
                  onClick: onPrevious,
                  disabled: props.isSubmitting,
                },
                label: 'Précédent',
              }
            : null,
          {
            props: {
              variant: 'contained',
              color: 'primary',
              type: 'submit',
              onClick: () => setSubmitAction(SubmitAction.ADD_OR_UPDATE),
              disabled: !props.isValid || (props.isSubmitting && hasConflict),
            },
            label: isCreating ? (
              <CircularProgress className={classes.circularProgress} size={22} />
            ) : isUpdateMode ? (
              'Mettre à jour'
            ) : (
              'Ajouter'
            ),
          },
        ]);

        return (
          <Form>
            <div className={classes.formContent}>{createElement(formContent, props)}</div>
            {hasConflict && (
              <div className={classes.footer}>
                <CreationConflict
                  isCreating={isCreating}
                  isReplacing={isReplacing}
                  isForceCreating={isForceCreating}
                  onCancelClick={onClose}
                  onSubmit={setSubmitAction}
                />
              </div>
            )}
            <div className={classes.footer}>
              <ModalFooterButtons buttons={buttons} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PersistenceForm;
