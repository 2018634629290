import { skipToken } from '@reduxjs/toolkit/query';
import {
  GetEventHistoryRequestDTO,
  useGetPatientDiagnosticAntecedentHistoryQuery,
  useGetPatientEventAntecedentHistoryQuery,
} from '../../repository';
import { isEventType, isObservationType, MedicalRecordType } from '../../entity';
import { useMemo } from 'react';
import {
  PatientAntecedent,
  bindFromPatientEventAndType,
  bindFromPatientObservation,
} from '../../entity/patientAntecedent';
import { GetObservationHistoryRequestDTO } from '../../repository/dto/GetObservationHistoryRequestDTO';

interface UseGetPatientAntecedentHistoryUseCaseParams {
  recordType: MedicalRecordType;
  relatedTemplateElementId: string;
  familyRelationshipId?: string;
}

const useGetPatientAntecedentHistoryUseCase = (
  params: UseGetPatientAntecedentHistoryUseCaseParams,
) => {
  const eventDto: GetEventHistoryRequestDTO = useMemo(
    () => ({
      eventTypeId: params.relatedTemplateElementId,
      allowFamilyRelationship: true,
      appendProgramLabel: true,
    }),
    [params?.relatedTemplateElementId],
  );

  const {
    data: patientEventAntecedentHistory = [],
    isLoading: isGetPatientEventAntecedentHistoryLoading,
    error: patientEventAntecedentRequestError,
  } = useGetPatientEventAntecedentHistoryQuery(
    isEventType(params.recordType) ? eventDto : skipToken,
  );

  const diagnosticDto: GetObservationHistoryRequestDTO = useMemo(
    () => ({
      observationDescriptionId: params.relatedTemplateElementId,
      familyRelationshipId: params?.familyRelationshipId,
    }),
    [params?.familyRelationshipId, params.relatedTemplateElementId],
  );

  const {
    data: patientDiagnosticAntecedentHistory = [],
    isLoading: isGetPatientDiagnosticAntecedentHistoryLoading,
    error: patientDiagnosticAntecedentRequestError,
  } = useGetPatientDiagnosticAntecedentHistoryQuery(
    isObservationType(params.recordType) ? diagnosticDto : skipToken,
  );

  const data: PatientAntecedent[] = useMemo(() => {
    if (isObservationType(params.recordType))
      return patientDiagnosticAntecedentHistory.map(bindFromPatientObservation);

    if (isEventType(params.recordType))
      return patientEventAntecedentHistory.map(bindFromPatientEventAndType);
    return [];
  }, [params.recordType, patientDiagnosticAntecedentHistory, patientEventAntecedentHistory]);

  return {
    data,
    isLoading:
      isGetPatientDiagnosticAntecedentHistoryLoading || isGetPatientEventAntecedentHistoryLoading,
    error: patientDiagnosticAntecedentRequestError || patientEventAntecedentRequestError,
  };
};

export default useGetPatientAntecedentHistoryUseCase;
