import { CellProps } from 'react-table';
import React, { useMemo } from 'react';

import { EnhancedColumn } from 'src/components/common/Table/types';
import TableActions, { TableAction } from 'src/components/common/Table/Actions';

export type HandleClick<T> = (entity: T) => void;

export interface TableActionParams<T> {
  handleCreateClick?: () => void;
  handleDeleteClick?: HandleClick<T>;
  handleEditClick?: HandleClick<T>;
  handleHistoryClick?: HandleClick<T>;
}

export const useTableActions = <T extends object = {}>({
  handleCreateClick,
  handleEditClick,
  handleDeleteClick,
  handleHistoryClick,
}: TableActionParams<T>): EnhancedColumn<T> =>
  useMemo<EnhancedColumn<T>>(
    () =>
      ({
        Header: '',
        accessor: 'id',
        width: '5%',
        align: 'right',
        Cell: (props: CellProps<T>) => {
          const vm = props?.cell?.row?.original;

          const actions: TableAction<any>[] = useMemo(() => {
            let actions = [];
            if (handleCreateClick)
              actions.push({
                label: 'Ajouter',
                method: () => handleCreateClick && handleCreateClick(),
              });

            if (handleEditClick)
              actions.push({
                label: 'Mettre à jour',
                method: () => handleEditClick && handleEditClick(vm),
              });

            if (handleHistoryClick)
              actions.push({
                label: 'Historique',
                method: () => handleHistoryClick && handleHistoryClick(vm),
              });

            if (handleDeleteClick)
              actions.push({
                label: 'Supprimer',
                method: () => handleDeleteClick && handleDeleteClick(vm),
              });

            return actions;
          }, [vm]);

          return <TableActions actions={actions} />;
        },
      } as EnhancedColumn<T>),
    [handleCreateClick, handleDeleteClick, handleEditClick, handleHistoryClick],
  );

export default useTableActions;
