export type MeasurementUnit = {
  id: string;
  longName: string;
  shortName: string;
};

export type MeasurementUnitDto = {
  id: string;
  longName?: string;
  shortName?: string;
};

export type Quantity = {
  id: string;
  value: number;
  measurementUnit: MeasurementUnit;
};

export const displayMeasurementUnit = (
  measure: MeasurementUnit | undefined,
  withLongName: boolean = false,
) => {
  if (!measure || measure?.shortName === 'U') return '';

  if (withLongName) return measure.longName;

  return measure.shortName;
};
