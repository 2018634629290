import { useCallback } from 'react';

import { FactCreationDTO } from '../../dto';
import {
  useCreatePatientFactMutation,
  useForcePatientFactCreationMutation,
  useReplacePatientFactMutation,
} from '../../repository';
import { SubmitAction } from '../../../../pages/PatientDashboard/PatientDashboardUpperSection/MedicalRecord/Modals/PersistenceModal/PersistenceForm/constants';

const useUpdatePatientFactUseCase = () => {
  const [
    createFact,
    {
      isLoading: isPatientFactCreationLoading,
      isSuccess: isPatientFactCreationSuccess,
      error: patientFactCreationError,
    },
  ] = useCreatePatientFactMutation();

  const [
    forcePatientFactCreation,
    {
      isLoading: isForcePatientFactCreationLoading,
      isSuccess: isForcePatientFactCreationSuccess,
      error: forcePatientFactCreationError,
    },
  ] = useForcePatientFactCreationMutation();

  const [
    replacePatientFact,
    {
      isLoading: isReplacePatientFactLoading,
      isSuccess: isReplacePatientFactSuccess,
      error: replacePatientFactError,
    },
  ] = useReplacePatientFactMutation();

  const updateFact = useCallback(
    (
      recordId: string,
      dto: FactCreationDTO,
      submitAction: SubmitAction | undefined = SubmitAction.ADD_OR_UPDATE,
    ) => {
      if (submitAction === SubmitAction.ADD_OR_UPDATE) createFact({ body: dto });

      if (submitAction === SubmitAction.REPLACE)
        replacePatientFact({
          id: recordId,
          body: dto,
        });

      if (submitAction === SubmitAction.FORCE_CREATION) forcePatientFactCreation({ body: dto });
    },
    [createFact, forcePatientFactCreation, replacePatientFact],
  );

  return {
    updateFact,
    isCreationLoading: isPatientFactCreationLoading,
    isCreationSuccess: isPatientFactCreationSuccess,
    isReplaceLoading: isReplacePatientFactLoading,
    isReplaceSuccess: isReplacePatientFactSuccess,
    isForceCreationLoading: isForcePatientFactCreationLoading,
    isForceCreationSuccess: isForcePatientFactCreationSuccess,
    isSuccess:
      isForcePatientFactCreationSuccess ||
      isReplacePatientFactSuccess ||
      isPatientFactCreationSuccess,
    error: patientFactCreationError || forcePatientFactCreationError || replacePatientFactError,
  };
};

export default useUpdatePatientFactUseCase;
