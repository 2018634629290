import { isDate } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const formatDateToParisTimezone = (date: Date) => {
  return formatInTimeZone(date, 'Europe/Paris', "yyyy-MM-dd'T'HH:mm:ss");
};

type Deserialized<T extends Record<string, any>> = {
  [U in keyof T]: T[U] extends Date ? string : T[U];
};

const isObject = (obj: Object) =>
  typeof obj === 'object' && !Array.isArray(obj) && obj !== null && !isDate(obj);
export function transformDateFromBody<T extends Record<string, any>>(body: T): Deserialized<T> {
  return Object.entries(body).reduce((acc, [key, value]) => {
    let v = value;

    if (isDate(value)) {
      v = formatDateToParisTimezone(value);
    }

    if (isObject(value)) {
      v = transformDateFromBody(value);
    }

    if (Array.isArray(value)) {
      v = value.map(transformDateFromBody);
    }

    return {
      ...acc,
      [key]: v,
    };
  }, {} as Deserialized<T>);
}
