import { FunctionComponent, memo } from 'react';
import { Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography/Typography';
import useStyles from './styles';

export interface AlertTextProps extends Omit<TypographyProps, 'color'> {
  color: 'warning' | 'info' | 'error' | 'success';
  alternativePalette?: boolean;
}

const AlertText: FunctionComponent<AlertTextProps> = memo(props => {
  const { color, alternativePalette, ...rest } = props;
  const classes = useStyles({ color, alternativePalette: alternativePalette || false });
  return <Typography className={classes.typography} {...rest} />;
});
export default AlertText;
