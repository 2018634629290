import { isNilOrEmpty } from 'ramda-adjunct';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { NavigationRoute } from '../routes/navigationRoute';
import { User } from '../domains/user/entity/user';
import history from 'src/app/history';
import storageService, { StorageType } from './storage';
import { clearReportStore } from './indexed-db';

export enum StorageKeys {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  USER = 'USER',
  ORGANIZATION_ID = 'ORGANIZATION_ID',
  XSRF_TOKEN = 'XSRF-TOKEN',
  BSESSION = 'BSESSION',
  B_TRUST_ME = 'B_TRUST_ME',
  USER_IDS_MAPPING = 'USER_IDS_MAPPING',
  RETRY_AFTER = 'RETRY_AFTER',
  SECURITY_CODE_RETRY_AFTER = 'SECURITY_CODE_RETRY_AFTER',
}

class AuthenticationServiceImpl {
  clearUser(): void {
    storageService.removeItem(StorageKeys.USER);
  }

  clearToken(): void {
    storageService.removeItem(StorageKeys.ACCESS_TOKEN, StorageType.SESSION);
  }

  clearOrganizationId(): void {
    storageService.removeItem(StorageKeys.ORGANIZATION_ID);
  }

  clearUserIdsMapping(): void {
    storageService.removeItem(StorageKeys.USER_IDS_MAPPING, StorageType.SESSION);
  }

  clearRetryAfter(): void {
    storageService.removeItem(StorageKeys.RETRY_AFTER, StorageType.COOKIE);
  }

  clearSecurityCodeRetryAfter(): void {
    storageService.removeItem(StorageKeys.SECURITY_CODE_RETRY_AFTER, StorageType.COOKIE);
  }

  clear(): void {
    this.clearUser();
    this.clearToken();
    this.clearUserIdsMapping();
    this.clearOrganizationId();
  }

  getAccessTokenFromStorage() {
    return storageService.getItem(StorageKeys.ACCESS_TOKEN, StorageType.SESSION);
  }

  getAccessTokenExpirationDate(token: string): number | undefined {
    const decodedToken = jwtDecode<JwtPayload>(token);
    return decodedToken?.exp;
  }

  getUserIdsMapping(): Record<string, string> | undefined {
    return storageService.getItem(StorageKeys.USER_IDS_MAPPING, StorageType.SESSION);
  }

  getPatientRefId(patientId: string): string | undefined {
    const userIdsMapping = this.getUserIdsMapping();

    if (!userIdsMapping) return undefined;

    return userIdsMapping[patientId];
  }

  hasTokenForPatient(patientId: string): boolean {
    const userIdsMapping = this.getUserIdsMapping();

    if (!userIdsMapping) return false;

    return !!Object.keys(userIdsMapping).find(key => key === patientId);
  }

  getBSession(): string {
    return JSON.parse(storageService.getItem(StorageKeys.BSESSION, StorageType.COOKIE));
  }

  getBTrustMe(): string {
    return JSON.parse(storageService.getItem(StorageKeys.B_TRUST_ME, StorageType.COOKIE));
  }

  getOrganizationId(): number {
    return JSON.parse(storageService.getItem(StorageKeys.ORGANIZATION_ID));
  }

  getUser(): User {
    return storageService.getItem(StorageKeys.USER);
  }

  getXSRFToken(): string {
    return storageService.getItem(StorageKeys.XSRF_TOKEN, StorageType.COOKIE) || null;
  }

  getRetryAfter(): Date {
    return storageService.getItem(StorageKeys.RETRY_AFTER, StorageType.COOKIE);
  }

  getSecurityCodeRetryAfter(): Date {
    return storageService.getItem(StorageKeys.SECURITY_CODE_RETRY_AFTER, StorageType.COOKIE);
  }

  isAccessTokenExpired() {
    const accessToken = this.getAccessTokenFromStorage();

    if (isNilOrEmpty(accessToken)) return true;

    const accessTokenExpiredAt = this.getAccessTokenExpirationDate(accessToken);

    if (isNilOrEmpty(accessTokenExpiredAt)) return true;

    return Number(accessTokenExpiredAt) < Math.floor(Date.now() / 1000);
  }

  isAuthenticated() {
    return isNilOrEmpty(this.getBSession());
  }

  setOrUpdateOrganizationId(organizationId: number) {
    return storageService.setItem(StorageKeys.ORGANIZATION_ID, organizationId);
  }

  setOrUpdateAccessToken(token: string): void {
    storageService.setItem(StorageKeys.ACCESS_TOKEN, token, StorageType.SESSION);
  }

  setOrUpdateUserIdsMapping(userIdsMapping: Record<string, string>): void {
    storageService.setItem(StorageKeys.USER_IDS_MAPPING, userIdsMapping, StorageType.SESSION);
  }

  setOrUpdateUser(user: User): void {
    storageService.setItem(StorageKeys.USER, user);
  }

  setRetryAfter(datetime: Date): void {
    storageService.setItem(StorageKeys.RETRY_AFTER, datetime, StorageType.COOKIE);
  }

  setSecurityCodeRetryAfter(datetime: Date): void {
    storageService.setItem(StorageKeys.SECURITY_CODE_RETRY_AFTER, datetime, StorageType.COOKIE);
  }

  logout() {
    this.clear();
    clearReportStore();
    history.push(NavigationRoute.LOGIN);
  }

  logoutWithLocationState() {
    this.clear();
    clearReportStore();
    history.push({
      pathname: NavigationRoute.LOGIN,
      state: { from: { pathname: history.location.pathname } },
    });
  }
}

const authenticationService = new AuthenticationServiceImpl();
export default authenticationService;
