import { SubjectType } from '../../../value-object/SubjectType';
import { LatestFactDTO } from '../../../dto';
import { Fact, PatientFactAndType } from '../../../entity/fact';

const staticsSubjects: string[] = [
  SubjectType.PARAMETRE,
  SubjectType.SCORE,
  SubjectType.ETATPHYSIOLOGIQUE,
];

export const selectStatisticsFromResponse = (
  response: LatestFactDTO[] = [],
): PatientFactAndType[] => {
  const filteredLatestFactDTO: LatestFactDTO[] = response.filter(({ subject }) => {
    return staticsSubjects.includes(subject.label);
  });

  return filteredLatestFactDTO.map(latestFactDTO => ({
    fact: latestFactDTO.patientFact,
    type: latestFactDTO.factType,
  }));
};

export const selectStatisticFacts = (facts: Fact[]): Fact[] =>
  facts.filter(fact => staticsSubjects.includes(fact.subject.label));
