import { useCallback } from 'react';

import { AnswerType } from 'src/domains/medical-record/value-object/AnswerType';
import { IManifestationPreviewVM } from '../../ManifestationPreviewVM';
import { ManifestationCreation } from '../../ManifestationForm/schema';
import { ObservationCreationDTO } from 'src/domains/medical-record/dto';
import { SubmitAction } from '../../../../Modals/PersistenceModal/PersistenceForm/constants';
import useUpdateObservationUseCase from 'src/domains/medical-record/use-case/observation/useUpdateObservationUseCase';

const useUpdateManifestation = (patientId: string, visualModel: IManifestationPreviewVM) => {
  const { updateObservation, ...rest } = useUpdateObservationUseCase();

  const update: (modification: ManifestationCreation, submitAction?: SubmitAction) => void =
    useCallback(
      (modification, submitAction = SubmitAction.ADD_OR_UPDATE) => {
        const creationDTO: ObservationCreationDTO = {
          answerType: AnswerType.DOCTOR,
          endDate: modification.startDate as Date,
          observationDescriptionId: visualModel.descriptionId,
          startDate: modification.startDate as Date,
          status: modification.status,
        };
        updateObservation(patientId, visualModel.id, creationDTO, submitAction);
      },
      [visualModel.descriptionId, visualModel.id, updateObservation, patientId],
    );

  return {
    update,
    ...rest,
  };
};

export default useUpdateManifestation;
