import {
  ClickAwayListener,
  Fade,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { useEffect, useRef, MouseEvent, KeyboardEvent, FunctionComponent } from 'react';
import { useToggle } from 'react-use';

import useStyles from './styles';
import { MoreHoriz } from '@material-ui/icons';

export type TableAction<T> = {
  label: string;
  method: (row: T) => void;
};

interface TableActionsProps {
  actions: TableAction<any>[];
}

export const TableActions: FunctionComponent<TableActionsProps> = ({ actions = [] }) => {
  const classes = useStyles();
  const [open, toggle] = useToggle(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    toggle(false);
  };

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      toggle(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={toggle}
        color={'primary'}
        size={'small'}
        disableRipple
      >
        <MoreHoriz />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
        placement={'bottom-start'}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {actions.map(action => (
                    <MenuItem
                      key={`action-${action.label}`}
                      disableRipple
                      onClick={params => {
                        action.method(params);
                        toggle(false);
                      }}
                    >
                      <Typography variant={'body2'} color={'primary'}>
                        {action.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default TableActions;
