import { makeStyles } from '@material-ui/core/styles';
import colors from 'src/theme/colors';

export default makeStyles(theme => ({
  paper: {
    border: `solid 1px ${colors.LIGHT_BLUE.light}`,
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
}));
