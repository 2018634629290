import { FunctionComponent } from 'react';

import AuthenticationLocked from './AuthenticationLocked';
import CredentialsForm from './CredentialsForm';
import Page from 'src/components/common/Page';
import SecurityCodeForm from '../../components/authentication/SecurityCodeForm';
import TrustBrowser from './TrustBrowser';
import useAuthenticate, { AuthenticationStep } from './use-authenticate';
import NominalLayout from '../../components/common/NominalLayout';

const LoginPage: FunctionComponent = () => {
  const {
    accessToken,
    credentials,
    errorMessage,
    isLoading,
    authenticationStep,
    onAuthenticationBlockedEnd,
    onCredentialFormSubmit,
    onSecurityCodeSubmit,
    onTrustBrowserEnd,
    remainingAttempts,
  } = useAuthenticate();

  return (
    <Page testId="login-page">
      <NominalLayout>
        {authenticationStep === AuthenticationStep.DEFAULT && (
          <CredentialsForm
            login={credentials?.login}
            onSubmit={onCredentialFormSubmit}
            isLoading={isLoading}
            errorMessage={errorMessage}
            remainingAttempts={remainingAttempts}
          />
        )}

        {authenticationStep === AuthenticationStep.SECURITY_CODE && (
          <SecurityCodeForm
            accessToken={accessToken}
            onSubmit={onSecurityCodeSubmit}
            isLoading={isLoading}
            errorMessage={errorMessage}
            remainingAttempts={remainingAttempts}
          />
        )}

        {authenticationStep === AuthenticationStep.AUTHENTICATION_BLOCKED && (
          <AuthenticationLocked onEnd={onAuthenticationBlockedEnd} />
        )}

        {authenticationStep === AuthenticationStep.TRUST_BROWSER && (
          <TrustBrowser onClose={onTrustBrowserEnd} />
        )}
      </NominalLayout>
    </Page>
  );
};

export default LoginPage;
