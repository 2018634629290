import { CoreAppItem, LabelListItem } from '../value-object/Item';
import { AnswerType } from '../value-object/AnswerType';
import { StatusType } from '../value-object/StatusType';
import { Subject } from '../value-object/Subject';

export enum DegreeType {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum QualificationType {
  DIAGNOSTIC = 'DIAGNOSTIC',
  RISK = 'RISK',
  VACCINE = 'VACCINE',
  SUSPICION = 'SUSPICION',
  THERAPEUTIC_FAILURE = 'THERAPEUTIC_FAILURE',
  STABLE = 'STABLE',
  MONITORED = 'MONITORED',
  ON_TREATMENT = 'ON_TREATMENT',
  AGGRAVATE = 'AGGRAVATE',
  CURED = 'CURED',
  CUSTOMER = 'CUSTOMER',
}

export enum EditionFormat {
  PERIOD = 'PERIOD',
  DATE = 'DATE',
  DEGREE = 'DEGREE',
  PRESENCE = 'PRESENCE',
  FREQUENCY = 'FREQUENCY',
  MEASURE = 'MEASURE',
  INHERENT = 'INHERENT',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  QUALITATIVE_RESULT = 'QUALITATIVE_RESULT',
  FORMAT_PASSAGE = 'FORMAT_PASSAGE',
}

export interface ObservationType {
  id: string;
  label: string;
  evolutionOrder: number | null;
  stored: boolean;
  normal: boolean;
  evolves: boolean;
  coreAppId: number;
}

export interface Description {
  id: string;
  label: string;
  degree: DegreeType;
  priority: number;
  qualification: QualificationType;
  observationType: ObservationType;
  editionFormat: EditionFormat;
  evolvesFrom: null | { id: string; label: string; coreAppId: number };
  // bb03Label: string;
  // bb03Topic: number;
  // bb03Type: number;
}

export interface PatientObservation {
  id: string;
  answerType: AnswerType;
  status: StatusType;
  date: Date;
  startDate: Date;
  endDate: Date;
  score: number;
  familyRelationship: CoreAppItem;
  observationDescription: Description;
  subject: Subject;
  crmSelector: string;
  lastModifiedBy: string;
}

export interface Observation {
  observationDescription: Description;
  subject: Subject;
  familyRelationship: LabelListItem | null;
}

export const getDegreeLabel = (degree: DegreeType) => {
  if (degree === DegreeType.LOW) return 'Faible';
  if (degree === DegreeType.MEDIUM) return 'Modéré';
  if (degree === DegreeType.HIGH) return 'Fort';
  return '-';
};
