import { useMemo } from 'react';
import { CellProps } from 'react-table';

import TableActions, { TableAction } from 'src/components/common/Table/Actions';
import DateField from 'src/components/common/Table/DateField';
import TextField from 'src/components/common/Table/TextField';
import { UserRole } from 'src/domains/user/entity/common';
import authenticationService from 'src/services/authentication';
import { AuthorField } from '../../../helpers/table/AuthorField';
import FactValueField from '../../../helpers/table/FactValueField';
import { HandleClick, TableActionParams } from '../../../helpers/table/use-table-actions';
import { IFactPreviewVM } from '../../FactPreviewVM';
import { FIELDS } from '../Create/CreationForm/fields';

interface useTableColumnProps extends TableActionParams<IFactPreviewVM> {
  handleDeleteClick: HandleClick<IFactPreviewVM>;
}

export const useTableColumn = ({
  handleDeleteClick,
  handleEditClick,
  handleHistoryClick,
}: useTableColumnProps): any[] => {
  const currentUser = authenticationService.getUser();
  const fields = useMemo(() => FIELDS.map(f => f.factTypeId), []);

  return useMemo(
    () => [
      {
        Header: 'Date de déclaration',
        id: 'creationDate',
        accessor: 'creationDate',
        sortType: 'datetime',
        sortable: true,
        width: '15%',
        Cell: (props: CellProps<IFactPreviewVM>) => {
          const date = props.cell.value;

          return <DateField date={date} />;
        },
      },
      {
        Header: 'Statistique déclarée par le patient',
        accessor: 'label',
        width: '45%',
        Cell: (props: CellProps<IFactPreviewVM>) => {
          const label = props.cell.value;

          return <TextField label={label} variant={'h5'} />;
        },
      },
      {
        Header: 'Modification',
        accessor: 'answerType',
        width: '20%',
        Cell: (props: CellProps<IFactPreviewVM>) => {
          const label = props.cell.value;

          return <AuthorField value={label} />;
        },
      },
      {
        Header: 'Valeur',
        accessor: (row: IFactPreviewVM) => {
          if (row.isQuantifiable) return row.value;
          return row.status;
        },
        width: '15%',
        sortable: true,
        Cell: (props: CellProps<IFactPreviewVM>) => {
          const value = props.row.original.value;
          const status = props.row.original.status;
          const measurementUnit = props.row.original.measurementUnit;
          const isQuantifiable = props.row.original.isQuantifiable || false;

          return (
            <FactValueField
              status={status}
              isQuantifiable={isQuantifiable}
              value={value}
              measurementUnit={measurementUnit}
            />
          );
        },
      },
      {
        Header: '',
        accessor: 'id',
        width: '5%',
        align: 'right',
        Cell: (props: CellProps<IFactPreviewVM>) => {
          const vm = props?.cell?.row?.original;

          const actions: TableAction<any>[] = useMemo(() => {
            let actions = [];

            if (
              handleEditClick &&
              (currentUser?.role === UserRole.ORG_MANAGER || !fields.includes(vm.typeId))
            )
              actions.push({
                label: 'Mettre à jour',
                method: () => handleEditClick && handleEditClick(vm),
              });

            if (handleHistoryClick)
              actions.push({
                label: 'Historique',
                method: () => handleHistoryClick && handleHistoryClick(vm),
              });

            if (handleDeleteClick)
              actions.push({
                label: 'Supprimer',
                method: () => handleDeleteClick && handleDeleteClick(vm),
              });

            return actions;
          }, [vm]);

          return <TableActions actions={actions} />;
        },
      },
    ],
    [currentUser?.role, fields, handleDeleteClick, handleEditClick, handleHistoryClick],
  );
};
