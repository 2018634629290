import { makeStyles, Theme } from '@material-ui/core/styles';

interface ModalStylesProps {}

export default makeStyles<Theme, ModalStylesProps>(() => ({
  paper: {
    padding: 30,
    borderRadius: 20,
  },
  closeButton: {
    minWidth: 0,
    minHeight: 'auto',
    padding: 0,
    marginTop: 2,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}));
