import { FunctionComponent } from 'react';

import CreationForm from './CreationForm';

interface StatisticCreationModalContentProps {
  onClose: () => void;
  onSubmit: () => void;
}

export const StatisticCreationModalContent: FunctionComponent<StatisticCreationModalContentProps> =
  ({ onSubmit }) => {
    return <CreationForm onClose={onSubmit} />;
  };

export default StatisticCreationModalContent;
