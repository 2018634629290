import { useEffect, useState } from 'react';

import authenticationService from 'src/services/authentication';
import { useGenerateTokenMutation } from '../../token-api';
import { TokensResponse } from '../../types';

export type UseGetTokensValue = {
  generateToken: (patientId?: string) => void;
  data: TokensResponse | undefined;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  /** token loaded in storage, will be used in engine queries */
  tokenLoaded: boolean;
  error: any;
};

const useGenerateToken = (): UseGetTokensValue => {
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [generateToken, { data, isLoading, isError, isSuccess, error }] =
    useGenerateTokenMutation();

  useEffect(() => {
    if (!isLoading && !isError && isSuccess && data) {
      authenticationService.setOrUpdateAccessToken(data.token);
      setTokenLoaded(true);
    }
  }, [isLoading, isError, isSuccess, data]);

  return {
    generateToken,
    tokenLoaded,
    data,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useGenerateToken;
