export enum FieldFactType {
  systolicBp = 'FaitExport55519743I3341869',
  diastolicBp = 'FaitExport55521638I8393622',
  weight = 'FaitExport55514062I8228951',
  emptyStomacGlycemia = 'FaitExport55516254I497718',
  postPrandialeGlycemia = 'FaitExport55516888I7634566',
  waistSize = 'FaitExport55517991I5410621',
  cholesterol = 'FaitExport55654810I3041862',
  hdl = 'FaitExport55519499I2087942',
  ldl = 'FaitExport55520898I4095853',
  triglycerides = 'FaitExport55654940I6776103',
}
