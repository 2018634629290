import { CoreAppItem } from '../value-object/Item';
import { Subject } from '../value-object/Subject';
import { EventType } from './event';
import { FactType } from './fact';

export enum MedicalRecordType {
  OBSERVATION = 'OBSERVATION',
  FACT = 'FACT',
  EVENT = 'EVENT',
}

export interface EventAndFact {
  type: EventType | FactType;
  familyRelationship?: CoreAppItem;
  subject: Subject;
  recordType: MedicalRecordType;
}

export const isEventType = (type: MedicalRecordType) => type === MedicalRecordType.EVENT;
export const isObservationType = (type: MedicalRecordType) =>
  type === MedicalRecordType.OBSERVATION;
