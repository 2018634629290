import { FunctionComponent } from 'react';
import { Typography } from '@material-ui/core';

import {
  STATUS_TYPE,
  StatusType,
} from '../../../../../../domains/medical-record/value-object/StatusType';

type StatusFieldProps = {
  status?: StatusType;
};

const StatusField: FunctionComponent<StatusFieldProps> = ({ status }) => {
  if (status === STATUS_TYPE.PRESENT)
    return (
      <Typography color={'error'} variant={'h5'}>
        {'Présent'}
      </Typography>
    );

  if (status === STATUS_TYPE.ABSENT)
    return (
      <Typography color={'primary'} variant={'h5'}>
        {'Absent'}
      </Typography>
    );

  return (
    <Typography color={'primary'} variant={'body1'}>
      {'-'}
    </Typography>
  );
};

export default StatusField;
