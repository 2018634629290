import { MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps } from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import React, { useCallback, useMemo, useState } from 'react';

export interface SelectOption {
  value?: string | number;
  label?: string;
}

interface SelectProps extends MuiSelectProps {
  options: SelectOption[];
}

const placeholderValue: string = '';

const Select = ({ value, onChange, options = [], placeholder, ...rest }: SelectProps) => {
  const [currentValue, setCurrentValue] = useState(value ?? placeholderValue);

  const handleOnChange: SelectInputProps['onChange'] = useCallback(
    (event, child) => {
      if (onChange) onChange(event, child);
      setCurrentValue(event?.target?.value as string);
    },
    [onChange],
  );

  const defaultValue = useMemo(() => value || options[0]?.value, [options, value]);

  return (
    <MuiSelect
      value={currentValue}
      defaultValue={defaultValue}
      onChange={handleOnChange}
      displayEmpty
      {...rest}
    >
      {placeholder && <MenuItem value={placeholderValue}>{placeholder}</MenuItem>}
      {options.map(({ value, label }: SelectOption, index) => (
        <MenuItem key={`option-${index}`} value={value}>
          {label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default Select;
