import { useMemo } from 'react';

import useGetPatientManifestationHistoryUseCase from 'src/domains/medical-record/use-case/observation/manifestation/useGetPatientManifestationHistoryUseCase';
import { IManifestationPreviewVM, bindFromEntity } from '../ManifestationPreviewVM';

interface UseManifestationHistoryParams {
  observationDescriptionId: string;
  familyRelationshipId?: string;
}

const useManifestationHistory = (params: UseManifestationHistoryParams) => {
  const { data = [], isLoading, error } = useGetPatientManifestationHistoryUseCase(params);

  const list: IManifestationPreviewVM[] = useMemo(() => data.map(bindFromEntity), [data]);

  return {
    isLoading,
    list,
    error,
  };
};

export default useManifestationHistory;
