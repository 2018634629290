import { SubjectType } from '../../../value-object/SubjectType';
import { LatestFactDTO } from '../../../dto';
import { Fact, PatientFactAndType } from '../../../entity/fact';

export const selectAnalysesFromResponse = (
  response: LatestFactDTO[] = [],
): PatientFactAndType[] => {
  const filteredLatestFactDTO: LatestFactDTO[] = response.filter(
    ({ subject }) => subject.label === SubjectType.PARAMETRE,
  );

  return filteredLatestFactDTO.map(latestFactDTO => ({
    fact: latestFactDTO.patientFact,
    type: latestFactDTO.factType,
  }));
};

export const selectAnalysisFacts = (facts: Fact[]): Fact[] =>
  facts.filter(fact => fact.subject.label === SubjectType.PARAMETRE);
