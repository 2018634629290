import React, { FunctionComponent, useMemo } from 'react';

import { IAntecedentPreviewVM } from '../AntecedentPreviewVM';
import { useHistoryTableColumn } from './columns';
import HistoryModal from '../../Modals/HistoryModal';
import useAntecedentHistory from './hook';

interface AntecedentHistoryModalProps {
  antecedent: IAntecedentPreviewVM;
  onClose: () => void;
  open: boolean;
}

export const AntecedentHistoryModal: FunctionComponent<AntecedentHistoryModalProps> = ({
  antecedent,
  onClose: handleOnClose,
  open,
}) => {
  const { isLoading, list } = useAntecedentHistory({
    familyRelationshipId: antecedent?.familyRelationship?.id,
    recordType: antecedent.type,
    relatedTemplateElementId: antecedent.id,
  });

  const title = useMemo(() => "Historique de l'antécédent", []);

  const columns = useHistoryTableColumn();

  return (
    <>
      <HistoryModal
        columns={columns}
        data={list}
        onClose={handleOnClose}
        open={open}
        isLoading={isLoading}
        title={title}
      />
    </>
  );
};

export default AntecedentHistoryModal;
