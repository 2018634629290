import { FunctionComponent, memo } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

interface TextFieldProps extends TypographyProps {
  label?: string;
}

const TextField: FunctionComponent<TextFieldProps> = memo(
  ({ label, variant = 'body1', color = 'primary', ...rest }) => {
    return (
      <Typography variant={variant} color={color} {...rest}>
        {label ?? '-'}
      </Typography>
    );
  },
);

export default TextField;
