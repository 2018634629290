import { useCallback } from 'react';

import {
  CreateMultiplePatientFactRequestDTO,
  useCreateMultiplePatientFactMutation,
} from 'src/domains/medical-record/repository';
import { StatisticCreationFormInterface } from '.';
import { FIELDS } from './fields';
import { AnswerType } from 'src/domains/medical-record/value-object/AnswerType';
import { STATUS_TYPE } from 'src/domains/medical-record/value-object/StatusType';
import { FieldFactType } from 'src/config/fact-types';

const convertMgdlToGl = (value: number) => value / 100;
const convertMgdlToMmol = (value: number) => value / 18;

const useAddStatistics = () => {
  const [create, { isLoading, isSuccess, error }] = useCreateMultiplePatientFactMutation();

  const add = useCallback(
    (payload: StatisticCreationFormInterface) => {
      const body = Object.entries(payload)
        .filter(([key]) => key !== 'timeElapsedAfterMeal')
        .map<CreateMultiplePatientFactRequestDTO['body'][number]>(([key, value]) => {
          const field = FIELDS.find(field => field.key === key);
          if (!field) {
            throw new Error(`Field ${key} not found on FIELDS from fields.ts`);
          }

          let factValue = Number(value);
          if (field.unit.id === 'GramPerLiter') {
            factValue = convertMgdlToGl(Number(value));
          } else if (field.unit.id === 'MillimolePerLiter') {
            factValue = convertMgdlToMmol(Number(value));
          }

          let factTypeId = field.factTypeId;

          // glycemia fact depends of timeElapsedAfterMeal value
          if (field.key === 'glycemia') {
            const timeElapsedAfterMeal = Number(payload['timeElapsedAfterMeal']); // should be present according to validation schema.
            factTypeId =
              timeElapsedAfterMeal > 480
                ? FieldFactType.emptyStomacGlycemia
                : FieldFactType.postPrandialeGlycemia;
          }

          return {
            startDate: new Date(),
            endDate: new Date(),
            factTypeId: factTypeId,
            status: STATUS_TYPE.PRESENT,
            answerType: AnswerType.DOCTOR,
            value: factValue,
            measurementUnit: {
              id: field.unit.id,
            },
          };
        });

      const dto: CreateMultiplePatientFactRequestDTO = {
        body,
      };
      create(dto);
    },
    [create],
  );

  return {
    add,
    isLoading,
    isSuccess,
    error,
  };
};

export default useAddStatistics;
