import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { LoginError } from 'src/domains/authentication/types';
import { NavigationRoute } from 'src/routes/navigationRoute';
import authenticationService from 'src/services/authentication';
import { ValidationCode } from '../../components/authentication/SecurityCodeForm/schema';
import useLogin from '../../domains/authentication/hooks/useLogin';
import { LoginPassword } from './CredentialsForm/schema';

interface LocationState {
  from: {
    pathname: string;
  };
}

export enum AuthenticationStep {
  AUTHENTICATION_BLOCKED = 'AUTHENTICATION_BLOCKED',
  DEFAULT = 'DEFAULT',
  SECURITY_CODE = 'SECURITY_CODE',
  TRUST_BROWSER = 'TRUST_BROWSER',
  HOME = 'HOME',
}

const useAuthenticate = () => {
  const [login, { isLoading, isSuccess, isError, error }] = useLogin();
  const history = useHistory<LocationState>();
  const location = useLocation<LocationState>();

  const [is2FAEnabled, setIs2FAEnabled] = useState<boolean>(false);
  const [authenticationStep, setAuthenticationStep] = useState(AuthenticationStep.DEFAULT);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [credentials, setCredentials] = useState<LoginPassword | undefined>();

  const isTrustBrowserEnabled: boolean = useMemo(() => !!authenticationService.getBTrustMe(), []);
  const [isAuthenticationBlocked, setIsAuthenticationBlocked] = useState<boolean>(
    !!authenticationService.getRetryAfter(),
  );

  const loginError = useMemo<LoginError | undefined>(() => {
    if (isError && error) {
      if ('data' in error) {
        return error.data as LoginError;
      }
    }
    return undefined;
  }, [error, isError]);

  const accessToken: string | undefined = useMemo(
    () => loginError?.errorDetails?.accessToken,
    [loginError?.errorDetails?.accessToken],
  );

  const remainingAttempts: number | undefined = useMemo(
    () => loginError?.errorDetails?.remainingAttempts,
    [loginError?.errorDetails?.remainingAttempts],
  );

  const onCredentialFormSubmit = useCallback(
    (formValues: LoginPassword) => {
      setCredentials(formValues);
      setErrorMessage('');
      login({ ...formValues });
    },
    [login],
  );

  const onSecurityCodeSubmit = useCallback(
    (validation: ValidationCode) => {
      if (credentials && validation?.code) {
        setErrorMessage('');
        // TODO: If we want to enable 2FA
        // login({ ...credentials, code: String(validation?.code) });
      }
    },
    [credentials], // add login if it's used
  );

  const onAuthenticationBlockedEnd = useCallback(() => {
    authenticationService.clearRetryAfter();
    setIsAuthenticationBlocked(false);
  }, []);

  const onTrustBrowserEnd = useCallback(
    () => setAuthenticationStep(AuthenticationStep.HOME),
    [setAuthenticationStep],
  );

  useEffect(() => {
    if (loginError) {
      const errorCode = loginError.errorCode;
      const errorDetails = loginError.errorDetails;

      if (errorDetails) {
        if (
          (errorCode === 'account.locked' ||
            errorCode === 'security.code.request.rate.limit.exceeded') &&
          errorDetails?.retryAfter
        ) {
          authenticationService.setRetryAfter(new Date(errorDetails.retryAfter));
          setIsAuthenticationBlocked(true);
        }

        if (errorCode === 'bad.credentials') {
          setErrorMessage(`Identifiant ou mot de passe incorrect`);
        }

        if (errorCode === 'bad.security.code' || errorCode === 'security.code.not.found') {
          setErrorMessage('Code de validation incorrect');
        }

        if (errorCode === 'security.code.required') {
          setIs2FAEnabled(true);
          setAuthenticationStep(AuthenticationStep.SECURITY_CODE);
        }
      } else {
        setErrorMessage('Identifiant ou mot de passe incorrect');
      }
    }
  }, [loginError, setAuthenticationStep]);

  useEffect(() => {
    if (!isLoading && !isError && isSuccess) {
      if (is2FAEnabled && !isTrustBrowserEnabled) {
        setAuthenticationStep(AuthenticationStep.TRUST_BROWSER);
      } else {
        setAuthenticationStep(AuthenticationStep.HOME);
      }
    }
  }, [isLoading, isSuccess, isError, is2FAEnabled, isTrustBrowserEnabled, setAuthenticationStep]);

  useEffect(() => {
    if (isAuthenticationBlocked) {
      setAuthenticationStep(AuthenticationStep.AUTHENTICATION_BLOCKED);
    }
  }, [isAuthenticationBlocked, setAuthenticationStep]);

  useEffect(() => {
    if (
      !isAuthenticationBlocked &&
      authenticationStep === AuthenticationStep.AUTHENTICATION_BLOCKED
    ) {
      setAuthenticationStep(AuthenticationStep.DEFAULT);
    }
  }, [authenticationStep, isAuthenticationBlocked, setAuthenticationStep]);

  useEffect(() => {
    if (authenticationStep === AuthenticationStep.HOME) {
      const { from } = location.state || { from: { pathname: NavigationRoute.HOME } };
      history.replace(from);
    }
  }, [authenticationStep, history, location.state]);

  return useMemo(
    () => ({
      accessToken,
      credentials,
      errorMessage,
      isLoading,
      isSuccess,
      isError,
      login,
      authenticationStep,
      onAuthenticationBlockedEnd,
      onCredentialFormSubmit,
      onSecurityCodeSubmit,
      onTrustBrowserEnd,
      remainingAttempts,
    }),
    [
      accessToken,
      authenticationStep,
      credentials,
      errorMessage,
      isError,
      isLoading,
      isSuccess,
      login,
      onAuthenticationBlockedEnd,
      onCredentialFormSubmit,
      onSecurityCodeSubmit,
      onTrustBrowserEnd,
      remainingAttempts,
    ],
  );
};

export default useAuthenticate;
