import React, { FunctionComponent, useCallback, useEffect } from 'react';

import { DeleteRecordRequestDTO } from 'src/domains/medical-record/repository';
import { ModalProps } from 'src/components/common/Modal';
import DeleteModal from '../../Modals/DeleteModal';
import useDeletePatientAntecedentUseCase from 'src/domains/medical-record/use-case/antecedent/useDeletePatientAntecedentUseCase';
import { MedicalRecordType } from 'src/domains/medical-record/entity';

interface AntecedentDeletionModalProps extends ModalProps {
  title: string;
  type: MedicalRecordType;
  recordId: string;
}

const AntecedentDeletionModal: FunctionComponent<AntecedentDeletionModalProps> = ({
  onClose,
  recordId,
  title,
  type,
  ...modalProps
}) => {
  const { deleteAntecedent, isLoading, isSuccess } = useDeletePatientAntecedentUseCase(type);

  const onDelete = useCallback(() => {
    const dto: DeleteRecordRequestDTO = {
      recordId,
    };

    deleteAntecedent(dto);
  }, [deleteAntecedent, recordId]);

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess, onClose]);

  return (
    <DeleteModal
      title={title}
      onDelete={onDelete}
      isLoading={isLoading}
      onClose={onClose}
      {...modalProps}
    />
  );
};

export default AntecedentDeletionModal;
