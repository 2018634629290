import { intervalToDuration } from 'date-fns';
import { FunctionComponent, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { useCountdown } from 'rooks';

import { Duration } from 'src/types/duration';
import { toDurationLabel } from 'src/helpers/format/duration';
import authenticationService from 'src/services/authentication';
import NominalContainer from 'src/components/common/NominalContainer';
import useStyles from './styles';

type AuthenticationLockedProps = {
  onEnd: () => void;
};

const AuthenticationLocked: FunctionComponent<AuthenticationLockedProps> = ({ onEnd }) => {
  const classes = useStyles();
  const retryAfter = authenticationService.getRetryAfter();

  const countdown = useCountdown(new Date(retryAfter), {
    interval: 1000,
    onEnd,
  });

  const remainingTime = useMemo(() => {
    const remaining = intervalToDuration({
      start: 0,
      end: 1000 * countdown,
    });

    return toDurationLabel(remaining as Duration);
  }, [countdown]);

  return (
    <NominalContainer title={'Compte bloqué'}>
      <div className={classes.processDetail}>
        <Typography color={'primary'} variant={'h4'}>
          Votre compte a été verrouillé après trop de tentatives de connexion infructueuses.
        </Typography>
        {remainingTime && (
          <div className={classes.countdownMessageContainer}>
            <Typography color={'primary'} variant={'body1'}>
              Vous serez redirigé vers la page de connexion dans{' '}
              <span className={classes.bold}>{remainingTime}</span>
            </Typography>
          </div>
        )}
      </div>
    </NominalContainer>
  );
};

export default AuthenticationLocked;
