export const reportTitle = 'Compte rendu de la consultation Mon Bilan Cardio';
export const isCardiacAssessmentQuestion =
  'La consultation qui a eu lieu a-t-elle bien porté sur la prévention cardiovasculaire (Mon Bilan Cardio) ?';
export const hasReferringDoctorQuestion = 'Le patient a-t-il un médecin traitant ?';
export const recommendationQuestion =
  'Quel message donnez-vous au patient vis-à-vis de son médecin traitant : lui recommandez-vous de le consulter pour discuter avec lui des risques identifiés dans Mon Bilan Cardio (réponse obligatoire) ?';
export const hasConsultationRecommendationWithSpecialistQuestion =
  'Avez-vous recommandé au patient de consulter un spécialiste ?';
export const hasAdditionalTestsRecommendationQuestion =
  'Avez-vous recommandé au patient de réaliser des examens complémentaires ?';

export const lifestyleActionsLabel =
  'Les habitudes de vie prioritaires sur lesquelles le patient est le plus motivé pour agir sont les suivantes :';
export const lifestyleActionsPriorityLabel = 'Priorité';
export const lifestyleActionsFirstPriorityLabel = 'Priorité 1 :';
export const lifestyleActionsSecondPriorityLabel = 'Priorité 2 :';
export const lifestyleActionsThirdPriorityLabel = 'Priorité 3 :';
