export enum SubjectType {
  ABSENCE = 'SubjectAbsence',
  ACTE = 'SubjectActe',
  ACTE_THERAPEUTIQUE = 'SubjectActeTherapeutique',
  ACTE_PARACLINIQUE = 'SubjectActeParaclinique',
  ACTECLINIQUE = 'SubjectActeClinique',
  ACTION = 'SubjectAction',
  AFFECTION = 'SubjectAffection',
  AGE = 'SubjectAge',
  ALIMENTATION = 'SubjectAlimentation',
  ALLERGÈNE = 'SubjectAllergene',
  ANIMAL = 'SubjectAnimal',
  APPROCHEMEDICALE = 'SubjectApprocheMedicale',
  BIODISPONIBILITÉ = 'SubjectBiodisponibilite',
  BOOLEANCONCEPT = 'SubjectBooleanConcept',
  BUILDINGBLOCKDEFINITION = 'SubjectBuildingBlockDefinition',
  CATEGORIE = 'SubjectCategorie',
  CHAPITREKNOWLEDGE = 'SubjectChapitreKnowledge',
  CONDITIONSDECONSERVATION = 'SubjectConditionsDeConservation',
  CONSULTATION = 'SubjectConsultation',
  CONTEXTE = 'SubjectContexte',
  CONTEXTEDECONSERVATION = 'SubjectContexteDeConservation',
  CONTEXTEDEPARAMETRE = 'SubjectContexteDeParametre',
  CONTEXTEDEPRISE = 'SubjectContexteDePrise',
  COREAPPIDENTIFIANTFIXE = 'SubjectCoreAppIDentifiantFixe',
  COREAPPIDENTIFIANTFIXEFILS = 'SubjectCoreAppIDentifiantFixeFils',
  DCI = 'SubjectDci',
  DUREE = 'SubjectDuree',
  DUREEDEXPIRATION = 'SubjectDureeDExpiration',
  DUREEDEXPIRATIONFIXE = 'SubjectDureeDExpirationFixe',
  DUREEFIXE = 'SubjectDureeFixe',
  DUREELIMITEDECONSERVATION = 'SubjectDureeLimiteDeConservation',
  DURÉEMAXIMALEDEPRISE = 'SubjectDureeMaximaleDePrise',
  DUREEMAXIMUM = 'SubjectDureeMaximum',
  DUREEMINIMUM = 'SubjectDureeMinimum',
  ELEMENTBIOLOGIQUE = 'SubjectElementBiologique',
  EQUIPEMENT = 'SubjectEquipement',
  ETABLISSEMENT = 'SubjectEtablissement',
  ETAT = 'SubjectEtat',
  ETATDEPARAMETRE = 'SubjectEtatDeParametre',
  ETATPHYSIOLOGIQUE = 'SubjectEtatPhysiologique',
  ETHNIE = 'SubjectEthnie',
  FACTEURENVIRONNEMENTAL = 'SubjectFacteurEnvironnemental',
  FORMAT = 'SubjectFormat',
  FORME_PHARMACEUTIQUE = 'SubjectFormePharmaceutique',
  FORMULE = 'SubjectFormule',
  FORMULEFIXE = 'SubjectFormuleFixe',
  FREQUENCE = 'SubjectFrequence',
  FREQUENCEFIXE = 'SubjectFrequenceFixe',
  FREQUENCEMAXIMUM = 'SubjectFrequenceMaximum',
  FREQUENCEMINIMUM = 'SubjectFrequenceMinimum',
  GENE = 'SubjectGene',
  GERME = 'SubjectGerme',
  GROUPEDEFACTEUR = 'SubjectGroupeDeFacteur',
  HANDICAP = 'SubjectHandicap',
  HYGIENEDEVIE = 'SubjectHygieneDeVie',
  IMMUNITENONACQUISE = 'SubjectImmuniteNonAcquise',
  INDICATEUR = 'SubjectIndicateur',
  INDICATEURAUTOMATIQUE = 'SubjectIndicateurAutomatique',
  INFORMATION = 'SubjectInformation',
  INTENSITE = 'SubjectIntensite',
  LABORATOIREPHARMACEUTIQUE = 'SubjectLaboratoirePharmaceutique',
  LISTE_DE_PRESCRIPTION = 'SubjectListeDePrescription',
  LOCALISATIONGEOGRAPHIQUE = 'SubjectLocalisationGeographique',
  LOCALISATIONSPATIALE = 'SubjectLocalisationSpatiale',
  MANIFESTATION = 'SubjectManifestation',
  MECANISMEPHYSIOLOGIQUE = 'SubjectMecanismePhysiologique',
  MECANISMEPHYSIOPATHOLOGIQUE = 'SubjectMecanismePhysiopathologique',
  MEDICAMENT = 'SubjectMedicament',
  METHODEEXPERIMENTALE = 'SubjectMethodeExperimentale',
  MODEDADMINISTRATION = 'SubjectModeDAdministration',
  MODEDEVIE = 'SubjectModeDeVie',
  MODEDOBTENTION = 'SubjectModeDObtention',
  MODEOPERATOIRE = 'SubjectModeOperatoire',
  MODETRANSMISSION = 'SubjectModeTransmission',
  MODETRANSMISSIONGENETIQUE = 'SubjectModeTransmissionGenetique',
  NATUREDELECHANTILLON = 'SubjectNatureDeLEchantillon',
  NIVEAU = 'SubjectNiveau',
  NOMBREDANNEEDETUDE = 'SubjectNombreDAnneeDEtude',
  NOMDECONSTAT = 'SubjectNomDeConstat',
  NOMDEGROUPE = 'SubjectNomDeGroupe',
  NOMDEGROUPEFILS = 'SubjectNomDeGroupeFils',
  NOMDEPRESCRIPTION = 'SubjectNomDePrescription',
  NOMDEPRISEENCHARGE = 'SubjectNomDePriseEnCharge',
  NOMDEQUESTION = 'SubjectNomDeQuestion',
  NOMDEQUESTIONFIXE = 'SubjectNomDeQuestionFixe',
  NOMDESOUSOBJECTIF = 'SubjectNomDeSousObjectif',
  NOMDEVENEMENTCLASSIQUE = 'SubjectNomDEvenementClassique',
  NOMDEVENEMENTCLASSIQUEFILS = 'SubjectNomDEvenementClassiqueFils',
  NOMDEVENEMENTEVALUATION = 'SubjectNomDEvenementEvaluation',
  NOMDEVENEMENTTHERAPEUTIQUE = 'SubjectNomDEvenementTherapeutique',
  NOTE = 'SubjectNote',
  NUMERODEFORMAT = 'SubjectNumeroDeFormat',
  NUMERODEFORMATFIXE = 'SubjectNumeroDeFormatFixe',
  NUMERODORDRE = 'SubjectNumeroDOrdre',
  NUMERODORDREFIXE = 'SubjectNumeroDOrdreFixe',
  OBJECTIF = 'SubjectObjectif',
  OPERATEUR = 'SubjectOperateur',
  OPERATEURTEMPORELLEFIXE = 'SubjectOperateurTemporelleFixe',
  ORDRE = 'SubjectOrdre',
  ORDREFIXE = 'SubjectOrdreFixe',
  ORDREFIXEBIS = 'SubjectOrdreFixeBis',
  ORGANE = 'SubjectOrgane',
  ORGANISME = 'SubjectOrganisme',
  PARAMETRE = 'SubjectParametre',
  PARAMETREDEFERMETURE = 'SubjectParametreDeFermeture',
  PARAMETREDEFERMETUREFILS = 'SubjectParametreDeFermetureFils',
  PARAMETREDESUBSTITUT = 'SubjectParametreDeSubstitut',
  PATHOLOGIE = 'SubjectPathologie',
  PERIODE = 'SubjectPeriode',
  POIDS = 'SubjectPoids',
  PONDERATIONDEFACTEUR = 'SubjectPonderationDeFacteur',
  POSITIONTEMPORELLE = 'SubjectPositionTemporelle',
  POSITIONTEMPORELLEFIXE = 'SubjectPositionTemporelleFixe',
  PRESCRIPTION = 'SubjectPrescription',
  PRIORITE = 'SubjectPriorite',
  PRIX = 'SubjectPrix',
  PROFILCONSOMMATEUR = 'SubjectProfilConsommateur',
  PRONOSTICVITALA5ANS = 'SubjectPronosticVitalA5Ans',
  PROTOCOLE = 'SubjectProtocole',
  QUANTITE = 'SubjectQuantite',
  QUANTITEFIXE = 'SubjectQuantiteFixe',
  QUANTITEMAXIMUM = 'SubjectQuantiteMaximum',
  QUANTITEMINIMUM = 'SubjectQuantiteMinimum',
  QUESTIONNAIRE = 'SubjectQuestionnaire',
  RANG = 'SubjectRang',
  RANGDEPROFILMEDICAL = 'SubjectRangDeProfilMedical',
  RANGDEREPONSE = 'SubjectRangDeReponse',
  REACTION = 'SubjectReaction',
  RELATIVITEDEFACTEUR = 'SubjectRelativiteDeFacteur',
  RELATIVITEELEMENT = 'SubjectRelativiteElement',
  REPERE = 'Repere',
  RÔLE = 'SubjectRole',
  SALAIREMOYEN = 'SubjectSalaireMoyen',
  SCORE = 'SubjectScore',
  SCOREAUTOMATIQUE = 'SubjectScoreAutomatique',
  SCOREDINFERENCE = 'SubjectScoreDInference',
  SCOREMAXIMUM = 'SubjectScoreMaximum',
  SCOREMINIMUM = 'SubjectScoreMinimum',
  SEXE = 'SubjectSexe',
  SIGNE = 'SubjectSigne',
  SPECIALITEMEDICALE = 'SubjectSpecialiteMedicale',
  SPECIALITEMEDICALEFIXE = 'SubjectSpecialiteMedicaleFixe',
  STATUS = 'SubjectStatus',
  STATUSDEFAIT = 'SubjectStatusDeFait',
  SYNDROME = 'SubjectSyndrome',
  SYSTEMEPHYSIOLOGIQUE = 'SubjectSystemePhysiologique',
  TAUXDEREMBOURSEMENT = 'SubjectTauxDeRemboursement',
  TEMPERATUREDECONSERVATION = 'SubjectTemperatureDeConservation',
  THEMATIQUE = 'SubjectThematique',
  THEMATIQUEELEARNING = 'SubjectThematiqueELearning',
  TRADUCTIONNUMERIQUE = 'SubjectTraductionNumerique',
  TRANCHEDAGE = 'SubjectTrancheDAge',
  TRANCHEDEPOIDS = 'SubjectTrancheDePoids',
  TYPE_DE_FACTEUR_DE_COMPLICATION = 'SubjectTypeDeFacteurDeComplication',
  TYPEDACTION = 'SubjectTypeDAction',
  TYPEDACTIONMECANISME = 'SubjectTypeDActionMecanisme',
  TYPEDACTIONPARAMETRE = 'SubjectTypeDActionParametre',
  TYPEDAFFECTION = 'SubjectTypeDAffection',
  TYPEDECARNETDESANTE = 'SubjectTypeDeCarnetDeSante',
  TYPEDECOMPOSANT = 'SubjectTypeDeComposant',
  TYPEDECONSTAT = 'SubjectTypeDeConstat',
  TYPEDECONSTATFILS = 'SubjectTypeDeConstatFils',
  TYPEDECONSULTATION = 'SubjectTypeDeConsultation',
  TYPEDEGROUPEDEFACTEUR = 'SubjectTypeDeGroupeDeFacteur',
  TYPEDEPERTURBATION = 'SubjectTypeDePerturbation',
  TYPEDEPRESCRIPTION = 'SubjectTypeDePrescription',
  TYPEDEPRESCRIPTIONFILS = 'SubjectTypeDePrescriptionFils',
  TYPEDEREACTION = 'SubjectTypeDeReaction',
  TYPEDERELATIONPROFIL = 'SubjectTypeDeRelationProfil',
  TYPEDERELATIVITE = 'SubjectTypeDeRelativite',
  TYPEDESCORE = 'SubjectTypeDeScore',
  TYPEDEVENEMENT = 'SubjectTypeDEvenement',
  TYPEDIMMUNITENONACQUISE = 'SubjectTypeDImmuniteNonAcquise',
  TYPEDINDICATEUR = 'SubjectTypeDIndicateur',
  TYPEDINDICATION = 'SubjectTypeDIndication',
  TYPEDINDICATIONEXPORT = 'SubjectTypeDIndicationExport',
  TYPEPARAMETRE = 'SubjectTypeParametre',
  UNITEDEMESURE = 'SubjectUniteDeMesure',
  UNITEDEMESUREFIXE = 'SubjectUniteDeMesureFixe',
  UNITEDEMESUREFIXEFILS = 'SubjectUniteDeMesureFixeFils',
  VALEUR_QUALITATIVE = 'SubjectValeurQualitative',
  VALEURBASSE = 'SubjectValeurBasse',
  VALEURDECONDITIONDAPPLICATION = 'SubjectValeurDeConditionDApplication',
  VALEURDECONSTAT = 'SubjectValeurDeConstat',
  VALEURDEPRISEENCHARGE = 'SubjectValeurDePriseEnCharge',
  VALEURDEPROFIL = 'SubjectValeurDeProfil',
  VALEURDESCORE = 'SubjectValeurDeScore',
  VALEURDEVENEMENTCLASSIQUE = 'SubjectValeurDEvenementClassique',
  VALEURDORDONNANCEUR = 'SubjectValeurDOrdonnanceur',
  VALEURHAUTE = 'SubjectValeurHaute',
  VARIABLE = 'SubjectVariable',
  VARIABLEFIXE = 'SubjectVariableFixe',
  VOIE_D_ABORD = 'SubjectVoieDAbord',
}
