import { useMemo } from 'react';

import { bindFromEntity, IReportPreviewVM } from '../ReportPreviewVM';
import { useGetPatientReportUseCase } from 'src/domains/patient/report/use-case/useGetPatientReport';

const usePatientReportList = (externalId: string) => {
  const { data, isLoading, error } = useGetPatientReportUseCase(externalId);

  const list: IReportPreviewVM[] = useMemo(() => {
    return (data ? [data] : []).map(bindFromEntity);
  }, [data]);

  return {
    isLoading,
    list,
    error,
  };
};

export default usePatientReportList;
