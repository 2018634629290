import { useToggle } from 'react-use';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { IDiagnosticPreviewVM } from '../DiagnosticPreviewVM';
import { useDiagnosticTableColumn } from './columns';
import ObservationDeletionModal from '../../DeletionModal';
import DiagnosticHistoryModal from '../History';
import DiagnosticModificationModalContent from '../Update';
import Section from '../../../Section';
import useDiagnosticList from './hook';
import PersistenceModal from '../../../Modals/PersistenceModal';

const DiagnosticSection: FunctionComponent = () => {
  const { isLoading, diagnosticList } = useDiagnosticList();

  const [selectedDiagnostic, setSelectedDiagnostic] = useState<IDiagnosticPreviewVM | undefined>(
    undefined,
  );
  const [isDiagnosticHistoryModalOpened, toggleDiagnosticHistoryModal] = useToggle(false);
  const [isDiagnosticModificationModalOpened, toggleDiagnosticModificationModal] = useToggle(false);
  const [isDiagnosticDeletionModalOpened, toggleDiagnosticDeletionModal] = useToggle(false);
  /*  const [, toggleDiagnosticCreationModal] = useToggle(false);

  const onCreationClick = useCallback(() => {
    toggleDiagnosticCreationModal();
  }, [toggleDiagnosticCreationModal]);*/

  const onModificationClick = useCallback(
    (vm: IDiagnosticPreviewVM) => {
      if (vm) {
        setSelectedDiagnostic(vm);
        toggleDiagnosticModificationModal();
      }
    },
    [toggleDiagnosticModificationModal],
  );

  const onHistoryClick = useCallback(
    (vm: IDiagnosticPreviewVM) => {
      if (vm) {
        setSelectedDiagnostic(vm);
        toggleDiagnosticHistoryModal();
      }
    },
    [toggleDiagnosticHistoryModal],
  );

  const onDeletionClick = useCallback(
    (vm: IDiagnosticPreviewVM) => {
      if (vm) {
        setSelectedDiagnostic(vm);
        toggleDiagnosticDeletionModal();
      }
    },
    [toggleDiagnosticDeletionModal],
  );

  const columns = useDiagnosticTableColumn({
    handleHistoryClick: onHistoryClick,
    handleDeleteClick: onDeletionClick,
    handleEditClick: onModificationClick,
  });

  const defaultSort = useMemo(
    () => [
      {
        id: 'creationDate',
        desc: true,
      },
    ],
    [],
  );

  return (
    <>
      <Section
        columns={columns}
        data={diagnosticList}
        // onAddButtonClick={onCreationClick}
        title={'Maladies présentes déclarées'}
        isLoading={isLoading}
        defaultSort={defaultSort}
      />
      {/*      <PersistenceModal
        contentType={DiagnosticCreationModalContent}
        onClose={toggleDiagnosticCreationModal}
        open={isDiagnosticCreationModalOpened}
        patientId={patientId}
        selectedRecord={selectedDiagnostic}
        title={'Ajouter la présence d’une maladie déclarée'}
        stepLabels={[
          'Sélection du problème médical',
          'Détails de la présence d’une maladie déclarée',
        ]}
      />*/}
      {selectedDiagnostic && (
        <PersistenceModal
          contentType={DiagnosticModificationModalContent}
          onClose={toggleDiagnosticModificationModal}
          open={isDiagnosticModificationModalOpened}
          selectedRecord={selectedDiagnostic}
          title={'Mettre à jour la présence d’une maladie déclarée'}
        />
      )}
      {selectedDiagnostic && (
        <ObservationDeletionModal
          recordId={selectedDiagnostic.id}
          onClose={toggleDiagnosticDeletionModal}
          open={isDiagnosticDeletionModalOpened}
          title={'Supprimer la présence d’une maladie déclarée'}
        />
      )}
      {selectedDiagnostic && (
        <DiagnosticHistoryModal
          diagnostic={selectedDiagnostic}
          onClose={toggleDiagnosticHistoryModal}
          open={isDiagnosticHistoryModalOpened}
        />
      )}
    </>
  );
};

DiagnosticSection.displayName = 'DiagnosticSection';

export default DiagnosticSection;
