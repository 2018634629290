import { Box, Button, AppBar as MuiAppBar, Toolbar, Typography } from '@material-ui/core';
import { toUpper } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import { FunctionComponent, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import useLogout from 'src/domains/authentication/hooks/useLogout';
import { NavigationRoute } from 'src/routes/navigationRoute';
import { User } from '../../../domains/user/entity/user';
import { toTitleCase } from '../../../helpers/format/string';
import authenticationService from '../../../services/authentication';
import { ReactComponent as LogOutSvg } from '../Icons/svgs/log-out.svg';
import Logo from '../Logo';
import useStyles from './styles';

const AppBar: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [logout] = useLogout();

  const navigateToPatientListPage = () => history.push(NavigationRoute.PATIENTS);

  const connectedUser: User = authenticationService.getUser();

  const userLabel = useMemo(() => {
    if (!connectedUser) {
      return null;
    }
    return `${toTitleCase(connectedUser.firstName)} ${toUpper(connectedUser.lastName)}`;
  }, [connectedUser]);

  return (
    <MuiAppBar
      position="sticky"
      color="inherit"
      elevation={0}
      data-testid={'app-bar'}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Box flexGrow={1} onClick={navigateToPatientListPage}>
          <Logo className={classes.logo} />
        </Box>
        <Box display={'inline-flex'} flexWrap={'wrap'}>
          <Box marginX={2}>
            <Button
              activeClassName={classes.activeNavigationLink}
              component={NavLink}
              to={NavigationRoute.PATIENTS}
            >
              <Typography className={classes.navigationLink}>Rechercher un patient</Typography>
            </Button>
          </Box>
          <Box>
            {!isNilOrEmpty(userLabel) && (
              <Button className={classes.userButton} disabled>
                <Typography color={'primary'} variant={'body1'} className={classes.userLabel}>
                  {userLabel}
                </Typography>
              </Button>
            )}
            <Button className={classes.logoutButton} onClick={logout}>
              <LogOutSvg />
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
