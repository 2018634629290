import { FunctionComponent } from 'react';
import { Theme } from '@material-ui/core/styles/createTheme';
import {
  PDFDivider,
  PDFDocument,
  PDFInlineList,
  PDFList,
  PDFPage,
  PDFStatusMessage,
  PDFTextParam,
  PDFTitle,
} from 'src/components';
import { PDFReportDTO } from './dto';

export interface PDFReportProps {
  pdfReportData?: PDFReportDTO;
  theme: Theme;
}

export const PDFReport: FunctionComponent<PDFReportProps> = ({ pdfReportData, theme }) => {
  if (!pdfReportData) return <div>Une erreur est survenu lors de la génération du PDF</div>;

  return (
    <PDFDocument theme={theme}>
      <PDFPage>
        <PDFTitle {...pdfReportData.reportTitle} />
        <PDFTextParam {...pdfReportData.isCardiacAssessment} />
        <PDFDivider />
        <PDFTextParam {...pdfReportData.referringDoctorStatus} />
        <PDFDivider />
        <PDFStatusMessage {...pdfReportData.recommendation} />
        <PDFDivider />
        <PDFTextParam {...pdfReportData.hasConsultationRecommendationWithSpecialist} />
        <PDFDivider />
        <PDFInlineList {...pdfReportData.additionalEvaluations} />
        <PDFDivider />
        <PDFList {...pdfReportData.lifestyleActionPriorities} />
      </PDFPage>
    </PDFDocument>
  );
};
export default PDFReport;
