import { useMemo } from 'react';

import useGetPatientBehaviourHistoryUseCase from 'src/domains/medical-record/use-case/observation/behaviour/useGetPatientBehaviourHistoryUseCase';
import { IBehaviourPreviewVM, bindFromEntity } from '../BehaviourPreviewVM';

interface UseBehaviourHistoryParams {
  observationDescriptionId: string;
  familyRelationshipId?: string;
}

const useBehaviourHistory = (params: UseBehaviourHistoryParams) => {
  const { data = [], isLoading, error } = useGetPatientBehaviourHistoryUseCase(params);

  const list: IBehaviourPreviewVM[] = useMemo(() => data.map(bindFromEntity), [data]);

  return {
    isLoading,
    list,
    error,
  };
};

export default useBehaviourHistory;
