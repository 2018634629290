import React, { FunctionComponent } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { useStyles } from './styles';

export interface PDFTextParamProps {
  title: string;
  value: string | number;
}

export const PDFTextParam: FunctionComponent<PDFTextParamProps> = ({ value, title }) => {
  const styles = useStyles();
  return (
    <View style={styles.root}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.value}>{value}</Text>
    </View>
  );
};
