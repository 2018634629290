import React, { FunctionComponent, useMemo } from 'react';

import ManifestationForm from '../../ManifestationForm';
import { IManifestationPreviewVM } from '../../ManifestationPreviewVM';
import ManifestationFormDTO, { bindFromIManifestationPreviewVM } from '../../ManifestationForm/dto';
import useUpdateManifestation from './hook';

interface ManifestationModificationFormProps {
  patientId: string;
  manifestation: IManifestationPreviewVM;
  onClose: () => void;
  onPrevious: () => void;
}

const ManifestationModificationForm: FunctionComponent<ManifestationModificationFormProps> = ({
  onClose,
  onPrevious,
  patientId,
  manifestation,
}) => {
  const { update, isCreationLoading, isForceCreationLoading, isReplaceLoading, isSuccess, error } =
    useUpdateManifestation(patientId, manifestation);

  const record: ManifestationFormDTO = useMemo(
    () => bindFromIManifestationPreviewVM(manifestation),
    [manifestation],
  );

  return (
    <ManifestationForm
      error={error}
      isCreating={isCreationLoading}
      isForceCreating={isForceCreationLoading}
      isReplacing={isReplaceLoading}
      isSuccess={isSuccess}
      onClose={onClose}
      onPrevious={onPrevious}
      onSubmit={update}
      record={record}
    />
  );
};

export default ManifestationModificationForm;
