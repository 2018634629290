import { Box, Button, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { Search } from 'react-feather';
import React, { FunctionComponent } from 'react';

import Layout from 'src/components/common/Layout';
import Page from 'src/components/common/Page';
import schema from './schema';
import Table from 'src/components/common/Table';
import useGetPatientList from './useGetPatientList';
import useStyles from './styles';

const PatientList: FunctionComponent = () => {
  const classes = useStyles();

  const { data, columns, isLoading, onSubmit, redirectToExternalPatientDashboardPage } =
    useGetPatientList();

  return (
    <Page testId="patient-list-page">
      <Layout>
        <>
          <Box
            component={Paper}
            display={'flex'}
            //@ts-ignore
            flexDirection={'column'}
            padding={3}
            marginBottom={10}
          >
            <Typography variant={'h3'}>Rechercher un patient</Typography>
            <Box display={'flex'} flexDirection={'column'}>
              <Formik
                initialValues={{
                  idRcu: undefined,
                }}
                onSubmit={async values => {
                  onSubmit(values);
                }}
                validationSchema={schema}
              >
                {props => {
                  return (
                    <Form>
                      <Box display={'flex'} marginY={6} alignItems={'baseline'}>
                        <Box
                          display={'flex'}
                          flex={2}
                          alignItems={'baseline'}
                          flexWrap={'nowrap'}
                          justifyContent={'center'}
                        >
                          <Field
                            className={classes.searchBar}
                            value={props.values.idRcu}
                            margin={'dense'}
                            variant={'outlined'}
                            color={'primary'}
                            id="idRcu"
                            name="idRcu"
                            placeholder="N° RCU"
                            type="text"
                            inputProps={{ 'aria-label': 'search' }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(
                              event: React.ChangeEvent<{ name: string | undefined; value: string }>,
                            ) => {
                              props.setFieldValue('idRcu', event.target.value, true);
                            }}
                            component={TextField}
                          />
                          <Box
                            display={'flex'}
                            flex={2}
                            flexWrap={'nowrap'}
                            justifyContent={'flex-start'}
                            paddingLeft={3}
                          >
                            <Button
                              color={'primary'}
                              variant={'contained'}
                              type={'submit'}
                              disabled={!props.isValid || props.isSubmitting}
                            >
                              Rechercher
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
              <Box flexGrow={1} marginBottom={10}>
                <Table
                  tableProps={{ 'data-testid': 'patient-list' }}
                  columns={columns}
                  data={data}
                  isLoading={isLoading}
                  onRowClick={(row: any) =>
                    row.original?.id &&
                    redirectToExternalPatientDashboardPage(row?.original?.externalId)
                  }
                  loadingRowsCount={1}
                />
              </Box>
            </Box>
          </Box>
        </>
      </Layout>
    </Page>
  );
};

export default PatientList;
