import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles(
  (theme: Theme) => ({
    appBar: {
      marginBottom: 26,
      displayPrint: 'none',
    },
    toolbar: {
      minHeight: 71,
    },
    navigationLink: {
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    activeNavigationLink: {
      color: theme.palette.action.selected,
    },
    logo: {
      cursor: 'pointer',
    },
    logoutButton: {
      minWidth: '3rem',
    },
    userButton: {
      minWidth: 0,
    },
    userLabel: {
      fontWeight: 'bold',
    },
  }),
  { name: 'AppBar' },
);
