import { useToggle } from 'react-use';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { useTableColumn } from './columns';

import { IFactPreviewVM } from '../../FactPreviewVM';
import StatisticHistoryModal from '../History';
import { StatisticModificationModalContent } from '../Update';
import Section from '../../../Section';
import usePatientStatisticList from './hook';
import ObservationDeletionModal from '../../DeletionModal';
import PersistenceModal from '../../../Modals/PersistenceModal';
import StatisticCreationModalContent from '../Create';
import authenticationService from 'src/services/authentication';
import { UserRole } from 'src/domains/user/entity/common';

const StatisticSection: FunctionComponent = () => {
  const { isLoading, list } = usePatientStatisticList();

  const [visualModel, setVisualModel] = useState<IFactPreviewVM | undefined>(undefined);
  const [isHistoryModalOpened, toggleHistoryModal] = useToggle(false);
  const [isModificationModalOpened, toggleModificationModal] = useToggle(false);
  const [isDeletionModalOpened, toggleDeletionModal] = useToggle(false);
  const [isCreationModalOpened, toggleCreationModal] = useToggle(false);

  const currentUser = authenticationService.getUser();

  const onCreationClick = useCallback(() => {
    toggleCreationModal();
  }, [toggleCreationModal]);

  const onModificationClick = useCallback(
    (vm: IFactPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleModificationModal();
      }
    },
    [toggleModificationModal],
  );

  const onHistoryClick = useCallback(
    (vm: IFactPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleHistoryModal();
      }
    },
    [toggleHistoryModal],
  );

  const onDeletionClick = useCallback(
    (vm: IFactPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleDeletionModal();
      }
    },
    [toggleDeletionModal],
  );

  const columns = useTableColumn({
    handleHistoryClick: onHistoryClick,
    handleDeleteClick: onDeletionClick,
    handleEditClick: onModificationClick,
  });

  const defaultSort = useMemo(
    () => [
      {
        id: 'creationDate',
        desc: true,
      },
    ],
    [],
  );

  return (
    <>
      <Section
        columns={columns}
        data={list}
        title={'Statistiques déclarées'}
        isLoading={isLoading}
        defaultSort={defaultSort}
        {...((currentUser?.role === UserRole.ORG_USER) === true
          ? {
              onAddButtonClick: onCreationClick,
              addButtonLabel: 'Saisir les mesures médicales',
            }
          : {})}
      />
      <PersistenceModal
        contentType={StatisticCreationModalContent}
        onClose={toggleCreationModal}
        open={isCreationModalOpened}
        title={'Ajouter des statistiques'}
      />
      {visualModel && (
        <PersistenceModal
          title={'Mettre à jour une statistique'}
          onClose={toggleModificationModal}
          open={isModificationModalOpened}
          contentType={StatisticModificationModalContent}
          selectedRecord={visualModel}
        />
      )}
      {visualModel && (
        <ObservationDeletionModal
          recordId={visualModel.id}
          onClose={toggleDeletionModal}
          open={isDeletionModalOpened}
          title={'Supprimer la statistique'}
        />
      )}
      {visualModel && (
        <StatisticHistoryModal
          statistic={visualModel}
          onClose={toggleHistoryModal}
          open={isHistoryModalOpened}
        />
      )}
    </>
  );
};

StatisticSection.displayName = 'StatisticSection';

export default StatisticSection;
