import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  select: {
    width: 220,
  },
  searchBar: {
    flex: 1,
    top: 7,
  },
  protocolColumn: {
    textTransform: 'uppercase',
  },
  emptyResult: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  validationButton: {
    height: 30,
    marginLeft: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  selectedRow: {
    cursor: 'pointer',
  },
}));
