import { useCallback } from 'react';
import { useCreateReportUseCase } from 'src/domains/patient/report/use-case/useCreatePatientReport';
import {
  Recommendation,
  ReferringDoctorStatus,
} from '../../../../../../../domains/patient/report/types';
import ReportFormDTO from '../../ReportForm/dto';

const useAddReport = (externalId: string) => {
  const { createReport, ...rest } = useCreateReportUseCase();

  const add = useCallback(
    (creation: ReportFormDTO) => {
      createReport({
        externalId,
        createdDate: new Date().toISOString(),
        isCardiacAssessment: creation.isCardiacAssessment,
        cardiacAssessmentConsultation: creation.isCardiacAssessment
          ? {
              additionalEvaluations: creation?.additionalEvaluations || [],
              hasAdditionalTestsRecommendation: !!creation?.hasAdditionalTestsRecommendation,
              hasConsultationRecommendationWithSpecialist:
                !!creation?.hasConsultationRecommendationWithSpecialist,
              lifestyleActionPriorities: creation.lifestyleActionPriorities || {},
              recommendation: creation.recommendation as Recommendation,
              referringDoctorStatus: creation.referringDoctorStatus as ReferringDoctorStatus,
            }
          : undefined,
      });
    },
    [createReport, externalId],
  );

  return { add, ...rest };
};

export default useAddReport;
