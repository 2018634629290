import React, { FunctionComponent } from 'react';
import { Document } from '@react-pdf/renderer';
import { PDFThemeContextProvider } from '../context';
import { Theme } from '@material-ui/core/styles';

export interface PDFDocumentProps {
  theme: Theme;
}

export const PDFDocument: FunctionComponent<PDFDocumentProps> = ({ children, theme }) => {
  return (
    <PDFThemeContextProvider theme={theme}>
      <Document>{children}</Document>
    </PDFThemeContextProvider>
  );
};
