import { StatusType } from 'src/domains/medical-record/value-object/StatusType';
import { IBehaviourPreviewVM } from '../BehaviourPreviewVM';
import { Observation } from 'src/domains/medical-record/entity/observation';

export default interface BehaviourFormDTO {
  observationDescriptionId: string;
  status?: StatusType;
  startDate?: Date;
}

export const bindFromObservation = (observation: Observation): BehaviourFormDTO => {
  return {
    observationDescriptionId: observation.observationDescription.id,
  };
};

export const bindFromIBehaviourPreviewVM = (vm: IBehaviourPreviewVM): BehaviourFormDTO => {
  return {
    observationDescriptionId: vm.descriptionId,
    status: vm.status,
    startDate: vm.startDate,
  };
};
