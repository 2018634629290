import { useMemo } from 'react';

import { bindFromEntity, IAntecedentPreviewVM } from '../AntecedentPreviewVM';
import useGetPatientAntecedentsUseCase from 'src/domains/medical-record/use-case/antecedent/useGetPatientAntecedentsUseCase';

const useAntecedentList = () => {
  const { data = [], isLoading, error } = useGetPatientAntecedentsUseCase();

  const list: IAntecedentPreviewVM[] = useMemo(() => data.map(bindFromEntity), [data]);

  return {
    isLoading,
    list,
    error,
  };
};

export default useAntecedentList;
