import { LatestObservationDTO } from '../../../dto';
import { Observation, PatientObservation, QualificationType } from '../../../entity/observation';
import { SubjectType } from '../../../value-object/SubjectType';

export const behaviourSubjects: string[] = [
  SubjectType.HYGIENEDEVIE,
  SubjectType.FACTEURENVIRONNEMENTAL,
  SubjectType.MODEDEVIE, // FIXME: NEW
];

export const selectBehavioursFromResponse = (
  response: LatestObservationDTO[] = [],
): PatientObservation[] => {
  const filteredLatestObservationsDTO: LatestObservationDTO[] = response.filter(
    ({ patientObservation }) =>
      behaviourSubjects.includes(patientObservation.subject.label) &&
      !patientObservation.familyRelationship?.id,
  );

  return filteredLatestObservationsDTO.map(
    latestObservationDTO => latestObservationDTO.patientObservation,
  );
};

export const selectBehaviourObservations = (observations: Observation[]): Observation[] => {
  return observations.reduce<Observation[]>((observation, currentObservation) => {
    const { subject, observationDescription } = currentObservation;

    const isQualificationMatch =
      observationDescription.qualification === QualificationType.DIAGNOSTIC;
    const isSubjectMatch = behaviourSubjects.includes(subject.label);

    if (isSubjectMatch && isQualificationMatch) {
      observation.push(currentObservation);
    }

    return observation;
  }, []);
};
