import { FunctionComponent } from 'react';
import { Typography } from '@material-ui/core';

import { AnswerType } from 'src/domains/medical-record/value-object/AnswerType';

type AuthorFieldProps = {
  value?: AnswerType;
};

export const AuthorField: FunctionComponent<AuthorFieldProps> = ({ value }) => {
  return (
    <Typography color={'primary'} variant={'h5'}>
      {value === AnswerType.PATIENT ? 'Patient' : value === AnswerType.DOCTOR ? 'Praticien' : '-'}
    </Typography>
  );
};

export default AuthorField;
