import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { cloneElement, FunctionComponent, ReactElement } from 'react';
import { X } from 'react-feather';

import useStyles from './styles';

const backdropTimeout = 500;

export interface ModalProps extends DialogProps {
  closeButton?: ReactElement | null;
  onClose?: any;
  title?: string;
  subtitle?: string;
  footer?: ReactElement;
}

const Modal: FunctionComponent<ModalProps> = ({
  closeButton,
  onClose: handleClose,
  title,
  subtitle,
  children,
  footer,
  PaperProps,
  ...modalProps
}) => {
  const classes = useStyles();

  const definedCloseButton: ReactElement | null =
    closeButton === null ? null : (
      <Button disableRipple color={'primary'} endIcon={<X />}>
        Fermer
      </Button>
    );

  return (
    <Dialog
      {...modalProps}
      onClose={handleClose}
      aria-labelledby="modal-title"
      PaperProps={{
        classes: {
          root: classes.paper,
        },
        ...PaperProps,
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        disableStrictModeCompat: true,
        timeout: backdropTimeout,
      }}
    >
      {(title || definedCloseButton) && (
        <DialogTitle disableTypography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} flexWrap={'nowrap'}>
            {title && (
              <Box display={'flex'} flex={1}>
                <Typography variant="h3" id="modal-title">
                  {title}
                </Typography>
              </Box>
            )}
            {definedCloseButton && (
              <Box display={'flex'} justifyContent={'flex-end'} marginLeft={'1rem'}>
                {definedCloseButton &&
                  cloneElement(definedCloseButton, {
                    className: classes.closeButton,
                    onClick: handleClose,
                  })}
              </Box>
            )}
          </Box>
          {subtitle && <Typography variant={'subtitle1'}>{subtitle}</Typography>}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {footer && <DialogActions data-testid={'modal-footer'}>{footer}</DialogActions>}
    </Dialog>
  );
};

export default Modal;
