import { FunctionComponent } from 'react';

import { displayMeasurementUnit, MeasurementUnit } from 'src/types/quantity';
import TextField from 'src/components/common/Table/TextField';
import { StatusType } from 'src/domains/medical-record/value-object/StatusType';
import StatusField from './StatusField';

type FactValueFieldProps = {
  value?: number;
  status?: StatusType;
  measurementUnit?: MeasurementUnit;
  isQuantifiable: boolean;
};

export const FactValueField: FunctionComponent<FactValueFieldProps> = ({
  value,
  status,
  measurementUnit,
  isQuantifiable,
}) => {
  const label = value ? `${value?.toFixed(2)} ${displayMeasurementUnit(measurementUnit)}` : '-';

  if (isQuantifiable) return <TextField label={label} variant={'h5'} />;

  if (status) return <StatusField status={status} />;

  return <TextField variant={'h5'} />;
};

export default FactValueField;
