import { CellProps } from 'react-table';

import { IManifestationPreviewVM } from '../ManifestationPreviewVM';
import { useMemo } from 'react';
import TextField from 'src/components/common/Table/TextField';
import DateField from 'src/components/common/Table/DateField';
import StatusField from '../../../helpers/table/StatusField';
import AuthorField from '../../../helpers/table/AuthorField';

export const useHistoryTableColumn = (): any[] => {
  return useMemo(
    () => [
      {
        id: 'creationDate',
        accessor: 'creationDate',
        sortType: 'datetime',
        sortable: true,
        width: '15%',
        Cell: (props: CellProps<IManifestationPreviewVM>) => {
          const date = props.cell.value;

          return <DateField date={date} />;
        },
      },
      {
        accessor: 'label',
        width: '50%',
        Cell: (props: CellProps<IManifestationPreviewVM>) => {
          const label = props.cell.value;

          return <TextField label={label} variant={'h5'} />;
        },
      },
      {
        accessor: 'answerType',
        width: '20%',
        Cell: (props: CellProps<IManifestationPreviewVM>) => {
          const answerType = props.cell.value;

          return <AuthorField value={answerType} />;
        },
      },
      {
        accessor: 'status',
        width: '15%',
        sortable: true,
        Cell: (props: CellProps<IManifestationPreviewVM>) => {
          const status = props.cell.value;

          return <StatusField status={status} />;
        },
      },
    ],
    [],
  );
};
