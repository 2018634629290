import { useMemo } from 'react';
import useGetPatientDiagnosticsUseCase from 'src/domains/medical-record/use-case/observation/diagnostic/useGetPatientDiagnosticsUseCase';

import { bindFromEntity, IDiagnosticPreviewVM } from '../DiagnosticPreviewVM';

const useDiagnosticList = () => {
  const { data = [], isLoading, error } = useGetPatientDiagnosticsUseCase();

  const diagnosticList: IDiagnosticPreviewVM[] = useMemo(() => data.map(bindFromEntity), [data]);

  return {
    isLoading,
    diagnosticList,
    error,
  };
};

export default useDiagnosticList;
