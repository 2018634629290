import { useCallback, useEffect } from 'react';

import { useLogoutMutation } from '../../api';
import authenticationService from 'src/services/authentication';
import { useDispatch } from 'react-redux';
import { userApi } from 'src/domains/user/repository';

const useInvalidateCurentUser = () => {
  const dispatch = useDispatch();

  const invalidateCurrentUser = useCallback(() => {
    dispatch(userApi.util.invalidateTags(['User']));
  }, [dispatch]);

  return { invalidateCurrentUser };
};

export type UseLogoutValue = [
  () => void | any,
  {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    error: any;
  },
];

const useLogout = (): UseLogoutValue => {
  const [logout, { isLoading, isSuccess, isError, error }] = useLogoutMutation();
  const { invalidateCurrentUser } = useInvalidateCurentUser();

  useEffect(() => {
    if (!isLoading && !isError && isSuccess) {
      invalidateCurrentUser();
      authenticationService.logout();
    }
  }, [isLoading, isError, isSuccess, invalidateCurrentUser]);

  return [logout, { isLoading, isSuccess, isError, error }];
};

export default useLogout;
