import React, { FunctionComponent, useMemo } from 'react';

import HistoryModal from '../../../Modals/HistoryModal';
import { useDiagnosticHistoryTableColumn } from './columns';
import { IDiagnosticPreviewVM } from '../DiagnosticPreviewVM';
import useDiagnosticHistory from './hook';

interface DiagnosticHistoryModalProps {
  diagnostic: IDiagnosticPreviewVM;
  onClose: () => void;
  open: boolean;
}

export const DiagnosticHistoryModal: FunctionComponent<DiagnosticHistoryModalProps> = ({
  diagnostic,
  onClose: handleOnClose,
  open,
}) => {
  const { isLoading, diagnosticList } = useDiagnosticHistory({
    observationDescriptionId: diagnostic.descriptionId,
    familyRelationshipId: diagnostic.familyRelationshipId,
  });

  const title = useMemo(() => `Historique de présence d'une maladie déclarée`, []);

  const columns = useDiagnosticHistoryTableColumn();

  return (
    <>
      <HistoryModal
        columns={columns}
        data={diagnosticList}
        onClose={handleOnClose}
        open={open}
        isLoading={isLoading}
        title={title}
      />
      {/*      <DiagnosticCreationModal
        title={'Ajouter un diagnostic'}
        stepLabels={['Sélection du problème médical', 'Détails du diagnostic']}
        open={isDiagnosticCreationModalOpened}
        patientId={patientId}
        onClose={toggleDiagnosticCreationModal}
      />*/}
    </>
  );
};

export default DiagnosticHistoryModal;
