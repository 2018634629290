import * as yup from 'yup';

export const schema = yup.object().shape(
  {
    systolicBp: yup
      .number()
      .min(10, 'La tension artérielle systolique doit être supérieur à 10mmHg')
      .max(300, 'La tension artérielle systolique doit être inférieur à 300mmHg')
      .nullable()
      .when('diastolicBp', {
        is: (val: number | null) => !!val,
        then: schema => schema.required('La tension artérielle systolique doit être renseignée'),
      }),
    diastolicBp: yup
      .number()
      .min(10, 'La tension artérielle diastolique doit être supérieur à 10mmHg')
      .max(300, 'La tension artérielle diastolique doit être inférieur à 300mmHg')
      .nullable()
      .when('systolicBp', {
        is: (val: number | null) => !!val,
        then: schema => schema.required('La tension artérielle diastolique doit être renseignée'),
      }),
    glycemia: yup
      .number()
      .min(0, 'La glycémie doit être supérieur à 0mg/dL')
      .max(9999, 'La glycémie doit être inférieur à 0mg/dL')
      .nullable()
      .when('timeElapsedAfterMeal', {
        is: (val: number | null) => !!val,
        then: schema => schema.required('La glycémie doit être renseignée'),
      }),
    timeElapsedAfterMeal: yup
      .number()
      .min(10, 'Le temps écoulé depuis le dernier repas doit être supérieur à 10min')
      .max(1000, 'Le temps écoulé depuis le dernier repas doit être supérieur à 1000min')
      .nullable()
      .when('glycemia', {
        is: (val: number | null) => !!val,
        then: schema =>
          schema.required('Le temps écoulé depuis le dernier repas doit être renseigné'),
      }),
    weight: yup
      .number()
      .min(20, 'Le poids doit être supérieur à 20Kg')
      .max(300, 'Le poids doit être supérieur à 300Kg')
      .nullable(),
    waistSize: yup
      .number()
      .min(30, 'Le tour de taille - perimètre abdominal doit être supérieur à 30cm')
      .max(200, 'Le tour de taille - perimètre abdominal doit être supérieur à 200cm')
      .nullable(),
    cholesterol: yup
      .number()
      .min(10, 'Le cholestérol total doit être supérieur à 10mg/dL')
      .max(1000, 'Le cholestérol total doit être supérieur à 1000mg/dL')
      .nullable(),
    hdl: yup
      .number()
      .min(10, 'Le HDL doit être supérieur à 10mg/dL')
      .max(1000, 'Le HDL doit être supérieur à 1000mg/dL')
      .nullable(),
    ldl: yup
      .number()
      .min(10, 'Le LDL doit être supérieur à 10mg/dL')
      .max(1000, 'Le LDL doit être supérieur à 1000mg/dL')
      .nullable(),
    triglycerides: yup
      .number()
      .min(10, 'La triglycérides doit être supérieur à 10mg/dL')
      .max(1000, 'La triglycérides doit être supérieur à 1000mg/dL')
      .nullable(),
  },
  [
    ['diastolicBp', 'systolicBp'],
    ['glycemia', 'timeElapsedAfterMeal'],
  ],
);
