import { useGetPatientStatisticHistoryQuery } from '../../../repository';

interface UseGetPatientFactHistoryUseCaseParams {
  factTypeId: string;
}

const useGetPatientStatisticHistoryUseCase = (params: UseGetPatientFactHistoryUseCaseParams) => {
  const { isLoading, data, error } = useGetPatientStatisticHistoryQuery(params);

  return { data, isLoading, error };
};

export default useGetPatientStatisticHistoryUseCase;
