import { LatestObservationDTO } from '../../../dto';
import { Observation, PatientObservation, QualificationType } from '../../../entity/observation';
import { SubjectType } from '../../../value-object/SubjectType';

export const diagnosticSubjects: string[] = [
  SubjectType.PATHOLOGIE,
  SubjectType.SYNDROME,
  SubjectType.REACTION,
];
// From latest patient observations response
export const selectDiagnosticsFromResponse = (
  response: LatestObservationDTO[] = [],
): PatientObservation[] => {
  const filteredLatestObservationsDTO: LatestObservationDTO[] = response.filter(
    ({ patientObservation }) =>
      diagnosticSubjects.includes(patientObservation.subject.label) &&
      !patientObservation.familyRelationship?.id,
  );

  return filteredLatestObservationsDTO.map(
    latestObservationDTO => latestObservationDTO.patientObservation,
  );
};

export const selectDiagnosticObservations = (observations: Observation[]): Observation[] => {
  return observations.reduce<Observation[]>((observation, currentObservation) => {
    const { subject, observationDescription } = currentObservation;

    const isQualificationMatch =
      observationDescription.qualification === QualificationType.DIAGNOSTIC;
    const isSubjectMatch = diagnosticSubjects.includes(subject.label);

    if (isSubjectMatch && isQualificationMatch) {
      observation.push(currentObservation);
    }

    return observation;
  }, []);
};
