import { SelectOption } from '../../../../../../components/common/Select';
import {
  AdditionalEvaluations,
  LifestyleAction,
  Recommendation,
  ReferringDoctorStatus,
} from '../../../../../../domains/patient/report/types';

export const referringDoctorStatusOptions: SelectOption[] = [
  { label: 'Ne souhaite pas répondre', value: ReferringDoctorStatus.NO_ANSWER },
  { label: 'Oui', value: ReferringDoctorStatus.PRESENT },
  { label: 'Non', value: ReferringDoctorStatus.ABSENT },
];

export const recommendationOptions: SelectOption[] = [
  {
    label:
      '1. Votre risque cardiovasculaire est faible. N’hésitez pas à partager vos résultats avec votre médecin traitant lors de votre prochaine consultation.',
    value: Recommendation.LOW,
  },
  {
    label:
      '2. Profitez de votre prochaine consultation avec votre médecin traitant pour discuter avec lui les risques identifiés dans Mon Bilan Cardio. Si vous n’avez pas de médecin traitant, choisissez-en un.',
    value: Recommendation.MEDIUM,
  },
  {
    label:
      '3. Consultez rapidement votre médecin traitant pour qu’il évalue avec vous les risques identifiés dans Mon Bilan Cardio. Si vous n’avez pas de médecin traitant, choisissez-en un rapidement.',
    value: Recommendation.HIGH,
  },
];

export const additionalEvaluationsOptions: SelectOption[] = [
  {
    label: 'Bilan lipidique',
    value: AdditionalEvaluations.LIPID,
  },
  {
    label: 'Bilan glycémique',
    value: AdditionalEvaluations.GLYCERIN,
  },
  {
    label: 'Bilan thyroïdien',
    value: AdditionalEvaluations.THYROID,
  },
  {
    label: 'Bilan rénal',
    value: AdditionalEvaluations.RENAL,
  },
  {
    label: 'Autres examens',
    value: AdditionalEvaluations.OTHER,
  },
];

export const lifestyleActionsOptions: SelectOption[] = [
  { label: '-', value: undefined },
  { label: 'Alimentation', value: LifestyleAction.FOOD },
  { label: 'Activité physique', value: LifestyleAction.PHYSICAL_ACTIVITY },
  { label: 'Sédentaire', value: LifestyleAction.SEDENTARY },
  { label: 'IMC', value: LifestyleAction.BODY_MASS_INDEX },
  { label: 'Tabac', value: LifestyleAction.TABACCO },
  { label: 'Alcool', value: LifestyleAction.ALCOHOL },
  { label: 'Stress', value: LifestyleAction.STRESS },
  { label: 'Sommeil', value: LifestyleAction.SLEEP },
];
