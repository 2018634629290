import { TextField, Typography } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import { toTitleCase } from 'src/helpers/format/string';
import React, { FunctionComponent, useMemo } from 'react';

import { displayMeasurementUnit } from 'src/types/quantity';
import { statusOptions } from '../../../helpers/statusOptions';
import { STATUS_TYPE, StatusType } from 'src/domains/medical-record/value-object/StatusType';
import PersistenceForm from '../../../Modals/PersistenceModal/PersistenceForm';
import schema, { StatisticCreation } from './schema';
import Select from 'src/components/common/Select';
import StatisticFormDTO from './dto';
import useStyles from '../../../Modals/PersistenceModal/PersistenceForm/styles';

interface StatisticFormProps {
  error?: any;
  isCreating?: boolean;
  isReplacing?: boolean;
  isForceCreating?: boolean;
  isSuccess: boolean;
  onClose: () => void;
  onPrevious?: () => void;
  onSubmit: (statistic: StatisticCreation) => void;
  record: StatisticFormDTO;
}

const StatisticForm: FunctionComponent<StatisticFormProps> = ({
  error,
  isCreating,
  isForceCreating,
  isReplacing,
  isSuccess,
  onClose,
  onPrevious,
  onSubmit,
  record,
}) => {
  const classes = useStyles();

  const initialValues: StatisticCreation = useMemo(
    () => ({
      status: record?.status || STATUS_TYPE.PRESENT,
      value: record?.value || 0,
    }),
    [record?.status, record?.value],
  );

  const isUpdateMode: boolean = useMemo(() => Boolean(record?.id), [record]);

  return (
    <PersistenceForm
      error={error}
      initialValues={initialValues}
      isUpdateMode={isUpdateMode}
      isCreating={isCreating}
      isForceCreating={isForceCreating}
      isReplacing={isReplacing}
      isSubmittingSuccessfully={isSuccess}
      onClose={onClose}
      onPrevious={onPrevious}
      onSubmit={onSubmit}
      validationSchema={schema}
      formContent={(props: FormikProps<StatisticCreation>) => (
        <>
          <div className={classes.row}>
            <div className={classes.inputSubjectContainer}>
              <Typography variant={'body1'}>Statistique sélectionnée</Typography>
            </div>
            <div className={classes.inputContainer}>
              <Typography color={'primary'} variant={'body1'} className={classes.selectedStatistic}>
                {toTitleCase(record.label)}
              </Typography>
            </div>
          </div>

          {record.isQuantifiable ? (
            <div className={classes.specificRow}>
              <div className={classes.inputSubjectContainer}>
                <div className={classes.inputSubjectLabel}>
                  <Typography variant={'body1'}>{`${toTitleCase(record.label)} ${
                    record?.measurementUnit
                      ? `(${displayMeasurementUnit(record?.measurementUnit)}) `
                      : ' '
                  }de`}</Typography>
                </div>
              </div>
              <div className={classes.inputContainer}>
                <Field
                  value={props.values.value}
                  margin={'dense'}
                  variant={'outlined'}
                  color={'primary'}
                  id="value"
                  name="value"
                  placeholder=""
                  type="number"
                  onChange={(
                    event: React.ChangeEvent<{ name: string | undefined; value: number }>,
                  ) => {
                    props.setFieldValue('value', event.target.value, true);
                  }}
                  component={TextField}
                />
              </div>
            </div>
          ) : (
            <div className={classes.specificRow}>
              <div className={classes.inputSubjectContainer}>
                <Typography variant={'body1'}>État: </Typography>
              </div>
              <div className={classes.inputContainer}>
                <Field
                  value={props.values.status}
                  options={statusOptions}
                  margin={'dense'}
                  variant={'outlined'}
                  color={'primary'}
                  id="status"
                  name="status"
                  placeholder=""
                  type="text"
                  onChange={(
                    event: React.ChangeEvent<{ name: string | undefined; value: StatusType }>,
                  ) => {
                    props.setFieldValue('status', event.target.value, true);
                  }}
                  component={Select}
                />
              </div>
            </div>
          )}
        </>
      )}
    />
  );
};

export default StatisticForm;
