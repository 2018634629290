import { LatestEventDTO } from '../../../dto';
import { PatientEventAndType } from '../../../entity/event';

export const selectEventsFromResponse = (
  response: LatestEventDTO[] = [],
): PatientEventAndType[] => {
  return response.map(latestEventDTO => ({
    event: latestEventDTO.patientEvent,
    type: latestEventDTO.eventType,
  }));
};
