import { CellProps } from 'react-table';

import { IDiagnosticPreviewVM } from '../DiagnosticPreviewVM';
import useTableActions, {
  HandleClick,
  TableActionParams,
} from '../../../helpers/table/use-table-actions';
import { useMemo } from 'react';
import TextField from 'src/components/common/Table/TextField';
import DateField from 'src/components/common/Table/DateField';
import StatusField from '../../../helpers/table/StatusField';
import { AuthorField } from '../../../helpers/table/AuthorField';

interface useDiagnosticTableColumnProps extends TableActionParams<IDiagnosticPreviewVM> {
  handleDeleteClick: HandleClick<IDiagnosticPreviewVM>;
}

export const useDiagnosticTableColumn = ({
  handleDeleteClick,
  handleEditClick,
  handleHistoryClick,
}: useDiagnosticTableColumnProps): any[] => {
  const actionsColumn = useTableActions<IDiagnosticPreviewVM>({
    handleDeleteClick,
    handleEditClick,
    handleHistoryClick,
  });

  return useMemo(
    () => [
      {
        Header: 'Date de déclaration',
        id: 'creationDate',
        accessor: 'creationDate',
        sortType: 'datetime',
        sortable: true,
        width: '15%',
        Cell: (props: CellProps<IDiagnosticPreviewVM>) => {
          const date = props.cell.value;

          return <DateField date={date} />;
        },
      },
      {
        Header: 'Maladie présente déclarée par le patient',
        accessor: 'label',
        width: '50%',
        Cell: (props: CellProps<IDiagnosticPreviewVM>) => {
          const label = props.cell.value;

          return <TextField label={label} variant={'h5'} />;
        },
      },
      {
        Header: 'Modification',
        accessor: 'answerType',
        width: '20%',
        Cell: (props: CellProps<IDiagnosticPreviewVM>) => {
          const label = props.cell.value;

          return <AuthorField value={label} />;
        },
      },
      {
        Header: 'Statut',
        accessor: 'status',
        width: '10%',
        sortable: true,
        Cell: (props: CellProps<IDiagnosticPreviewVM>) => {
          const status = props.cell.value;

          return <StatusField status={status} />;
        },
      },
      actionsColumn,
    ],
    [actionsColumn],
  );
};
