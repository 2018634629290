import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { ModalProps } from 'src/components/common/Modal';
import DeleteModal from '../../Modals/DeleteModal';
import { useDeletePatientReportUseCase } from 'src/domains/patient/report/use-case/useDeletePatientReport';

interface ReportDeletionModalProps extends ModalProps {
  title: string;
  externalId: string;
}

const ReportDeletionModal: FunctionComponent<ReportDeletionModalProps> = ({
  onClose,
  externalId,
  title,
  ...modalProps
}) => {
  const { deleteReport, isLoading, isSuccess } = useDeletePatientReportUseCase();

  const onDelete = useCallback(() => deleteReport(externalId), [deleteReport, externalId]);

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess, onClose]);

  return (
    <DeleteModal
      title={title}
      onDelete={onDelete}
      isLoading={isLoading}
      onClose={onClose}
      {...modalProps}
    />
  );
};

export default ReportDeletionModal;
