import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'baseline',
    justifyContent: 'center',
    flex: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  inputSubjectContainer: {
    display: 'flex',
    flexBasis: '42%',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(3),
  },
  inputContainer: {
    marginLeft: theme.spacing(3),
    flexBasis: '58%',
    display: 'flex',
  },
  footer: {
    marginTop: theme.spacing(10),
  },
  circularProgress: {
    color: theme.palette.primary.contrastText,
  },
}));
