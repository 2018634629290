import { AnswerType } from '../value-object/AnswerType';
import { CoreAppItem } from '../value-object/Item';
import { Frequency } from '../../../types/frequency';
import { Quantity } from '../../../types/quantity';
import { StatusType } from '../value-object/StatusType';
import { Subject } from '../value-object/Subject';

export interface FactType {
  id: string;
  label: string;
  stored: boolean;
  coreAppId: number;
  editionFormat: EditionFormat;
  measurementUnit: {
    id: string;
    longName: string;
    shortName: string;
  };
  qualitativeResuts: {
    id: string;
    label: string;
    coreAppId: number;
  }[];
}

export enum EditionFormat {
  PERIOD = 'PERIOD',
  DATE = 'DATE',
  DEGREE = 'DEGREE',
  PRESENCE = 'PRESENCE',
  FREQUENCY = 'FREQUENCY',
  MEASURE = 'MEASURE',
  INHERENT = 'INHERENT',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  QUALITATIVE_RESULT = 'QUALITATIVE_RESULT',
  FORMAT_PASSAGE = 'FORMAT_PASSAGE',
}

export interface PatientFact {
  id: string;
  date: Date;
  startDate: Date;
  endDate: Date;
  expirationDate: Date;
  status: StatusType;
  measure: Quantity;
  qualitativeResult: CoreAppItem;
  answerType: AnswerType;
  frequency: Frequency;
  lastModifiedBy: string;
}

export interface PatientFactAndType {
  fact: PatientFact;
  type: FactType;
}

export interface Fact {
  factType: FactType;
  subject: Subject;
}
