import { Antecedent } from 'src/domains/medical-record/entity/patientAntecedent';
import { IAntecedentPreviewVM } from '../AntecedentPreviewVM';
import { MedicalRecordType } from 'src/domains/medical-record/entity';
import { StatusType } from 'src/domains/medical-record/value-object/StatusType';

export default interface AntecedentFormDTO {
  familyRelationshipId?: string;
  status?: StatusType;
  type: MedicalRecordType;
}

export const bindFromAntecedent = (antecedent: Antecedent): AntecedentFormDTO => {
  return {
    familyRelationshipId: antecedent.familyRelationship?.id,
    type: MedicalRecordType.OBSERVATION,
  };
};

export const bindFromIAntecedentPreviewVM = (vm: IAntecedentPreviewVM): AntecedentFormDTO => {
  return {
    familyRelationshipId: vm.familyRelationship?.id,
    status: vm.status,
    type: vm.type,
  };
};
