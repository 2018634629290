import { FunctionComponent, memo } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { format } from 'date-fns';

interface DateFieldProps extends TypographyProps {
  date?: Date;
  emptyLabel?: string;
}

const DateField: FunctionComponent<DateFieldProps> = memo(
  ({ date, emptyLabel = '-', variant = 'body1', color = 'primary', ...rest }) => {
    return (
      <Typography variant={variant} color={color} {...rest}>
        {date ? format(new Date(date), 'dd/MM/yyyy') : emptyLabel}
      </Typography>
    );
  },
);

export default DateField;
