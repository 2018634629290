import { compareDesc } from 'date-fns';
import { StatusType } from 'src/domains/medical-record/value-object/StatusType';

import { PatientObservation } from '../../../entity/observation';
import { LatestObservationDTO } from '../../../dto';

export const selectObservationFromObservationListByDescriptionId = (
  list: PatientObservation[] = [],
  observationDescriptionId: string,
): PatientObservation | undefined =>
  list.find(observation => observation.observationDescription.id === observationDescriptionId);

export const selectInitialAndLastModifiedPatientObservation = (
  patientObservations: PatientObservation[] = [],
) => {
  const sortedAllPatientObservations: PatientObservation[] = patientObservations.sort((a, b) =>
    compareDesc(new Date(a.date as Date), new Date(b.date as Date)),
  );

  const initial: PatientObservation | undefined = sortedAllPatientObservations.find(
    patientObservation => patientObservation.lastModifiedBy === 'System',
  );

  const lastModified: PatientObservation | undefined = sortedAllPatientObservations.find(
    patientObservation => patientObservation.lastModifiedBy !== 'System',
  );

  return { initial, lastModified };
};

export const selectPatientObservationsByStatus = (
  patientObservations: PatientObservation[] = [],
  status: StatusType,
): PatientObservation[] =>
  patientObservations.filter(patientObservation => patientObservation?.status === status);

export const selectPatientObservationsFromGetLatestPatientObservationsResponse = (
  response: LatestObservationDTO[] = [],
): PatientObservation[] =>
  response.map(latestObservationDTO => latestObservationDTO.patientObservation);
