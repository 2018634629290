import {
  Column,
  Row,
  SortingRule,
  TableProps as RTTableProps,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useTable,
  UseTableOptions,
} from 'react-table';
import { ExpandMore } from '@material-ui/icons';
import { FunctionComponent, memo, useEffect, useMemo } from 'react';
import { isNilOrEmpty } from 'ramda-adjunct';
import { any, propOr, toPairs } from 'ramda';
import { Skeleton } from '@material-ui/lab';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';

import { EnhancedColumn } from './types';
import EmptyDataRow from './EmptyDataRow';
import useStyles from './styles';

const nominalLoadingRowsCount = 3;

export interface TableProps extends RTTableProps, Omit<UseTableOptions<any>, 'columns'> {
  isLoading?: boolean;
  defaultSort?: Array<SortingRule<any>>;
  filters?: Object;
  globalFilter?: string | number;
  loadingRowsCount?: number;
  onPreFilteredRowsChange?: (preFilteredRows: Array<Row<any>>) => void;
  onRowClick?: (row: Row<any>) => void;
  columns: EnhancedColumn[];
  tableProps?: object;
}

const Table: FunctionComponent<TableProps> = memo(
  ({
    columns,
    data,
    defaultSort = [],
    filters = {},
    globalFilter,
    isLoading = false,
    loadingRowsCount = nominalLoadingRowsCount,
    onPreFilteredRowsChange,
    onRowClick,
    tableProps = {},
    ...rest
  }) => {
    const tableData = useMemo(
      () => (isLoading ? Array(loadingRowsCount).fill({}) : data),
      [data, isLoading, loadingRowsCount],
    );

    const tableColumns: Column[] = useMemo(() => {
      if (isLoading)
        return columns.map(({ Skeleton: ColumnSkeleton, ...restColumn }) => ({
          ...restColumn,
          Cell: ColumnSkeleton ? (
            ColumnSkeleton
          ) : (
            <Skeleton height={18} variant="text" width={'100%'} />
          ),
        })) as Column[];
      return columns as Column[];
    }, [columns, isLoading]);

    const classes = useStyles();
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      preFilteredRows,
      prepareRow,
      setSortBy,
      setFilter,
      setGlobalFilter,
    } = useTable(
      {
        columns: tableColumns,
        data: tableData,
        autoResetSortBy: false,
        ...rest,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
    );

    useEffect(() => {
      if (!isLoading && !isNilOrEmpty(defaultSort)) setSortBy(defaultSort);
    }, [defaultSort, isLoading, setSortBy]);

    useEffect(() => {
      const flattenFilters: Array<Array<any>> = toPairs(filters);
      flattenFilters.forEach(([key, value]) => {
        setFilter(key, value);
      });
    }, [filters, setFilter]);

    useEffect(() => {
      setGlobalFilter(globalFilter);
    }, [globalFilter, setGlobalFilter]);

    useEffect(() => {
      onPreFilteredRowsChange && onPreFilteredRowsChange(preFilteredRows);
    }, [onPreFilteredRowsChange, preFilteredRows]);

    if (isNilOrEmpty(columns)) return null;

    const hasHeaders = any((column: EnhancedColumn) => !isNilOrEmpty(column.Header))(columns);

    return (
      <MuiTable {...getTableProps()} {...tableProps}>
        {hasHeaders && (
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  const sortLabelDirection = !column.isSorted
                    ? undefined
                    : column.isSortedDesc
                    ? 'desc'
                    : 'asc';

                  return (
                    <TableCell
                      width={propOr('auto', 'width', column)}
                      align={propOr('left', 'headerAlign', column)}
                      {...column.getHeaderProps()}
                    >
                      {propOr(false, 'sortable', column) ? (
                        <TableSortLabel
                          hideSortIcon={!column.canSort}
                          active={column.isSorted}
                          direction={sortLabelDirection}
                          IconComponent={ExpandMore}
                          {...column.getSortByToggleProps()}
                        >
                          {column.render('Header')}
                        </TableSortLabel>
                      ) : (
                        column.render('Header')
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
        )}
        <TableBody {...getTableBodyProps()}>
          {isLoading &&
            rows.map(row => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} hover>
                  {row.cells.map(cell => (
                    <TableCell
                      width={propOr('auto', 'width', cell.column)}
                      align={propOr('left', 'align', cell.column)}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          {!isLoading && isNilOrEmpty(rows) && (
            <EmptyDataRow message={'Aucun résultat'} colSpan={columns.length} />
          )}
          {!isLoading &&
            !isNilOrEmpty(rows) &&
            rows.map(row => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps({
                    className: onRowClick && classes.selectedRow,
                  })}
                  hover
                  onClick={() => onRowClick && onRowClick(row)}
                >
                  {row.cells.map(cell => (
                    <TableCell
                      width={propOr('auto', 'width', cell.column)}
                      align={propOr('left', 'align', cell.column)}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
        </TableBody>
      </MuiTable>
    );
  },
);

export default Table;
