import { useMemo } from 'react';

import { QualificationType } from 'src/domains/medical-record/entity/observation';
import { useGetLatestPatientObservationsQuery } from 'src/domains/medical-record/repository';
import { selectBehavioursFromResponse } from 'src/domains/medical-record/repository/selector/observation/behaviour';
import { PatientObservationType } from 'src/domains/medical-record/value-object/PatientObservationType';
import { STATUS_TYPE } from 'src/domains/medical-record/value-object/StatusType';
import { bindFromEntity, IBehaviourPreviewVM } from '../BehaviourPreviewVM';

const useBehaviourList = () => {
  const {
    data = [],
    isLoading,
    error,
  } = useGetLatestPatientObservationsQuery({
    statuses: [STATUS_TYPE.PRESENT, STATUS_TYPE.ABSENT],
    qualifications: [QualificationType.DIAGNOSTIC],
    type: PatientObservationType.DIAGNOSTICS,
  });

  const list: IBehaviourPreviewVM[] = useMemo(
    () => selectBehavioursFromResponse(data).map(bindFromEntity),
    [data],
  );

  return {
    isLoading,
    list,
    error,
  };
};

export default useBehaviourList;
