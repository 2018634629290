import React, { FunctionComponent } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { useStyles } from './styles';
import { booleanToText } from '../../../helpers/format/string';

export interface PDFInlineListProps {
  title: string;
  value?: string;
  listValues: Array<string | number>;
}

export const PDFInlineList: FunctionComponent<PDFInlineListProps> = ({
  title,
  value,
  listValues,
}) => {
  const styles = useStyles();
  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <Text style={styles.title}>{title}</Text>
        {value && <Text style={styles.value}>{value}</Text>}
      </View>
      {Boolean(listValues.length) && value === booleanToText(true) && (
        <View style={styles.listContainer}>
          {listValues.map((item, idx) => (
            <Text key={item} style={styles.listItem}>
              {item}
              {idx !== listValues.length - 1 ? ', ' : ''}
            </Text>
          ))}
        </View>
      )}
    </View>
  );
};
