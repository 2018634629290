import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as StepConnectorSvg } from './svgs/step-connector.svg';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '3.1rem',
  },
}));

const StepConnectorIcon = (props: SvgIconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      {...props}
      classes={{ root: classes.root }}
      component={StepConnectorSvg}
      viewBox="0 0 49.505 14"
    />
  );
};

export default StepConnectorIcon;
