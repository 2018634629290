import { CellProps } from 'react-table';
import { useMemo } from 'react';

import { IBehaviourPreviewVM } from '../BehaviourPreviewVM';
import DateField from 'src/components/common/Table/DateField';
import TextField from 'src/components/common/Table/TextField';
import StatusField from '../../../helpers/table/StatusField';
import useTableActions, {
  HandleClick,
  TableActionParams,
} from '../../../helpers/table/use-table-actions';
import AuthorField from '../../../helpers/table/AuthorField';

interface useTableColumnProps extends TableActionParams<IBehaviourPreviewVM> {
  handleDeleteClick: HandleClick<IBehaviourPreviewVM>;
}

export const useTableColumn = ({
  handleDeleteClick,
  handleEditClick,
  handleHistoryClick,
}: useTableColumnProps): any[] => {
  const actionsColumn = useTableActions<IBehaviourPreviewVM>({
    handleDeleteClick,
    handleEditClick,
    handleHistoryClick,
  });

  return useMemo(
    () => [
      {
        Header: 'Date de déclaration',
        id: 'creationDate',
        accessor: 'creationDate',
        sortType: 'datetime',
        sortable: true,
        width: '15%',
        Cell: (props: CellProps<IBehaviourPreviewVM>) => {
          const date = props.cell.value;

          return <DateField date={date} />;
        },
      },
      {
        Header: 'Comportement déclaré par le patient',
        accessor: 'label',
        width: '50%',
        Cell: (props: CellProps<IBehaviourPreviewVM>) => {
          const label = props.cell.value;

          return <TextField label={label} variant={'h5'} />;
        },
      },
      {
        Header: 'Modification',
        accessor: 'answerType',
        width: '20%',
        Cell: (props: CellProps<IBehaviourPreviewVM>) => {
          const label = props.cell.value;

          return <AuthorField value={label} />;
        },
      },
      {
        Header: 'Statut',
        accessor: 'status',
        width: '10%',
        sortable: true,
        Cell: (props: CellProps<IBehaviourPreviewVM>) => {
          const status = props.cell.value;

          return <StatusField status={status} />;
        },
      },

      actionsColumn,
    ],
    [actionsColumn],
  );
};
