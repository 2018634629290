import { Form, Formik } from 'formik';
import {
  Switch,
  Typography,
  Select as MuiSelect,
  MenuItem,
  ListItemText,
  Checkbox,
  List,
  ListItem,
  Divider,
  CircularProgress,
  MenuProps,
} from '@material-ui/core';
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';

import {
  AdditionalEvaluations,
  LifestyleAction,
  Recommendation,
  ReferringDoctorStatus,
} from '../../../../../../domains/patient/report/types';
import ReportFormDTO from './dto';
import schema from './schema';
import Select, { SelectOption } from 'src/components/common/Select';
import useStyles from './styles';
import {
  hasAdditionalTestsRecommendationQuestion,
  hasConsultationRecommendationWithSpecialistQuestion,
  hasReferringDoctorQuestion,
  isCardiacAssessmentQuestion,
  lifestyleActionsFirstPriorityLabel,
  lifestyleActionsLabel,
  lifestyleActionsSecondPriorityLabel,
  lifestyleActionsThirdPriorityLabel,
  recommendationQuestion,
} from './wording';
import { FormikHelpers } from 'formik';
import ModalFooterButtons, { ButtonParams } from 'src/components/common/Modal/ModalFooterButtons';
import {
  additionalEvaluationsOptions,
  lifestyleActionsOptions,
  recommendationOptions,
  referringDoctorStatusOptions,
} from './options';
import { findOptionLabelByValue } from '../../../../../../helpers/format/options';
import AlertText, { AlertTextProps } from 'src/components/common/AlertText';

interface ReportFormProps {
  error?: any;
  isUpdateMode?: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  onClose: () => void;
  onSubmit: (report: ReportFormDTO) => void;
  values: ReportFormDTO;
}

const ReportForm: FunctionComponent<ReportFormProps> = ({
  isUpdateMode = false,
  isLoading = false,
  isSuccess,
  onClose,
  onSubmit,
  values,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!isLoading && isSuccess) onClose();
  }, [isLoading, isSuccess, onClose]);

  const checkboxesMenuProps: Partial<MenuProps> = useMemo(
    () => ({
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      getContentAnchorEl: null,
    }),
    [],
  );

  const recommendationToColor: { [k in Recommendation]: AlertTextProps['color'] } = {
    [Recommendation.LOW]: 'success',
    [Recommendation.MEDIUM]: 'warning',
    [Recommendation.HIGH]: 'error',
  };

  const initialValues: ReportFormDTO = useMemo(
    () => ({
      isCardiacAssessment: values.isCardiacAssessment,
      additionalEvaluations: values?.additionalEvaluations || [],
      hasAdditionalTestsRecommendation: !!values.hasAdditionalTestsRecommendation,
      hasConsultationRecommendationWithSpecialist:
        !!values.hasConsultationRecommendationWithSpecialist,
      lifestyleActionPriorities: values.lifestyleActionPriorities,
      recommendation: values.recommendation || (recommendationOptions[0].value as Recommendation),
      referringDoctorStatus:
        values.referringDoctorStatus ||
        (referringDoctorStatusOptions[0].value as ReferringDoctorStatus),
    }),
    [
      values?.additionalEvaluations,
      values?.hasAdditionalTestsRecommendation,
      values?.hasConsultationRecommendationWithSpecialist,
      values?.referringDoctorStatus,
      values.isCardiacAssessment,
      values?.lifestyleActionPriorities,
      values?.recommendation,
    ],
  );

  const handleOnSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any> =
    useCallback(
      formValues => {
        onSubmit(formValues);
      },
      [onSubmit],
    );

  return (
    <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={schema}>
      {({ isSubmitting, isValid, setFieldValue, values }) => {
        const buttons: ButtonParams[] = [
          {
            props: {
              variant: 'contained',
              color: 'primary',
              type: 'submit',
              disabled: !isValid || isSubmitting,
            },
            label: isLoading ? (
              <CircularProgress className={classes.circularProgress} size={22} />
            ) : isUpdateMode ? (
              'Mettre à jour'
            ) : (
              'Ajouter'
            ),
          },
        ];

        return (
          <Form>
            <div className={classes.formContent}>
              <List dense component={'div'}>
                <ListItem dense classes={{ root: classes.row }} component={'div'}>
                  <Typography variant={'body1'}>{isCardiacAssessmentQuestion}</Typography>
                  <div className={classes.switchContainer}>
                    <Typography>Non</Typography>
                    <Switch
                      checked={values.isCardiacAssessment}
                      color={'primary'}
                      id="isCardiacAssessment"
                      name="isCardiacAssessment"
                      onChange={(event, checked) => {
                        setFieldValue('isCardiacAssessment', checked, true);
                      }}
                    />
                    <Typography>Oui</Typography>
                  </div>
                </ListItem>

                <Divider />

                <ListItem dense classes={{ root: classes.row }} component={'div'}>
                  <Typography variant={'body1'}>{hasReferringDoctorQuestion}</Typography>
                  <Select
                    value={values.referringDoctorStatus}
                    options={referringDoctorStatusOptions}
                    margin={'dense'}
                    variant={'outlined'}
                    color={'primary'}
                    id="hasReferringDoctor"
                    name="hasReferringDoctor"
                    placeholder=""
                    type="text"
                    className={classes.select}
                    disabled={!values.isCardiacAssessment}
                    onChange={event => {
                      setFieldValue('referringDoctorStatus', event.target.value, true);
                    }}
                  />
                </ListItem>
                <Divider />
                <ListItem dense classes={{ root: classes.column }} component={'div'}>
                  <Typography variant={'body1'}>{recommendationQuestion}</Typography>
                  <MuiSelect
                    className={classes.bigSelect}
                    classes={{ selectMenu: classes.bigSelectedMenu }}
                    value={values.recommendation}
                    margin={'dense'}
                    variant={'outlined'}
                    color={'primary'}
                    id="recommendation"
                    name="recommendation"
                    placeholder=""
                    type="text"
                    disabled={!values.isCardiacAssessment}
                    onChange={event => {
                      setFieldValue('recommendation', event.target.value, true);
                    }}
                  >
                    {recommendationOptions.map(({ label, value }: SelectOption, index) => (
                      <MenuItem
                        key={`recommendation-option-${index}`}
                        className={classes.selectItem}
                        value={value}
                      >
                        <AlertText
                          alternativePalette={true}
                          variant={'body1'}
                          color={recommendationToColor[value as Recommendation]}
                        >
                          {label}
                        </AlertText>
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </ListItem>

                <Divider />

                <ListItem dense classes={{ root: classes.row }} component={'div'}>
                  <Typography variant={'body1'}>
                    {hasConsultationRecommendationWithSpecialistQuestion}
                  </Typography>
                  <div className={classes.switchContainer}>
                    <Typography>Non</Typography>
                    <Switch
                      checked={values.hasConsultationRecommendationWithSpecialist}
                      color={'primary'}
                      id="hasConsultationRecommendationWithSpecialist"
                      name="hasConsultationRecommendationWithSpecialist"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        setFieldValue('hasConsultationRecommendationWithSpecialist', checked, true);
                      }}
                      disabled={!values.isCardiacAssessment}
                    />
                    <Typography>Oui</Typography>
                  </div>
                </ListItem>

                <Divider />
                <ListItem dense classes={{ root: classes.column }} component={'div'}>
                  <ListItem dense classes={{ root: classes.rowWithoutMargin }}>
                    <Typography variant={'body1'}>
                      {hasAdditionalTestsRecommendationQuestion}
                    </Typography>
                    <div className={classes.switchContainer}>
                      <Typography>Non</Typography>
                      <Switch
                        checked={values.hasAdditionalTestsRecommendation}
                        color={'primary'}
                        id="hasAdditionalTestsRecommendation"
                        name="hasAdditionalTestsRecommendation"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked: boolean,
                        ) => {
                          setFieldValue('hasAdditionalTestsRecommendation', checked, true);
                        }}
                        disabled={!values.isCardiacAssessment}
                      />
                      <Typography>Oui</Typography>
                    </div>
                  </ListItem>
                  {values.hasAdditionalTestsRecommendation && (
                    <ListItem dense disableGutters>
                      <MuiSelect
                        className={classes.bigSelect}
                        value={values.additionalEvaluations ?? []}
                        multiple
                        margin={'dense'}
                        variant={'outlined'}
                        color={'primary'}
                        id="additionalEvaluations"
                        name="additionalEvaluations"
                        disabled={!values.isCardiacAssessment}
                        renderValue={selected =>
                          Array.isArray(selected) &&
                          selected
                            .map(v => findOptionLabelByValue(additionalEvaluationsOptions, v))
                            .join(', ')
                        }
                        MenuProps={checkboxesMenuProps}
                        onChange={event => {
                          const fieldValue =
                            typeof event.target.value === 'string'
                              ? event.target.value.split(',')
                              : event.target.value;
                          setFieldValue('additionalEvaluations', fieldValue, true);
                        }}
                      >
                        {additionalEvaluationsOptions.map(({ value, label }: SelectOption) => {
                          return (
                            <MenuItem key={value} value={value}>
                              <Checkbox
                                checked={
                                  values.additionalEvaluations &&
                                  values.additionalEvaluations.indexOf(
                                    value as AdditionalEvaluations,
                                  ) >= 0
                                }
                              />
                              <ListItemText primary={label} />
                            </MenuItem>
                          );
                        })}
                      </MuiSelect>
                    </ListItem>
                  )}
                </ListItem>

                <Divider />

                <ListItem
                  dense
                  classes={{ root: classes.column }}
                  alignItems={'flex-start'}
                  component={'div'}
                >
                  <Typography variant={'body1'}>{lifestyleActionsLabel}</Typography>
                  <ListItem dense classes={{ root: classes.priorityRow }} alignItems={'flex-start'}>
                    <Typography variant={'body1'}>{lifestyleActionsFirstPriorityLabel}</Typography>
                    <Select
                      className={classes.prioritySelect}
                      value={values.lifestyleActionPriorities?.first}
                      options={lifestyleActionsOptions}
                      margin={'dense'}
                      variant={'outlined'}
                      color={'primary'}
                      id="lifestyleActionsFirstPriority"
                      name="lifestyleActionsFirstPriority"
                      type="text"
                      renderValue={v =>
                        findOptionLabelByValue(
                          lifestyleActionsOptions,
                          v as LifestyleAction | undefined,
                        ) || '-'
                      }
                      disabled={!values.isCardiacAssessment}
                      onChange={event => {
                        setFieldValue('lifestyleActionPriorities.first', event.target.value, true);
                      }}
                    />
                  </ListItem>
                  <ListItem
                    dense
                    classes={{ root: classes.priorityRow }}
                    alignItems={'flex-start'}
                    component={'div'}
                  >
                    <Typography variant={'body1'}>{lifestyleActionsSecondPriorityLabel}</Typography>
                    <Select
                      className={classes.prioritySelect}
                      value={values.lifestyleActionPriorities?.second}
                      options={lifestyleActionsOptions}
                      margin={'dense'}
                      variant={'outlined'}
                      color={'primary'}
                      id="lifestyleActionsSecondPriority"
                      name="lifestyleActionsSecondPriority"
                      type="text"
                      renderValue={v =>
                        findOptionLabelByValue(
                          lifestyleActionsOptions,
                          v as LifestyleAction | undefined,
                        ) || '-'
                      }
                      disabled={!values.isCardiacAssessment}
                      onChange={event => {
                        setFieldValue('lifestyleActionPriorities.second', event.target.value, true);
                      }}
                    />
                  </ListItem>
                  <ListItem
                    dense
                    classes={{ root: classes.priorityRow }}
                    alignItems={'flex-start'}
                    component={'div'}
                  >
                    <Typography variant={'body1'}>{lifestyleActionsThirdPriorityLabel}</Typography>
                    <Select
                      className={classes.prioritySelect}
                      value={values.lifestyleActionPriorities?.third}
                      options={lifestyleActionsOptions}
                      margin={'dense'}
                      variant={'outlined'}
                      color={'primary'}
                      id="lifestyleActionsThirdPriority"
                      name="lifestyleActionsThirdPriority"
                      type="text"
                      renderValue={v =>
                        findOptionLabelByValue(
                          lifestyleActionsOptions,
                          v as LifestyleAction | undefined,
                        ) || '-'
                      }
                      disabled={!values.isCardiacAssessment}
                      onChange={event => {
                        setFieldValue('lifestyleActionPriorities.third', event.target.value, true);
                      }}
                    />
                  </ListItem>
                </ListItem>
              </List>
              <ModalFooterButtons buttons={buttons} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReportForm;
