import { FieldFactType } from 'src/config/fact-types';
import { StatisticCreationFormInterface } from './index';

interface FieldInterface {
  factTypeId: string;
  key: keyof StatisticCreationFormInterface;
  label: string;
  unit: {
    label: string;
    id: string;
  };
}

export const FIELDS: FieldInterface[] = [
  {
    factTypeId: FieldFactType.weight,
    key: 'weight',
    label: 'Poids',
    unit: {
      label: 'kg',
      id: 'Kilogram',
    },
  },
  {
    factTypeId: '',
    key: 'timeElapsedAfterMeal',
    label: 'Temps écoulé depuis le dernier repas',
    unit: {
      label: 'min',
      id: '',
    },
  },
  {
    factTypeId: FieldFactType.systolicBp,
    key: 'systolicBp',
    label: 'Tension artérielle systolique',
    unit: {
      label: 'mmHg',
      id: 'MillimeterOfMercury',
    },
  },
  {
    factTypeId: FieldFactType.diastolicBp,
    key: 'diastolicBp',
    label: 'Tension artérielle diastolique',
    unit: {
      label: 'mmHg',
      id: 'MillimeterOfMercury',
    },
  },
  {
    factTypeId: FieldFactType.waistSize,
    key: 'waistSize',
    label: 'Tour de taille - Perimètre abdominal',
    unit: {
      label: 'cm',
      id: 'Centimeter',
    },
  },
  {
    factTypeId: '',
    key: 'glycemia',
    label: 'Glycémie',
    unit: {
      label: 'mg/dL',
      id: 'GramPerLiter',
    },
  },
  {
    factTypeId: FieldFactType.cholesterol,
    key: 'cholesterol',
    label: 'Cholestérol total',
    unit: {
      label: 'mg/dL',
      id: 'MillimolePerLiter',
    },
  },
  {
    factTypeId: FieldFactType.triglycerides,
    key: 'triglycerides',
    label: 'Triglycérides',
    unit: {
      label: 'mg/dL',
      id: 'GramPerLiter',
    },
  },
  {
    factTypeId: FieldFactType.hdl,
    key: 'hdl',
    label: 'HDL',
    unit: {
      label: 'mg/dL',
      id: 'GramPerLiter',
    },
  },
  {
    factTypeId: FieldFactType.ldl,
    key: 'ldl',
    label: 'LDL',
    unit: {
      label: 'mg/dL',
      id: 'GramPerLiter',
    },
  },
];
