import { useMemo } from 'react';

import { useGetLatestPatientObservationsQuery } from 'src/domains/medical-record/repository';
import { selectManifestationsFromResponse } from 'src/domains/medical-record/repository/selector/observation/manifestation';
import { PatientObservationType } from 'src/domains/medical-record/value-object/PatientObservationType';
import { STATUS_TYPE } from 'src/domains/medical-record/value-object/StatusType';
import { bindFromEntity, IManifestationPreviewVM } from '../ManifestationPreviewVM';
import { QualificationType } from 'src/domains/medical-record/entity/observation';

const useManifestationList = () => {
  const {
    isLoading,
    data = [],
    error,
  } = useGetLatestPatientObservationsQuery({
    type: PatientObservationType.DIAGNOSTICS,
    statuses: [STATUS_TYPE.PRESENT, STATUS_TYPE.ABSENT],
    qualifications: [QualificationType.DIAGNOSTIC],
  });

  const list: IManifestationPreviewVM[] = useMemo(
    () => selectManifestationsFromResponse(data).map(bindFromEntity),
    [data],
  );

  return {
    isLoading,
    list,
    error,
  };
};

export default useManifestationList;
