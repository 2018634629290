import { isNilOrEmpty } from 'ramda-adjunct';
import { Redirect, Route, Switch } from 'react-router-dom';
import React, { createElement, FunctionComponent } from 'react';

import { config, EnhancedRouteConfig } from '../routes';
import { NavigationRoute } from '../routes/navigationRoute';
import LoginPage from '../pages/Login';
import PrivateRoute from '../components/navigation/PrivateRoute';

export const renderRoutes = (routes: EnhancedRouteConfig[]) => {
  if (isNilOrEmpty(routes)) return null;

  return (
    <Switch>
      {routes.map((route, i) => (
        <PrivateRoute
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          render={props =>
            route.render
              ? route.render({ ...props, route: route })
              : // @ts-ignore
                createElement(route.component, { ...props, route: route })
          }
        />
      ))}
      <Route path={NavigationRoute.LOGIN} exact={true} component={LoginPage} />
      <Redirect from="*" to={NavigationRoute.PATIENTS} />
    </Switch>
  );
};

const App: FunctionComponent = () => renderRoutes(config);

export default App;
