import { useCallback } from 'react';

import { EventCreationDTO } from '../../dto';
import {
  CreatePatientEventRequestDTO,
  useCreatePatientEventMutation,
  useForcePatientEventCreationMutation,
  useReplacePatientEventMutation,
} from '../../repository';
import { SubmitAction } from '../../../../pages/PatientDashboard/PatientDashboardUpperSection/MedicalRecord/Modals/PersistenceModal/PersistenceForm/constants';

const useUpdatePatientEventUseCase = () => {
  const [
    createEvent,
    {
      isLoading: isPatientEventCreationLoading,
      isSuccess: isPatientEventCreationSuccess,
      error: patientEventCreationError,
    },
  ] = useCreatePatientEventMutation();

  const [
    forcePatientEventCreation,
    {
      isLoading: isForcePatientEventCreationLoading,
      isSuccess: isForcePatientEventCreationSuccess,
      error: forcePatientEventCreationError,
    },
  ] = useForcePatientEventCreationMutation();

  const [
    replacePatientEvent,
    {
      isLoading: isReplacePatientEventLoading,
      isSuccess: isReplacePatientEventSuccess,
      error: replacePatientEventError,
    },
  ] = useReplacePatientEventMutation();

  const updateEvent = useCallback(
    (
      patientId: string,
      recordId: string,
      dto: EventCreationDTO,
      submitAction: SubmitAction | undefined = SubmitAction.ADD_OR_UPDATE,
    ) => {
      const requestDto: CreatePatientEventRequestDTO = { body: dto };

      if (submitAction === SubmitAction.ADD_OR_UPDATE) createEvent(requestDto);
      if (submitAction === SubmitAction.REPLACE) replacePatientEvent(requestDto);
      if (submitAction === SubmitAction.FORCE_CREATION) forcePatientEventCreation(requestDto);
    },
    [createEvent, forcePatientEventCreation, replacePatientEvent],
  );

  return {
    updateEvent,
    isCreationLoading: isPatientEventCreationLoading,
    isCreationSuccess: isPatientEventCreationSuccess,
    isReplaceLoading: isReplacePatientEventLoading,
    isReplaceSuccess: isReplacePatientEventSuccess,
    isForceCreationLoading: isForcePatientEventCreationLoading,
    isForceCreationSuccess: isForcePatientEventCreationSuccess,
    isSuccess:
      isForcePatientEventCreationSuccess ||
      isReplacePatientEventSuccess ||
      isPatientEventCreationSuccess,
    error: patientEventCreationError || forcePatientEventCreationError || replacePatientEventError,
  };
};

export default useUpdatePatientEventUseCase;
