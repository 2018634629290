import { useToggle } from 'react-use';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { useTableColumn } from './columns';

import { IBehaviourPreviewVM } from '../BehaviourPreviewVM';
import BehaviourHistoryModal from '../History';
import { BehaviourModificationModalContent } from '../Update';
import Section from '../../../Section';
import useBehaviourList from './hook';
import ObservationDeletionModal from '../../DeletionModal';
import PersistenceModal from '../../../Modals/PersistenceModal';

const BehaviourSection: FunctionComponent = () => {
  const { isLoading, list } = useBehaviourList();

  const [visualModel, setVisualModel] = useState<IBehaviourPreviewVM | undefined>(undefined);
  const [isHistoryModalOpened, toggleHistoryModal] = useToggle(false);
  const [isModificationModalOpened, toggleModificationModal] = useToggle(false);
  const [isDeletionModalOpened, toggleDeletionModal] = useToggle(false);
  /* const [isCreationModalOpened, toggleCreationModal] = useToggle(false);

  const onCreationClick = useCallback(() => {
    toggleCreationModal();
  }, [toggleCreationModal]);*/

  const onModificationClick = useCallback(
    (vm: IBehaviourPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleModificationModal();
      }
    },
    [toggleModificationModal],
  );

  const onHistoryClick = useCallback(
    (vm: IBehaviourPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleHistoryModal();
      }
    },
    [toggleHistoryModal],
  );

  const onDeletionClick = useCallback(
    (vm: IBehaviourPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleDeletionModal();
      }
    },
    [toggleDeletionModal],
  );

  const columns = useTableColumn({
    handleHistoryClick: onHistoryClick,
    handleDeleteClick: onDeletionClick,
    handleEditClick: onModificationClick,
  });

  const defaultSort = useMemo(
    () => [
      {
        id: 'creationDate',
        desc: true,
      },
    ],
    [],
  );

  return (
    <>
      <Section
        columns={columns}
        data={list}
        // onAddButtonClick={onCreationClick}
        title={'Comportements déclarés'}
        isLoading={isLoading}
        defaultSort={defaultSort}
      />
      {/* <PersistenceModal
        contentType={ManifestationCreationModal}
        onClose={toggleCreationModal}
        open={isCreationModalOpened}
        patientId={patientId}
        selectedRecord={visualModel}
        title={'Ajouter un comportement'}
        stepLabels={['Sélection du problème médical', 'Spécification du comportement']}
      />*/}
      {visualModel && (
        <PersistenceModal
          title={'Mettre à jour un comportement'}
          onClose={toggleModificationModal}
          open={isModificationModalOpened}
          contentType={BehaviourModificationModalContent}
          selectedRecord={visualModel}
        />
      )}
      {visualModel && (
        <ObservationDeletionModal
          recordId={visualModel.id}
          onClose={toggleDeletionModal}
          open={isDeletionModalOpened}
          title={'Supprimer le comportement'}
        />
      )}
      {visualModel && (
        <BehaviourHistoryModal
          manifestation={visualModel}
          onClose={toggleHistoryModal}
          open={isHistoryModalOpened}
        />
      )}
    </>
  );
};

BehaviourSection.displayName = 'BehaviourSection';

export default BehaviourSection;
