import { Alert } from '@material-ui/lab';
import { CircularProgress, Typography } from '@material-ui/core';
import React, { FunctionComponent, memo, useCallback } from 'react';

import useStyles from './styles';
import ModalFooterButtons, { ButtonParams } from 'src/components/common/Modal/ModalFooterButtons';
import { SubmitAction } from '../constants';

interface CreationConflictProps {
  isCreating: boolean;
  isReplacing: boolean;
  isForceCreating: boolean;
  onCancelClick: () => void;
  onSubmit: (submitAction: SubmitAction) => void;
}

const CreationConflict: FunctionComponent<CreationConflictProps> = memo(
  ({
    isCreating = false,
    isForceCreating = false,
    isReplacing = false,
    onCancelClick,
    onSubmit,
  }) => {
    const classes = useStyles();

    const buttons = useCallback(
      (areDisabled: boolean = false): ButtonParams[] => [
        {
          props: { variant: 'outlined', color: 'default', onClick: onCancelClick },
          label: 'Annuler',
        },
        {
          props: {
            variant: 'contained',
            color: 'primary',
            type: 'submit',
            onClick: () => onSubmit(SubmitAction.REPLACE),
            disabled: areDisabled,
          },
          label: isReplacing ? (
            <CircularProgress className={classes.circularProgress} size={22} />
          ) : (
            'Remplacer'
          ),
        },
        {
          props: {
            variant: 'contained',
            color: 'primary',
            type: 'submit',
            onClick: () => onSubmit(SubmitAction.FORCE_CREATION),
            disabled: areDisabled,
          },
          label: isForceCreating ? (
            <CircularProgress className={classes.circularProgress} size={22} />
          ) : (
            'Ajouter'
          ),
        },
      ],
      [classes.circularProgress, isForceCreating, isReplacing, onCancelClick, onSubmit],
    );

    return (
      <div className={classes.errorContainer}>
        <Alert
          icon={false}
          variant={'outlined'}
          severity={'error'}
          style={{ width: 'fit-content' }}
        >
          <div className={classes.errorContainer}>
            <div className={classes.errorTitle}>
              <Typography variant={'h5'}>
                Une donnée médicale à la même date existe déjà !
              </Typography>
            </div>
            <Typography variant={'body1'}>
              Attention une donnée existe déjà sur les dates que vous avez indiqué. Souhaitez-vous
              remplacer la donnée existante ou forcer l'ajout de la nouvelle donnée ?
            </Typography>

            <ModalFooterButtons buttons={buttons(isForceCreating || isCreating || isReplacing)} />
          </div>
        </Alert>
      </div>
    );
  },
);

export default CreationConflict;
