import { CellProps } from 'react-table';

import { differenceInYears, format } from 'date-fns';
import { Patient } from 'src/domains/patient/types';
import { toTitleCase } from 'src/helpers/format/string';
import TextField from '../../components/common/Table/TextField';

export const protocolType: string = 'qualification.label';
export const columns: any[] = [
  {
    Header: 'NOM Prénom',
    accessor: 'firstName',
    Cell: (props: CellProps<Patient>) => {
      const firstName = props.row.values.firstName;
      const lastName = props.row.original?.lastName;

      return <>{`${lastName && lastName.toUpperCase()} ${toTitleCase(firstName)} `}</>;
    },
  },
  {
    Header: 'N° RCU',
    accessor: 'externalId',
    Cell: (props: CellProps<Patient>) => {
      const rcu = props.cell.value;

      return <TextField label={rcu} variant={'body1'} />;
    },
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    Cell: (props: CellProps<Patient>) => {
      const email = props.cell.value;

      return <TextField label={email} variant={'body1'} />;
    },
  },
  {
    Header: 'Age (date de naissance)',
    accessor: 'birthDate',
    Cell: (props: CellProps<Patient>) => {
      const birthDate: Date = props.cell.value;

      if (!birthDate) return <TextField />;

      const years = `${differenceInYears(Date.now(), new Date(birthDate))} ans`;
      const label = `${years} (${format(new Date(birthDate), 'dd/MM/yyyy')})`;

      return <TextField label={label} />;
    },
  },
  {
    Header: 'Genre',
    accessor: 'gender',
    Cell: (props: CellProps<any>) => {
      const gender = props.cell.value;
      const label = gender === 'MALE' ? 'Homme' : gender === 'FEMALE' ? 'Femme' : '-';

      return <TextField label={label} variant={'body1'} />;
    },
  },
];
