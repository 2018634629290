import React, { FunctionComponent } from 'react';
import { Theme } from '@material-ui/core/styles';

const PDFThemeContext = React.createContext<Theme | null>(null);

export interface PDFThemeContextProviderProps {
  theme: Theme;
}

export const PDFThemeContextProvider: FunctionComponent<PDFThemeContextProviderProps> = ({
  children,
  theme,
}) => {
  return <PDFThemeContext.Provider value={theme}>{children}</PDFThemeContext.Provider>;
};

export const usePDFThemeContext = () => {
  const context = React.useContext(PDFThemeContext);
  if (!context) {
    throw new Error('usePDFThemeContext must be used within a PDFThemeContextProvider');
  }
  return context;
};
