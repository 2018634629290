import { Button, CircularProgress, List, ListItem, TextField, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

import { FormikConfig, useFormik } from 'formik';
import { NavigationRoute } from 'src/routes/navigationRoute';
import { schema, LoginPassword } from './schema';
import useStyles from './styles';
import NominalContainer from '../../../components/common/NominalContainer';

type CredentialsFormProps = {
  onSubmit: FormikConfig<LoginPassword>['onSubmit'];
  isLoading: boolean;
  login?: string;
  errorMessage?: string;
  remainingAttempts?: number;
};

const CredentialsForm: FunctionComponent<CredentialsFormProps> = ({
  onSubmit,
  isLoading = false,
  login = '',
  errorMessage,
  remainingAttempts,
}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      login,
      password: '',
    },
    validationSchema: schema,
    onSubmit,
  });

  return (
    <NominalContainer title={'Identification'}>
      <form onSubmit={formik.handleSubmit}>
        <List className={classes.list} disablePadding>
          <ListItem disableGutters>
            <TextField
              variant={'outlined'}
              color={'primary'}
              hiddenLabel
              fullWidth
              id="login"
              name="login"
              placeholder="Votre identifiant"
              value={formik.values.login}
              onChange={formik.handleChange}
              error={formik.touched.login && Boolean(formik.errors.login)}
              helperText={formik.touched.login && formik.errors.login}
            />
          </ListItem>
          <ListItem disableGutters>
            <TextField
              variant={'outlined'}
              color={'primary'}
              hiddenLabel
              fullWidth
              id="password"
              name="password"
              placeholder="Votre mot de passe"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </ListItem>
          <ListItem disableGutters>
            <div className={classes.passwordForgottenContainer}>
              <NavLink to={NavigationRoute.PASSWORD_FORGOTTEN} className={classes.link}>
                <Typography variant={'body1'}>Mot de passe oublié</Typography>
              </NavLink>
            </div>
          </ListItem>
        </List>

        <div className={classes.errorMessage}>
          {errorMessage && (
            <Typography variant={'body1'} color={'error'}>
              {errorMessage}
            </Typography>
          )}
          {remainingAttempts && (
            <Typography variant={'body1'} color={'error'}>
              Tentatives restantes: {remainingAttempts}
            </Typography>
          )}
        </div>

        <Button
          color={'primary'}
          fullWidth
          size={'large'}
          variant={'contained'}
          type={'submit'}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Se connecter'}
        </Button>
      </form>
    </NominalContainer>
  );
};

export default CredentialsForm;
