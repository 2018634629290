import { useCallback } from 'react';

import { AnswerType } from 'src/domains/medical-record/value-object/AnswerType';
import { DiagnosticModification } from './schema';
import { IDiagnosticPreviewVM } from '../../DiagnosticPreviewVM';
import { ObservationCreationDTO } from 'src/domains/medical-record/dto';
import useUpdateObservationUseCase from 'src/domains/medical-record/use-case/observation/useUpdateObservationUseCase';
import { SubmitAction } from '../../../../Modals/PersistenceModal/PersistenceForm/constants';

const useUpdateDiagnostic = (patientId: string, diagnostic: IDiagnosticPreviewVM) => {
  const { updateObservation: update, ...rest } = useUpdateObservationUseCase();

  const updateDiagnostic = useCallback(
    (modificationDiagnostic: DiagnosticModification, submitAction?: SubmitAction) => {
      const newDiagnostic: ObservationCreationDTO = {
        answerType: AnswerType.DOCTOR,
        endDate: new Date(),
        observationDescriptionId: diagnostic.descriptionId,
        startDate: new Date(),
        status: modificationDiagnostic.status,
      };

      update(patientId, diagnostic.id, newDiagnostic, submitAction);
    },
    [diagnostic.descriptionId, diagnostic.id, patientId, update],
  );

  return {
    updateDiagnostic,
    ...rest,
  };
};

export default useUpdateDiagnostic;
