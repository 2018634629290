import { useDeletePatientObservationMutation } from '../../repository';

const useDeletePatientObservationUseCase = () => {
  const [deletePatientObservation, { isLoading, isSuccess, data, error }] =
    useDeletePatientObservationMutation();

  return { deletePatientObservation, data, isLoading, isSuccess, error };
};

export default useDeletePatientObservationUseCase;
