import React, { FunctionComponent } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { useStyles } from './styles';

export interface PDFTitleProps {
  title: string;
}

export const PDFTitle: FunctionComponent<PDFTitleProps> = ({ title }) => {
  const styles = useStyles();
  return (
    <View style={styles.root}>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};
