import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles<Theme>(() => ({
  widthAuto: {
    width: 'auto',
  },
  bold: {
    fontWeight: 'bold',
  },
}));
