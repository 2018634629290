const colors = {
  INDIGO: { main: '#495285', light: '#979bb0', dark: '#313863' },
  TEAL: { main: '#2cc4cb', light: '#73f7dd', dark: '#216583' },
  CYAN: { main: '#e0eef2', light: '#f1f9fb' },
  LIGHT_BLUE: { main: '#2699fb', light: '#bedbe3' },
  AMBER: { main: '#e9b72c' },
  PINK: { main: '#fc0771' },
  BLACK: '#000000',
  WHITE: '#ffffff',
};

export default colors;
