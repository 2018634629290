import { Button, CircularProgress, Typography } from '@material-ui/core';
import React, { FunctionComponent, useEffect } from 'react';
import useStyles from './styles';
import NominalContainer from '../../../components/common/NominalContainer';
import { useTrustMeMutation } from 'src/domains/authentication/api';

type TrustBrowserProps = {
  onClose: () => void;
};

const TrustBrowser: FunctionComponent<TrustBrowserProps> = ({ onClose }) => {
  const classes = useStyles();

  const [trustBrowser, { isLoading, isSuccess }] = useTrustMeMutation();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      onClose();
    }
  }, [isLoading, isSuccess, onClose]);

  return (
    <NominalContainer title={'Se fier au navigateur ?'}>
      <div className={classes.processDetail}>
        <Typography color={'primary'} variant={'body1'}>
          Si vous décidez de faire confiance à ce navigateur, vous n’aurez pas à fournir de code de
          validation lors de votre prochaine connexion.
        </Typography>
      </div>
      <div className={classes.footerContainer}>
        <div className={classes.footerButton}>
          <Button variant={'outlined'} color={'primary'} onClick={onClose}>
            Non
          </Button>
        </div>
        <div className={classes.footerButton}>
          <Button
            variant={'contained'}
            color={'primary'}
            disabled={isLoading}
            onClick={() => trustBrowser()}
          >
            {isLoading ? <CircularProgress size={22} /> : 'Se fier'}
          </Button>
        </div>
      </div>
    </NominalContainer>
  );
};

export default TrustBrowser;
