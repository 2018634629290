import React, { FunctionComponent } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { useStyles } from './styles';

export interface PDFListItem {
  text: string;
  value: string | number;
}

export interface PDFListProps {
  title: string;
  listValues: Array<PDFListItem>;
}

export const PDFList: FunctionComponent<PDFListProps> = ({ title, listValues }) => {
  const styles = useStyles();
  return Boolean(listValues.length) ? (
    <View style={styles.root}>
      <View style={styles.header}>
        <Text style={styles.title}>{title}</Text>
      </View>
      {Boolean(listValues.length) && (
        <View style={styles.listContainer}>
          {listValues.map(({ text, value }) => (
            <View key={`pdf-list-${text}-${value}`} style={styles.listItem}>
              <Text style={styles.listItemText}>{text}</Text>
              <Text style={styles.listItemValue}>{value}</Text>
            </View>
          ))}
        </View>
      )}
    </View>
  ) : null;
};
