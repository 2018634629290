import { SchemaOf } from 'yup';
import * as yup from 'yup';
import {
  AdditionalEvaluations,
  LifestyleAction,
  Recommendation,
  ReferringDoctorStatus,
} from '../../../../../../domains/patient/report/types';
import ReportFormDTO from './dto';

export const schema: SchemaOf<ReportFormDTO> = yup.object().shape({
  isCardiacAssessment: yup.boolean().default(true),
  additionalEvaluations: yup
    .array()
    .default([])
    .when(['isCardiacAssessment', 'hasAdditionalTestsRecommendation'], {
      is: (isCardiacAssessment: boolean, hasAdditionalTestsRecommendation: boolean) =>
        isCardiacAssessment && hasAdditionalTestsRecommendation,
      then: yup
        .array()
        .of(yup.mixed().oneOf([...Object.values(AdditionalEvaluations), undefined]))
        .required('Veuillez sélectionner au moins une option'),
      otherwise: yup.array().notRequired(),
    }),
  hasAdditionalTestsRecommendation: yup.boolean(),
  hasConsultationRecommendationWithSpecialist: yup.boolean(),
  lifestyleActionPriorities: yup.object().shape({
    first: yup.mixed().oneOf([...Object.values(LifestyleAction), undefined]),
    second: yup.mixed().oneOf([...Object.values(LifestyleAction), undefined]),
    third: yup.mixed().oneOf([...Object.values(LifestyleAction), undefined]),
  }),
  referringDoctorStatus: yup.mixed().oneOf([...Object.values(ReferringDoctorStatus), undefined]),
  recommendation: yup
    .mixed()
    .oneOf([...Object.values(Recommendation), undefined])
    .when('isCardiacAssessment', {
      is: true,
      then: yup.string().required('Veuillez sélectionner une réponse'),
      otherwise: yup.string().notRequired(),
    }),
});

export default schema;
