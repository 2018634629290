import React, { FunctionComponent, useCallback, useEffect } from 'react';

import { DeleteRecordRequestDTO } from 'src/domains/medical-record/repository';
import { ModalProps } from 'src/components/common/Modal';
import DeleteModal from '../../Modals/DeleteModal';
import useDeletePatientFactUseCase from 'src/domains/medical-record/use-case/fact/useDeletePatientFactUseCase';

interface FactDeletionModalProps extends ModalProps {
  title: string;
  recordId: string;
}

const FactDeletionModal: FunctionComponent<FactDeletionModalProps> = ({
  onClose,
  recordId,
  title,
  ...modalProps
}) => {
  const { deleteFact, isLoading, isSuccess } = useDeletePatientFactUseCase();

  const onDelete = useCallback(() => {
    const dto: DeleteRecordRequestDTO = {
      recordId,
    };

    deleteFact(dto);
  }, [deleteFact, recordId]);

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess, onClose]);

  return (
    <DeleteModal
      title={title}
      onDelete={onDelete}
      isLoading={isLoading}
      onClose={onClose}
      {...modalProps}
    />
  );
};

export default FactDeletionModal;
