import { useMemo } from 'react';

import {
  useGetPatientDiagnosticHistoryQuery,
  useGetPatientObservationsQuery,
} from '../../../repository';
import { GetPatientObservationsRequestDTO } from '../../../repository/dto/GetPatientObservationsRequestDTO';
import { PatientObservationType } from '../../../value-object/PatientObservationType';

interface UseGetPatientDiagnosticHistoryUseCaseParams {
  observationDescriptionId: string;
  familyRelationshipId?: string;
}

const useGetPatientDiagnosticHistoryUseCase = (
  params: UseGetPatientDiagnosticHistoryUseCaseParams,
) => {
  const { isLoading, data, error } = useGetPatientDiagnosticHistoryQuery(params);

  return { data, isLoading, error };
};

export const useGetPatientDiagnosticHistory = (
  params: Omit<GetPatientObservationsRequestDTO, 'type'>,
) => {
  const requestDTO: GetPatientObservationsRequestDTO = useMemo<GetPatientObservationsRequestDTO>(
    () => ({
      ...params,
      type: PatientObservationType.DIAGNOSTICS,
    }),
    [params],
  );

  const { isLoading, data, error } = useGetPatientObservationsQuery(requestDTO);

  return { data, isLoading, error };
};

export default useGetPatientDiagnosticHistoryUseCase;
