import { useMemo } from 'react';

import {
  PatientObservation,
  QualificationType,
} from 'src/domains/medical-record/entity/observation';
import { STATUS_TYPE } from 'src/domains/medical-record/value-object/StatusType';
import { useGetAllPatientObservationsQuery } from '../../../repository';
import { SubjectType } from 'src/domains/medical-record/value-object/SubjectType';

const useGetRisksAndSuspicionsUseCase = () => {
  const {
    data = [],
    isLoading,
    error,
  } = useGetAllPatientObservationsQuery({
    statuses: [STATUS_TYPE.PRESENT, STATUS_TYPE.ABSENT],
  });

  const riskAndSuspicionObservation: PatientObservation[] = useMemo(
    () =>
      data.filter(
        observation =>
          [QualificationType.DIAGNOSTIC].includes(
            observation.observationDescription.qualification,
          ) &&
          observation.observationDescription.evolvesFrom !== null &&
          observation.subject.label === SubjectType.REPERE,
      ),
    [data],
  );

  return { data: riskAndSuspicionObservation, isLoading, error };
};

export default useGetRisksAndSuspicionsUseCase;
