import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';

import { useGetCurrentUserQuery } from 'src/domains/user/repository';
import authenticationService from 'src/services/authentication';

const useGetConnectedUser = (hasFetchUserCondition: boolean = false) => {
  const {
    data: connectedUser,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useGetCurrentUserQuery(!hasFetchUserCondition && skipToken);

  useEffect(() => {
    if (!isLoading && !isError && isSuccess) {
      if (connectedUser) {
        authenticationService.setOrUpdateUser(connectedUser);
        authenticationService.setOrUpdateOrganizationId(
          connectedUser.organizationConfiguration.organizationId,
        );
      }
    }
  }, [isLoading, isError, isSuccess, connectedUser]);

  return { data: connectedUser, isLoading, isError, isSuccess, error };
};

export default useGetConnectedUser;
