import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { isNilOrEmpty, isString } from 'ramda-adjunct';

import authenticationService from './authentication';

// engine
export const engineHttpClient = fetchBaseQuery({
  baseUrl: '/engine',
  mode: 'cors',
  prepareHeaders: headers => {
    const token = authenticationService.getAccessTokenFromStorage();
    if (!authenticationService.isAccessTokenExpired() && isString(token)) {
      headers.set('Authorization', `Bearer ${token}`);
    } else {
      headers.delete('Authorization');
    }
    return headers;
  },
});

const engineBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await engineHttpClient(args, api, extraOptions);
  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    const user = authenticationService.getUser();
    const organizationId = authenticationService.getOrganizationId();

    if (isNilOrEmpty(user) || isNilOrEmpty(organizationId)) {
      authenticationService.logoutWithLocationState();
      return result;
    }
    // const response = await userAdminHttpClient(
    //   {
    //     url: '/api/v1/accounts/tokens',
    //     method: 'POST',
    //   },
    //   api,
    //   {},
    // );

    // if (response.error && (response.error.status === 401 || response.error.status === 403)) {
    //   authenticationService.logoutWithLocationState();
    //   return result;
    // }

    // try {
    //   // @ts-ignore
    //   if (response.data && response.data.token) {
    //     const tokensResponse = response.data as TokensResponse;
    //     authenticationService.setOrUpdateAccessToken(tokensResponse.token as string);
    //   }
    // } catch (e) {}

    // result = await engineHttpClient(args, api, extraOptions);
  }
  return result;
};

export default engineBaseQuery;
