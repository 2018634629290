import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  processDetail: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  countdownMessageContainer: {
    paddingTop: theme.spacing(2),
  },
  bold: {
    fontWeight: 'bold',
  },
}));
