import { useMemo } from 'react';
import { QualificationType } from '../../entity/observation';
import {
  PatientAntecedent,
  bindFromPatientEventAndType,
  bindFromPatientObservation,
} from '../../entity/patientAntecedent';
import {
  useGetLatestPatientEventsQuery,
  useGetLatestPatientObservationsQuery,
  useGetPatientEventAntecedentsQuery,
} from '../../repository';
import { GetLatestPatientEventsRequestDTO } from '../../repository/dto/GetLatestPatientEventsRequestDTO';
import { GetLatestPatientObservationsRequestDTO } from '../../repository/dto/GetLatestPatientObservationsRequestDTO';
import { PatientEventType } from '../../value-object/PatientEventType';
import { PatientObservationType } from '../../value-object/PatientObservationType';
import { STATUS_TYPE } from '../../value-object/StatusType';
import { SubjectType } from '../../value-object/SubjectType';
import { selectPatientObservationsFromGetLatestPatientObservationsResponse } from '../../repository/selector/observation/observation';
import { selectAntecedentDiagnosticsFromResponse } from '../../repository/selector/antecedent';

const useGetPatientDiagnosticAntecedentUseCase = () => {
  const {
    isLoading,
    data = [],
    error,
  } = useGetLatestPatientObservationsQuery({
    // type: [PatientObservationType.ANTECEDENTS, PatientObservationType.DIAGNOSTICS],
    type: PatientObservationType.DIAGNOSTICS,
    statuses: [STATUS_TYPE.PRESENT, STATUS_TYPE.ABSENT],
    qualifications: [QualificationType.DIAGNOSTIC],
  });

  const list = useMemo(() => selectAntecedentDiagnosticsFromResponse(data), [data]);

  return {
    isLoading,
    error,
    data: list,
  };
};

const useGetPatientAntecedentsUseCase = () => {
  const {
    isLoading: isGetPatientEventRequestLoading,
    data: patientEventAndTypes = [],
    error: patientEventRequestError,
  } = useGetPatientEventAntecedentsQuery();

  const {
    isLoading: isGetPatientDiagnosticRequestLoading,
    data: patientDiagnostics = [],
    error: patientDiagnosticRequestError,
  } = useGetPatientDiagnosticAntecedentUseCase();

  const patientEventAntecedents: PatientAntecedent[] = useMemo(
    () => patientEventAndTypes.map(bindFromPatientEventAndType),
    [patientEventAndTypes],
  );
  const patientDiagnosticAntecedents: PatientAntecedent[] = useMemo(
    () =>
      patientDiagnostics.filter(p => !!p.familyRelationship?.id).map(bindFromPatientObservation),
    [patientDiagnostics],
  );

  const data = useMemo(
    () => [...patientEventAntecedents, ...patientDiagnosticAntecedents],
    [patientDiagnosticAntecedents, patientEventAntecedents],
  );

  return {
    data,
    isLoading: isGetPatientEventRequestLoading || isGetPatientDiagnosticRequestLoading,
    error: patientEventRequestError || patientDiagnosticRequestError,
  };
};

export const useGetPatientAntecedents = (patientId: string) => {
  const eventAntecedentRequestDTO: GetLatestPatientEventsRequestDTO = useMemo(
    () => ({
      patientId,
      allowFamilyRelationship: true,
      subjects: [SubjectType.NOMDEPRESCRIPTION, SubjectType.ACTE, SubjectType.CONSULTATION],
      type: PatientEventType.ANTECEDENTS,
    }),
    [patientId],
  );

  const observationAntecedentRequestDTO: GetLatestPatientObservationsRequestDTO = useMemo(
    () => ({
      patientId,
      qualifications: [QualificationType.DIAGNOSTIC],
      subjects: [
        SubjectType.PATHOLOGIE,
        SubjectType.AFFECTION,
        SubjectType.SYNDROME,
        SubjectType.REACTION,
      ],
      statuses: [STATUS_TYPE.PRESENT],
      type: PatientObservationType.ANTECEDENTS,
    }),
    [patientId],
  );

  const {
    isLoading: isGetPatientEventRequestLoading,
    isError: isGetPatientEventRequestError,
    data: patientEventAndTypes = [],
    error: patientEventRequestError,
  } = useGetLatestPatientEventsQuery(eventAntecedentRequestDTO);

  const {
    isLoading: isGetPatientDiagnosticRequestLoading,
    isError: isGetPatientDiagnosticRequestError,
    data: patientDiagnostics = [],
    error: patientDiagnosticRequestError,
  } = useGetLatestPatientObservationsQuery(observationAntecedentRequestDTO);

  const patientEventAntecedents: PatientAntecedent[] = useMemo(
    () => patientEventAndTypes.map(bindFromPatientEventAndType),
    [patientEventAndTypes],
  );
  const patientDiagnosticAntecedents: PatientAntecedent[] = useMemo(
    () =>
      selectPatientObservationsFromGetLatestPatientObservationsResponse(patientDiagnostics).map(
        bindFromPatientObservation,
      ),
    [patientDiagnostics],
  );

  const data = useMemo(
    () => [...patientEventAntecedents, ...patientDiagnosticAntecedents],
    [patientDiagnosticAntecedents, patientEventAntecedents],
  );

  const isLoading: boolean = useMemo(
    () => isGetPatientDiagnosticRequestLoading || isGetPatientEventRequestLoading,
    [isGetPatientDiagnosticRequestLoading, isGetPatientEventRequestLoading],
  );

  const isError: boolean = useMemo(
    () => isGetPatientDiagnosticRequestError || isGetPatientEventRequestError,
    [isGetPatientDiagnosticRequestError, isGetPatientEventRequestError],
  );

  const error = useMemo(
    () => patientEventRequestError || patientDiagnosticRequestError,
    [patientEventRequestError, patientDiagnosticRequestError],
  );

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

export default useGetPatientAntecedentsUseCase;
