import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  {
    breadcrumbs: {
      marginBottom: 26,
    },
  },
  { name: 'Breadcrumbs' },
);
