import { format } from 'date-fns';
import { SchemaOf } from 'yup';
import * as yup from 'yup';

import { STATUS_TYPE, StatusType } from 'src/domains/medical-record/value-object/StatusType';

export type AntecedentCreation = {
  startDate?: Date;
  familyRelationshipId?: string;
  status: StatusType;
};

export const schema: SchemaOf<AntecedentCreation> = yup.object({
  startDate: yup
    .date()
    .max(
      new Date(new Date().setHours(23, 59, 59, 59)),
      `Veuillez saisir une date inférieure ou égale au ${format(new Date(), 'dd/MM/yyyy')}`,
    )
    .required('Information requise')
    .typeError('Format incorrect: veuillez saisir une date sous la forme jj/mm/aaaa'),
  familyRelationshipId: yup.string(),
  status: yup.mixed().oneOf(Object.values(STATUS_TYPE)).required('Information requise'),
});

export default schema;
