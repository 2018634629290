import { SchemaOf } from 'yup';
import * as yup from 'yup';

export type LoginPassword = {
  login: string;
  password: string;
};

export const schema: SchemaOf<LoginPassword> = yup.object({
  login: yup.string().email('Saisissez un identifiant valide').required('Identifiant requis'),
  password: yup
    .string()
    .min(4, 'Le mot de passe doit contenir au minimum 4 caractères')
    .required('Mot de passe requis'),
});
