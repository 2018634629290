import { useCallback } from 'react';

import { AnswerType } from 'src/domains/medical-record/value-object/AnswerType';
import { IFactPreviewVM } from '../../../FactPreviewVM';
import { FactCreationDTO } from 'src/domains/medical-record/dto';
import { StatisticCreation } from '../../StatisticForm/schema';
import useUpdatePatientFactUseCase from 'src/domains/medical-record/use-case/fact/useUpdatePatientFactUseCase';
import { SubmitAction } from '../../../../Modals/PersistenceModal/PersistenceForm/constants';

const useUpdateStatistic = (visualModel: IFactPreviewVM) => {
  const { updateFact, ...rest } = useUpdatePatientFactUseCase();

  const update = useCallback(
    (statistic: StatisticCreation, submitAction = SubmitAction.ADD_OR_UPDATE) => {
      const creationDTO: FactCreationDTO = {
        answerType: AnswerType.DOCTOR,
        factTypeId: visualModel.typeId,
        endDate: new Date(),
        startDate: new Date(),
        status: statistic.status,
        value: statistic.value,
        measurementUnit: visualModel.measurementUnit,
      };

      updateFact(visualModel.id, creationDTO, submitAction);
    },
    [updateFact, visualModel.id, visualModel.measurementUnit, visualModel.typeId],
  );

  return {
    update,
    ...rest,
  };
};

export default useUpdateStatistic;
