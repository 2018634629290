import { useToggle } from 'react-use';
import React, { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';

import { useTableColumn } from './columns';

import { ReportModificationModalContent } from '../Update';
import { IReportPreviewVM } from '../ReportPreviewVM';
import ReportCreationModalContent from '../Create';
import Section from '../../Section';
import usePatientReportList from './hook';
import PersistenceModal from '../../Modals/PersistenceModal';
import ReportDeletionModal from '../DeletionModal';
import usePDFReport from '../ReportForm/PDFReport/hook';

type ReportSectionProps = {
  externalId: string;
};

const ReportSection: FunctionComponent<ReportSectionProps> = memo(({ externalId }) => {
  const { isLoading, list } = usePatientReportList(externalId);

  const [visualModel, setVisualModel] = useState<IReportPreviewVM | undefined>(undefined);

  const { downloadPDF } = usePDFReport();
  const [isModificationModalOpened, toggleModificationModal] = useToggle(false);
  const [isDeletionModalOpened, toggleDeletionModal] = useToggle(false);
  const [isCreationModalOpened, toggleCreationModal] = useToggle(false);

  const onCreationClick = useCallback(() => toggleCreationModal(), [toggleCreationModal]);

  const onModificationClick = useCallback(
    (vm: IReportPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleModificationModal();
      }
    },
    [toggleModificationModal],
  );

  const onDeletionClick = useCallback(
    (vm: IReportPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleDeletionModal();
      }
    },
    [toggleDeletionModal],
  );

  const columns = useTableColumn({
    handleEditClick: onModificationClick,
    handleDeleteClick: onDeletionClick,
    handleGeneratePDFClick: downloadPDF,
  });

  const defaultSort = useMemo(() => [{ id: 'createdDate', desc: true }], []);

  const reportAlreadyPresented = useMemo(() => list.length > 0, [list.length]);

  return (
    <>
      <Section
        columns={columns}
        data={list}
        title={'Compte rendu'}
        isLoading={isLoading}
        defaultSort={defaultSort}
        onAddButtonClick={onCreationClick}
        disableAddButton={reportAlreadyPresented}
      />
      <PersistenceModal
        contentType={ReportCreationModalContent}
        onClose={toggleCreationModal}
        open={isCreationModalOpened}
        title={'Ajouter un compte rendu'}
        selectedRecord={{ externalId }}
      />

      {visualModel && (
        <PersistenceModal
          title={'Mettre à jour le compte rendu'}
          onClose={toggleModificationModal}
          open={isModificationModalOpened}
          contentType={ReportModificationModalContent}
          selectedRecord={visualModel}
        />
      )}
      {visualModel && (
        <ReportDeletionModal
          onClose={toggleDeletionModal}
          open={isDeletionModalOpened}
          externalId={externalId}
          title={'Supprimer le compte rendu'}
        />
      )}
    </>
  );
});

ReportSection.displayName = 'ReportSection';

export default ReportSection;
