import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  DatabaseReport,
  deleteReportFromDB,
  getReportFromDB,
  setReportInDB,
  updateReportInDB,
} from 'src/services/indexed-db';

export const indexedDBApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Report'],
  endpoints: builder => ({
    getPatientReport: builder.query<DatabaseReport | null, string>({
      providesTags: ['Report'],
      queryFn: async externalId => {
        const result = await getReportFromDB(externalId);
        return { data: result };
      },
    }),
    createPatientReport: builder.mutation<DatabaseReport, DatabaseReport>({
      invalidatesTags: ['Report'],
      queryFn: async data => {
        const result = await setReportInDB(data);
        return { data: result };
      },
    }),
    updatePatientReport: builder.mutation<DatabaseReport, DatabaseReport>({
      invalidatesTags: ['Report'],
      queryFn: async data => {
        const result = await updateReportInDB(data);
        return { data: result };
      },
    }),
    deletePatientReport: builder.mutation<boolean, string>({
      invalidatesTags: ['Report'],
      queryFn: async externalId => {
        const result = await deleteReportFromDB(externalId);
        return { data: result };
      },
    }),
  }),
});

export const {
  useCreatePatientReportMutation,
  useGetPatientReportQuery,
  useDeletePatientReportMutation,
  useUpdatePatientReportMutation,
} = indexedDBApi;
