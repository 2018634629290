import { createApi } from '@reduxjs/toolkit/query/react';
import { isNilOrEmpty } from 'ramda-adjunct';
import userAdminBaseQuery from 'src/services/user-admin-http-client';
import { ReportCreationDTO } from './report/dto';
import { Patient } from './types';

type GetPatientRequestParams = {
  id: string;
  organizationId?: number;
};

export type GetPatientByExternalIdRequestParams = {
  externalId: string;
};

export interface GetPatientReportRequestDTO {
  patientId: string;
}

export interface DeletePatientReportRequestDTO {
  patientId: string;
}

export interface UpdatePatientReportRequestDTO extends ReportCreationDTO {
  patientId: string;
}

export const patientApi = createApi({
  reducerPath: 'patientApi',
  baseQuery: userAdminBaseQuery,
  tagTypes: ['Report'],
  endpoints: builder => ({
    getPatient: builder.query<Patient, GetPatientRequestParams>({
      query: ({ id, organizationId }) => {
        if (!isNilOrEmpty(organizationId)) {
          return {
            url: `/api/v1/patients/${id}?organization=${organizationId}`,
          };
        }

        return {
          url: `/api/v1/patients/${id}`,
        };
      },
    }),
    getPatientByExternalId: builder.query<Patient, GetPatientByExternalIdRequestParams>({
      query: ({ externalId }) => ({
        url: `/api/v1/patients/external/${externalId}`,
      }),
    }),
  }),
});

export const { useGetPatientQuery, useGetPatientByExternalIdQuery } = patientApi;
