import { fr } from 'date-fns/locale';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Typography } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import React, { FunctionComponent, useMemo } from 'react';

import { IDiagnosticPreviewVM } from '../../DiagnosticPreviewVM';
import { statusOptions } from '../../../../helpers/statusOptions';
import { STATUS_TYPE, StatusType } from 'src/domains/medical-record/value-object/StatusType';
import Select from 'src/components/common/Select';
import schema from './schema';
import useStyles from './styles';
import useUpdateDiagnostic from './hook';
import { DiagnosticCreation } from '../../DiagnosticForm/schema';
import PersistenceForm from '../../../../Modals/PersistenceModal/PersistenceForm';

interface DiagnosticModificationFormProps {
  patientId: string;
  record: IDiagnosticPreviewVM;
  onClose: () => void;
  onPrevious: () => void;
  startDateProps?: object;
}

const DiagnosticModificationForm: FunctionComponent<DiagnosticModificationFormProps> = ({
  record,
  onClose,
  patientId,
  startDateProps,
}) => {
  const classes = useStyles();

  const {
    updateDiagnostic,
    isCreationLoading,
    isForceCreationLoading,
    isReplaceLoading,
    isSuccess,
    error,
  } = useUpdateDiagnostic(patientId, record);

  const initialValues: DiagnosticCreation = useMemo(
    () => ({
      startDate: new Date(),
      status: record?.status || STATUS_TYPE.PRESENT,
    }),
    [record?.status],
  );
  return (
    <PersistenceForm
      error={error}
      initialValues={initialValues}
      isUpdateMode={true}
      isCreating={isCreationLoading}
      isForceCreating={isForceCreationLoading}
      isReplacing={isReplaceLoading}
      isSubmittingSuccessfully={isSuccess}
      onClose={onClose}
      onSubmit={updateDiagnostic}
      validationSchema={schema}
      formContent={(props: FormikProps<DiagnosticCreation>) => (
        <>
          <div className={classes.row}>
            <div className={classes.inputSubjectContainer}>
              <Typography variant={'body1'}>A partir de quand ?</Typography>
            </div>
            <div className={classes.inputContainer}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                <Field
                  id="initialStartDate"
                  name="initialStartDate"
                  component={KeyboardDatePicker}
                  disableToolbar
                  disableFuture
                  format="dd/MM/yyyy"
                  color={'primary'}
                  margin={'dense'}
                  hiddenLabel
                  leftArrowButtonProps={{ color: 'primary' }}
                  rightArrowButtonProps={{ color: 'primary' }}
                  keyboardIcon={<EventIcon color={'primary'} />}
                  inputVariant={'outlined'}
                  cancelLabel={'Annuler'}
                  okLabel={'Confirmer'}
                  value={record.startDate}
                  disabled
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.inputSubjectContainer}>
              <Typography variant={'body1'}>Problème médical</Typography>
            </div>
            <div className={classes.inputContainer}>
              <Field
                value={props.values.status}
                options={statusOptions}
                margin={'dense'}
                variant={'outlined'}
                color={'primary'}
                id="status"
                name="status"
                placeholder=""
                type="text"
                onChange={(
                  event: React.ChangeEvent<{ name: string | undefined; value: StatusType }>,
                ) => {
                  props.setFieldValue('status', event.target.value, true);
                }}
                component={Select}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.inputSubjectContainer}>
              <Typography variant={'body1'}>{`${
                props.values.status === STATUS_TYPE.ABSENT ? 'Absent' : 'Présent'
              } depuis le`}</Typography>
            </div>
            <div className={classes.inputContainer}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                <Field
                  id="startDate"
                  name="startDate"
                  component={KeyboardDatePicker}
                  disableToolbar
                  disableFuture
                  format="dd/MM/yyyy"
                  color={'primary'}
                  margin={'dense'}
                  hiddenLabel
                  leftArrowButtonProps={{ color: 'primary' }}
                  rightArrowButtonProps={{ color: 'primary' }}
                  keyboardIcon={<EventIcon color={'primary'} />}
                  inputVariant={'outlined'}
                  cancelLabel={'Annuler'}
                  okLabel={'Confirmer'}
                  onChange={(value: MaterialUiPickersDate) => {
                    props.setFieldValue('startDate', value, true);
                  }}
                  value={props.values.startDate}
                  error={Boolean(props.errors.startDate)}
                  helperText={props.errors.startDate}
                  {...startDateProps}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </>
      )}
    />
  );
};

export default DiagnosticModificationForm;
