import React, { FunctionComponent } from 'react';

import BehaviourModificationForm from './BehaviourModificationForm';
import Step from 'src/components/common/Step';

interface BehaviourModificationModalContentProps {
  currentStep: number;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
  onSubmit: () => void;
  patientId: string;
  setRecord: (record: any) => void;
  record: any;
}

export const BehaviourModificationModalContent: FunctionComponent<BehaviourModificationModalContentProps> =
  ({ currentStep, onClose, onPrevious, patientId, record }) => {
    return (
      <>
        <Step step={0} currentStep={currentStep}>
          <BehaviourModificationForm
            patientId={patientId}
            behaviour={record}
            onClose={onClose}
            onPrevious={onPrevious}
          />
        </Step>
      </>
    );
  };

export default BehaviourModificationModalContent;
