import React, { FunctionComponent } from 'react';

import Modal, { ModalProps } from 'src/components/common/Modal';
import Table, { TableProps } from 'src/components/common/Table';
import useStyles from './styles';

interface HistoryModalProps extends Omit<TableProps, 'role'>, ModalProps {
  columns: TableProps['columns'];
  data: TableProps['data'];
  title?: string;
}

export const HistoryModal: FunctionComponent<HistoryModalProps> = ({
  columns = [],
  data = [],
  isLoading,
  onClose: handleOnClose,
  open,
  title,
}) => {
  const classes = useStyles();

  return (
    <Modal maxWidth={'sm'} onClose={handleOnClose} open={open} title={title}>
      <div className={classes.container}>
        <div className={classes.tableContainer}>
          <Table columns={columns} data={data} isLoading={isLoading} />
        </div>
        {/* <div className={classes.addButtonContainer}>
          <Button variant={'contained'} color={'primary'} onClick={onAddButtonClick}>
            Ajouter
          </Button>
        </div>*/}
      </div>
    </Modal>
  );
};

export default HistoryModal;
