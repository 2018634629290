import React, { FunctionComponent, useMemo } from 'react';

import { IAntecedentPreviewVM } from '../../AntecedentPreviewVM';
import AntecedentForm from '../../AntecedentForm';
import useUpdateAntecedent from './hook';
import AntecedentFormDTO, { bindFromIAntecedentPreviewVM } from '../../AntecedentForm/dto';

interface AntecedentFormProps {
  patientId: string;
  antecedent: IAntecedentPreviewVM;
  onClose: () => void;
  onPrevious: () => void;
}

const AntecedentModificationForm: FunctionComponent<AntecedentFormProps> = ({
  onClose,
  onPrevious,
  patientId,
  antecedent,
}) => {
  const { update, isCreationLoading, isForceCreationLoading, isReplaceLoading, isSuccess, error } =
    useUpdateAntecedent(patientId, antecedent);

  const record: AntecedentFormDTO = useMemo(
    () => bindFromIAntecedentPreviewVM(antecedent),
    [antecedent],
  );

  return (
    <AntecedentForm
      error={error}
      isCreating={isCreationLoading}
      isForceCreating={isForceCreationLoading}
      isReplacing={isReplaceLoading}
      isSuccess={isSuccess}
      onClose={onClose}
      onPrevious={onPrevious}
      onSubmit={update}
      patientId={patientId}
      record={record}
    />
  );
};

export default AntecedentModificationForm;
