import { Paper } from '@material-ui/core';
import { FunctionComponent } from 'react';

import Logo from '../Logo';
import useStyles from './styles';

const NominalLayout: FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <Logo height={50} width={'100%'} />
      </div>
      <Paper className={classes.paper}>
        <div className={classes.contentContainer}>{children}</div>
      </Paper>
    </div>
  );
};

export default NominalLayout;
