import { Box, Paper, Typography } from '@material-ui/core';
import { FunctionComponent, useEffect } from 'react';

import Layout from 'src/components/common/Layout';
import Page from 'src/components/common/Page';
import PatientInformations from './PatientInformations';

import { useDispatch } from 'react-redux';
import PatientDashboardUpperSection from './PatientDashboardUpperSection';
import usePatientDashboard from './hook';
import { MEDICAL_RECORD_TAGS, medicalRecordApi } from 'src/domains/medical-record/repository';

const PatientDashboardPage: FunctionComponent = () => {
  const { isPatientIdentified, isLoading, patientId, externalId } = usePatientDashboard();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(medicalRecordApi.util.invalidateTags(MEDICAL_RECORD_TAGS));
  }, [externalId, dispatch]);

  return (
    <Page testId={'patient-profile-page'}>
      <Layout>
        <Box
          maxWidth={'sm'}
          display={'flex'}
          flexDirection={'column'}
          padding={2}
          marginBottom={10}
        >
          {patientId && isPatientIdentified && (
            <Box marginBottom={5}>
              <PatientInformations patientId={patientId} />
            </Box>
          )}
          {isPatientIdentified && patientId && (
            <Box marginBottom={2}>
              <PatientDashboardUpperSection externalId={externalId} />
            </Box>
          )}
          {!isPatientIdentified && !isLoading && (
            <Box
              display={'flex'}
              position={'absolute'}
              alignItems={'center'}
              flexDirection={'column'}
              justifyContent={'center'}
              top={'50%'}
              left={'50%'}
              //@ts-ignore
              sx={{ transform: 'translate(-50%, -60%)' }}
            >
              <Box>
                <Box
                  component={Paper}
                  display={'flex'}
                  //@ts-ignore
                  flexDirection={'column'}
                  paddingX={12}
                  paddingTop={5}
                  paddingBottom={6}
                  borderRadius={9}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Box display={'flex'} flexDirection={'column'}>
                      <Box paddingBottom={2}>
                        <Typography color={'primary'} variant={'h3'}>
                          Aucun patient identifié
                        </Typography>
                      </Box>
                      <Typography color={'primary'} variant={'body1'}>
                        Veuillez vérifier que le numéro RCU renseigné est correct ou que vous
                        disposez des droits nécessaires pour accéder aux informations de ce patient.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Layout>
    </Page>
  );
};

export default PatientDashboardPage;
