import { isNilOrEmpty } from 'ramda-adjunct';
import { CellProps } from 'react-table';
import { useMemo } from 'react';

import { IAntecedentPreviewVM } from '../AntecedentPreviewVM';
import { toTitleCase } from 'src/helpers/format/string';
import AuthorField from '../../helpers/table/AuthorField';
import DateField from 'src/components/common/Table/DateField';
import TextField from 'src/components/common/Table/TextField';
import StatusField from '../../helpers/table/StatusField';
import useTableActions, {
  HandleClick,
  TableActionParams,
} from '../../helpers/table/use-table-actions';

interface useTableColumnProps extends TableActionParams<IAntecedentPreviewVM> {
  handleDeleteClick: HandleClick<IAntecedentPreviewVM>;
}

export const useTableColumn = ({
  handleDeleteClick,
  handleEditClick,
  handleHistoryClick,
}: useTableColumnProps): any[] => {
  const actionsColumn = useTableActions<IAntecedentPreviewVM>({
    handleDeleteClick,
    handleEditClick,
    handleHistoryClick,
  });

  return useMemo(
    () => [
      {
        Header: 'Date de déclaration',
        id: 'creationDate',
        accessor: 'creationDate',
        sortType: 'datetime',
        sortable: true,
        sortDescFirst: true,
        width: '15%',
        Cell: (props: CellProps<IAntecedentPreviewVM>) => {
          const date = props.cell.value;

          return <DateField date={date} />;
        },
      },
      {
        Header: 'Antécédent déclaré par le patient',
        accessor: 'label',
        width: '35%',
        Cell: (props: CellProps<IAntecedentPreviewVM>) => {
          const label = props.cell.value;

          return <TextField label={label} variant={'h5'} />;
        },
      },
      {
        Header: 'Correspondance',
        accessor: 'familyRelationship.label',
        width: '25%',
        Cell: (props: CellProps<IAntecedentPreviewVM>) => {
          const label = !isNilOrEmpty(props.cell.value) ? toTitleCase(props.cell.value) : undefined;

          return <TextField label={label} variant={'h5'} />;
        },
      },
      {
        Header: 'Modification',
        accessor: 'author',
        width: '15%',
        sortable: true,
        Cell: (props: CellProps<IAntecedentPreviewVM>) => {
          const author = props.cell.value;

          return <AuthorField value={author} />;
        },
      },
      {
        Header: 'Statut',
        accessor: 'status',
        width: '15%',
        sortable: true,
        Cell: (props: CellProps<IAntecedentPreviewVM>) => {
          const status = props.cell.value;

          return <StatusField status={status} />;
        },
      },
      actionsColumn,
    ],
    [actionsColumn],
  );
};
