import { useToggle } from 'react-use';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { IAntecedentPreviewVM } from '../AntecedentPreviewVM';
import { AntecedentModificationModalContent } from '../Update';
import { useTableColumn } from './columns';
import AntecedentDeletionModal from '../DeletionModal';
import AntecedentHistoryModal from '../History';
import PersistenceModal from '../../Modals/PersistenceModal';
import Section from '../../Section';
import useAntecedentList from './hook';

const AntecedentSection: FunctionComponent = () => {
  const { isLoading, list } = useAntecedentList();

  const [visualModel, setVisualModel] = useState<IAntecedentPreviewVM | undefined>(undefined);
  const [isHistoryModalOpened, toggleHistoryModal] = useToggle(false);
  const [isModificationModalOpened, toggleModificationModal] = useToggle(false);
  const [isDeletionModalOpened, toggleDeletionModal] = useToggle(false);
  /*  const [isCreationModalOpened, toggleCreationModal] = useToggle(false);

  const onCreationClick = useCallback(() => {
    toggleCreationModal();
  }, [toggleCreationModal]);*/

  const onModificationClick = useCallback(
    (vm: IAntecedentPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleModificationModal();
      }
    },
    [toggleModificationModal],
  );

  const onHistoryClick = useCallback(
    (vm: IAntecedentPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleHistoryModal();
      }
    },
    [toggleHistoryModal],
  );

  const onDeletionClick = useCallback(
    (vm: IAntecedentPreviewVM) => {
      if (vm) {
        setVisualModel(vm);
        toggleDeletionModal();
      }
    },
    [toggleDeletionModal],
  );

  const defaultSort = useMemo(
    () => [
      {
        id: 'creationDate',
        desc: true,
      },
    ],
    [],
  );

  const columns = useTableColumn({
    handleHistoryClick: onHistoryClick,
    handleDeleteClick: onDeletionClick,
    handleEditClick: onModificationClick,
  });

  return (
    <>
      <Section
        columns={columns}
        data={list}
        // onAddButtonClick={onCreationClick}
        title={'Antécédents déclarés'}
        isLoading={isLoading}
        defaultSort={defaultSort}
      />
      {/* <PersistenceModal
        contentType={ManifestationCreationModal}
        onClose={toggleCreationModal}
        open={isCreationModalOpened}
        patientId={patientId}
        selectedRecord={visualModel}
        title={'Ajouter un antécédent'}
        stepLabels={['Sélection du problème médical', "Spécification de l'antécédent"]}
      />*/}
      {visualModel && (
        <PersistenceModal
          title={'Mettre à jour un antécédent'}
          onClose={toggleModificationModal}
          open={isModificationModalOpened}
          contentType={AntecedentModificationModalContent}
          selectedRecord={visualModel}
        />
      )}
      {visualModel && (
        <AntecedentDeletionModal
          recordId={visualModel.id}
          onClose={toggleDeletionModal}
          open={isDeletionModalOpened}
          title={"Supprimer l'antécédent"}
          type={visualModel.type}
        />
      )}
      {visualModel && (
        <AntecedentHistoryModal
          antecedent={visualModel}
          onClose={toggleHistoryModal}
          open={isHistoryModalOpened}
        />
      )}
    </>
  );
};

AntecedentSection.displayName = 'AntecedentSection';

export default AntecedentSection;
