import { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import React from 'react';

import { NavigationRoute } from './navigationRoute';
import PatientListPage from '../pages/PatientList';
import PatientDashboardPage from 'src/pages/PatientDashboard';

export interface EnhancedRouteConfig extends RouteConfig {
  breadcrumb?: React.ComponentType | React.ElementType | string | null;
  render?: (props: RouteConfigComponentProps<any>) => React.ReactNode;
}

export const config: EnhancedRouteConfig[] = [
  {
    breadcrumb: 'Rechercher un patient',
    exact: true,
    component: PatientListPage,
    path: NavigationRoute.PATIENTS,
  },
  {
    breadcrumb: 'Profil patient',
    exact: true,
    component: PatientDashboardPage,
    path: `${NavigationRoute.EXTERNAL_PATIENT}/:id`,
  },
];

export default config;
