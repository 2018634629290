import { LatestObservationDTO } from '../../../dto';
import { Observation, PatientObservation } from '../../../entity/observation';
import { SubjectType } from '../../../value-object/SubjectType';

export const manifestationSubjects: string[] = [
  SubjectType.MANIFESTATION,
  SubjectType.SIGNE,
  SubjectType.AFFECTION,
];

export const selectManifestationsFromResponse = (
  response: LatestObservationDTO[] = [],
): PatientObservation[] => {
  const filteredLatestObservationsDTO: LatestObservationDTO[] = response.filter(
    ({ patientObservation }) =>
      manifestationSubjects.includes(patientObservation.subject.label) &&
      !patientObservation.familyRelationship?.id,
  );

  return filteredLatestObservationsDTO.map(
    latestObservationDTO => latestObservationDTO.patientObservation,
  );
};

export const selectManifestationObservations = (observations: Observation[]): Observation[] =>
  observations.filter(observation => manifestationSubjects.includes(observation.subject.label));
