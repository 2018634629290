import React, { FunctionComponent, useMemo } from 'react';

import { IFactPreviewVM } from '../../../FactPreviewVM';
import StatisticForm from '../../StatisticForm';
import useUpdateStatistic from './hook';
import StatisticFormDTO, { bindFromIFactPreviewVM } from '../../StatisticForm/dto';

interface StatisticModificationFormProps {
  statistic: IFactPreviewVM;
  onClose: () => void;
  onPrevious: () => void;
}

const StatisticModificationForm: FunctionComponent<StatisticModificationFormProps> = ({
  onClose,
  onPrevious,
  statistic,
}) => {
  const { update, isCreationLoading, isForceCreationLoading, isReplaceLoading, isSuccess, error } =
    useUpdateStatistic(statistic);

  const record: StatisticFormDTO = useMemo(() => bindFromIFactPreviewVM(statistic), [statistic]);

  return (
    <StatisticForm
      error={error}
      isCreating={isCreationLoading}
      isForceCreating={isForceCreationLoading}
      isReplacing={isReplaceLoading}
      isSuccess={isSuccess}
      onClose={onClose}
      onPrevious={onPrevious}
      onSubmit={update}
      record={record}
    />
  );
};

export default StatisticModificationForm;
