import { useCallback, useMemo, useState } from 'react';
import { isNilOrEmpty } from 'ramda-adjunct';
import { useHistory } from 'react-router-dom';

import { columns } from './constant';
import { NavigationRoute } from '../../routes/navigationRoute';
import { Patient } from 'src/domains/patient/types';
import {
  GetPatientByExternalIdRequestParams,
  useGetPatientByExternalIdQuery,
} from 'src/domains/patient/api';
import authenticationService from '../../services/authentication';
import { PatientSearch } from './schema';

interface UseGetPatientList {
  data: Patient[];
  columns: any[];
  isLoading: boolean;
  isError: boolean;
  onSubmit: (dto: PatientSearch) => void;
  redirectToExternalPatientDashboardPage: (patientId: string) => void;
}

const useGetPatientList = (): UseGetPatientList => {
  const history = useHistory();
  const [rcu, setRcu] = useState<string>('');

  const patientSearchDto: GetPatientByExternalIdRequestParams = useMemo(
    () => ({ externalId: rcu, organizationId: authenticationService.getOrganizationId() }),
    [rcu],
  );

  const {
    data: patient,
    isLoading,
    isError,
  } = useGetPatientByExternalIdQuery(patientSearchDto, { skip: isNilOrEmpty(rcu) });

  // @ts-ignore
  const patients: Patient[] = useMemo(() => {
    if (!isError && !isNilOrEmpty(patient)) return [patient];

    return [];
  }, [isError, patient]);

  const redirectToExternalPatientDashboardPage = useCallback(
    (externalId: string) => {
      history.push(`${NavigationRoute.EXTERNAL_PATIENT}/${externalId}`);
    },
    [history],
  );

  const onSubmit = (dto: PatientSearch) => {
    if (dto?.idRcu) setRcu(dto.idRcu);
  };

  return {
    data: patients,
    columns,
    isLoading,
    isError,
    onSubmit,
    redirectToExternalPatientDashboardPage,
  };
};

export default useGetPatientList;
