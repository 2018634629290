import { FunctionComponent, HTMLAttributes, SVGAttributes } from 'react';
import { ReactComponent as LogoTomaProSvg } from '../Icons/svgs/tomaProLogo.svg';

interface LogoProps
  extends Pick<SVGAttributes<any>, 'width' | 'height'>,
    HTMLAttributes<HTMLElement> {}

const Logo: FunctionComponent<LogoProps> = ({ className, width = 133, height = 47 }) => (
  <LogoTomaProSvg width={width} height={height} data-testid="logo" className={className} />
);

export default Logo;
