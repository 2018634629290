import { Box } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { FunctionComponent, useState } from 'react';

import PatientDashboardTabs from '../Tabs';
import PatientDashboardTab from '../Tabs/tab';
import MedicalRecord from './MedicalRecord';

enum CategoryType {
  MEDICAL_CARD = 'MEDICAL_CARD',
  TREATMENTS = 'TREATMENTS',
  DOCUMENTS = 'DOCUMENTS',
}

type PatientDashboardUpperSectionProps = {
  externalId: string;
};

const PatientDashboardUpperSection: FunctionComponent<PatientDashboardUpperSectionProps> = ({
  externalId,
}) => {
  const [value, setValue] = useState<string>(CategoryType.MEDICAL_CARD);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box marginBottom={5}>
      <TabContext value={value}>
        {/*@ts-ignore*/}
        <PatientDashboardTabs value={value} onChange={handleChange}>
          <PatientDashboardTab label="Profil de santé" value={CategoryType.MEDICAL_CARD} />
        </PatientDashboardTabs>
        <TabPanel value={CategoryType.MEDICAL_CARD}>
          <MedicalRecord externalId={externalId} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default PatientDashboardUpperSection;
