import { CellProps } from 'react-table';
import React, { useCallback, useMemo } from 'react';

import DateField from 'src/components/common/Table/DateField';
import TextField from 'src/components/common/Table/TextField';
import { HandleClick } from '../../helpers/table/use-table-actions';
import { IReportPreviewVM } from '../ReportPreviewVM';
import TableActions from '../../../../../../components/common/Table/Actions';

interface useTableColumnProps {
  handleEditClick?: HandleClick<IReportPreviewVM>;
  handleDeleteClick?: HandleClick<IReportPreviewVM>;
  handleGeneratePDFClick?: HandleClick<IReportPreviewVM>;
}

export const useTableColumn = ({
  handleDeleteClick,
  handleEditClick,
  handleGeneratePDFClick,
}: useTableColumnProps): any[] => {
  const getActions = useCallback(
    (vm: IReportPreviewVM) => {
      let actions = [];

      if (handleGeneratePDFClick)
        actions.push({
          label: 'Générer PDF',
          method: () => handleGeneratePDFClick && handleGeneratePDFClick(vm),
        });

      if (handleEditClick)
        actions.push({
          label: 'Mettre à jour',
          method: () => handleEditClick && handleEditClick(vm),
        });

      if (handleDeleteClick)
        actions.push({
          label: 'Supprimer',
          method: () => handleDeleteClick && handleDeleteClick(vm),
        });

      return actions;
    },
    [handleDeleteClick, handleEditClick, handleGeneratePDFClick],
  );

  return useMemo(
    () => [
      {
        Header: 'Date de déclaration',
        accessor: 'createdDate',
        sortable: true,
        width: '15%',
        Cell: (props: CellProps<IReportPreviewVM>) => {
          const date = props.cell.value;

          return <DateField date={date} />;
        },
      },
      {
        Header: 'Type de compte rendu',
        accessor: 'label',
        width: '50%',
        Cell: (props: CellProps<IReportPreviewVM>) => {
          const label = props.cell.value;

          return <TextField label={label} variant={'h5'} />;
        },
      },
      {
        Header: '',
        accessor: 'id',
        width: '5%',
        align: 'right',
        Cell: (props: CellProps<IReportPreviewVM>) => {
          const vm = props?.cell?.row?.original;
          const actionList = getActions(vm);
          return <TableActions actions={actionList} />;
        },
      },
    ],
    [getActions],
  );
};
