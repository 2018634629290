import { CellProps } from 'react-table';

import { IFactPreviewVM } from '../../FactPreviewVM';
import { useMemo } from 'react';
import TextField from 'src/components/common/Table/TextField';
import DateField from 'src/components/common/Table/DateField';
import FactValueField from '../../../helpers/table/FactValueField';
import { AuthorField } from '../../../helpers/table/AuthorField';

export const useHistoryTableColumn = (): any[] => {
  return useMemo(
    () => [
      {
        id: 'creationDate',
        accessor: 'creationDate',
        sortType: 'datetime',
        sortable: true,
        width: '15%',
        Cell: (props: CellProps<IFactPreviewVM>) => {
          const date = props.cell.value;

          return <DateField date={date} />;
        },
      },
      {
        accessor: 'label',
        width: '50%',
        Cell: (props: CellProps<IFactPreviewVM>) => {
          const label = props.cell.value;

          return <TextField label={label} variant={'h5'} />;
        },
      },
      {
        accessor: 'answerType',
        width: '20%',
        Cell: (props: CellProps<IFactPreviewVM>) => {
          const label = props.cell.value;

          return <AuthorField value={label} />;
        },
      },
      {
        id: 'value',
        accessor: (row: IFactPreviewVM) => {
          if (row.isQuantifiable) return row.value;
          return row.status;
        },
        width: '15%',
        sortable: true,
        Cell: (props: CellProps<IFactPreviewVM>) => {
          const value = props.row.original.value;
          const status = props.row.original.status;
          const measurementUnit = props.row.original.measurementUnit;
          const isQuantifiable = props.row.original.isQuantifiable || false;

          return (
            <FactValueField
              status={status}
              isQuantifiable={isQuantifiable}
              value={value}
              measurementUnit={measurementUnit}
            />
          );
        },
      },
    ],
    [],
  );
};
