import { IObservationPreviewVM } from '../ObservationPreviewVM';
import { PatientObservation } from 'src/domains/medical-record/entity/observation';
import { toTitleCase } from 'src/helpers/format/string';
import { StatusType } from 'src/domains/medical-record/value-object/StatusType';

export interface IDiagnosticPreviewVM extends IObservationPreviewVM {
  status: StatusType;
}

export const bindFromEntity = (observation: PatientObservation): IDiagnosticPreviewVM => {
  return {
    answerType: observation.answerType,
    creationDate: new Date(observation.date),
    descriptionId: observation.observationDescription.id,
    endDate: new Date(observation.endDate),
    familyRelationshipId: observation?.familyRelationship?.id,
    id: observation.id,
    label: toTitleCase(observation.observationDescription.observationType.label),
    startDate: new Date(observation.startDate),
    status: observation.status,
  };
};
